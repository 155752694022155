import Tracking from '@/utils/tracking'
export default defineNuxtPlugin((nuxtApp) => {
  const track = Tracking(nuxtApp)
  const root = track.mount('#mParticle')
  return {
    provide: {
      track: root
    }
  }
})
