export default [
  {
    paths: {
      en: '/community/',
      es: '/comunidad/'
    },
    key: 'Community',
    component: 'community/index',
    sitemap: true,
    crawler: true,
    translation: ['community'],
    keepAlive: true
  },
  {
    paths: {
      en: '/community/migrant-handbook/',
      es: '/comunidad/guia-migrante/'
    },
    key: 'CommunityMigrantHandbook',
    component: 'community/migrant-handbook',
    sitemap: true,
    crawler: true,
    translation: ['migrant-handbook'],
    keepAlive: true
  },
  {
    paths: {
      en: '/community/article/[slug]/',
      es: '/comunidad/article/[slug]/'
    },
    key: 'CommunityArticle',
    component: 'community/article/[slug]',
    sitemap: true,
    crawler: true,
    translation: ['community'],
    keepAlive: true
  },
  {
    paths: {
      en: '/community/majority-tv/[slug]/',
      es: '/comunidad/majority-tv/[slug]/'
    },
    key: 'CommunityVideo',
    component: 'community/majority-tv/[slug]',
    sitemap: true,
    crawler: true,
    translation: ['community'],
    keepAlive: true
  },
  {
    paths: {
      en: '/community/event/[slug]/',
      es: '/comunidad/evento/[slug]/'
    },
    key: 'CommunityEvent',
    component: 'community/event/[slug]',
    sitemap: true,
    crawler: true,
    translation: ['community'],
    keepAlive: true
  },
  {
    paths: {
      en: '/community/tag/[slug]/',
      es: '/comunidad/tag/[slug]/'
    },
    key: 'CommunityTag',
    component: 'community/tag/[slug]',
    sitemap: true,
    crawler: true,
    translation: ['community'],
    keepAlive: true
  },
  {
    paths: {
      en: '/community/discount/[slug]/',
      es: '/comunidad/descuento/[slug]/'
    },
    key: 'CommunityDiscount',
    component: 'community/discount/[slug]',
    sitemap: true,
    crawler: true,
    translation: ['community'],
    keepAlive: true
  },
  {
    paths: {
      en: '/community/[slug]/',
      es: '/comunidad/[slug]/'
    },
    key: 'CommunityDynamic',
    component: 'community/[slug]',
    sitemap: true,
    crawler: true,
    translation: ['community'],
    keepAlive: true
  },
  // {
  //   paths: {
  //     en: '/community/events/',
  //     es: '/comunidad/eventos/'
  //   },
  //   key: 'CommunityEvents',
  //   component: 'community/CommunityHome',
  //   sitemap: true,
  //   crawler: true,
  //   translation: ['community'],
  //   keepAlive: true
  // },
  // {
  //   paths: {
  //     en: '/community/majority-tv/',
  //     es: '/comunidad/majority-tv/'
  //   },
  //   key: 'CommunityVideos',
  //   component: 'community/CommunityHome',
  //   sitemap: true,
  //   crawler: true,
  //   translation: ['community'],
  //   keepAlive: true
  // },
  // {
  //   paths: {
  //     en: '/community/search/',
  //     es: '/comunidad/search/'
  //   },
  //   key: 'CommunitySearchResult',
  //   component: 'community/CommunityHome',
  //   sitemap: false,
  //   crawler: false,
  //   translation: ['community'],
  //   keepAlive: true
  // },
  // {
  //   paths: {
  //     en: '/community/migrant-handbook/',
  //     es: '/comunidad/guia-migrante/'
  //   },
  //   key: 'CommunityMigrantHandbook',
  //   component: 'community/CommunityMigrantHandbook',
  //   sitemap: true,
  //   crawler: true,
  //   translation: ['migrant-handbook'],
  //   keepAlive: true
  // },
  // {
  //   paths: {
  //     en: '/community/[slug]/',
  //     es: '/comunidad/[slug]/'
  //   },
  //   key: 'CommunityCategory',
  //   component: 'community/CommunityHome',
  //   sitemap: true,
  //   crawler: true,
  //   translation: ['community'],
  //   keepAlive: true
  // },
  // {
  //   paths: {
  //     en: '/community/event/[slug]/',
  //     es: '/comunidad/evento/[slug]/'
  //   },
  //   key: 'CommunityEvent',
  //   component: 'community/CommunityEventDetails',
  //   sitemap: true,
  //   crawler: true,
  //   translation: ['community'],
  //   keepAlive: true
  // },
  // {
  //   paths: {
  //     en: '/community/majority-tv/[slug]/',
  //     es: '/comunidad/majority-tv/[slug]/'
  //   },
  //   key: 'CommunityVideo',
  //   component: 'community/CommunityVideoDetails',
  //   sitemap: true,
  //   crawler: true,
  //   translation: ['community'],
  //   keepAlive: true
  // },
  // {
  //   paths: {
  //     en: '/community/tag/[slug]/',
  //     es: '/comunidad/tag/[slug]/'
  //   },
  //   key: 'CommunityTag',
  //   component: 'community/CommunityHome',
  //   sitemap: true,
  //   crawler: true,
  //   translation: ['community'],
  //   keepAlive: true
  // },

  // {
  //   paths: {
  //     en: '/community/discount/[slug]/',
  //     es: '/comunidad/descuento/[slug]/'
  //   },
  //   key: 'CommunityDiscount',
  //   component: 'community/CommunityDiscountDetails',
  //   sitemap: true,
  //   crawler: true,
  //   translation: ['community'],
  //   keepAlive: true
  // }
]
