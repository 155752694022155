export default [

  {
    paths: {
      en: '/data-deletion',
      es: '/eliminacion-de-datos'
    },
    key: 'data-delete',
    component: 'data-deletion/index',
    sitemap: false
  },
  {
    paths: {
      en: '/membership/',
      es: '/membresia/'
    },
    key: 'Membership',
    component: 'membership/index',
    sitemap: true
  },
  {
    paths: {
      en: '/membership/[country]/',
      es: '/membresia/[country]/'
    },
    key: 'Membership Country',
    component: 'membership/[country]/index',
    sitemap: true
  },
  {
    paths: {
      en: '/majority-account/',
      es: '/cuenta-majority/'
    },
    key: 'ServicesMajorityAccount',
    component: 'majority-account/index',
    sitemap: true
  },
  {
    paths: {
      en: '/majority-account/get-paid-early/',
      es: '/cuenta-majority/recibir-sueldo-antes/'
    },
    key: 'FeaturesMajorityAccountGetPaidEarly',
    component: 'majority-account/get-paid-early',
    sitemap: true
  },
  {
    paths: {
      en: '/majority-account/no-hidden-fees/',
      es: '/cuenta-majority/sin-cargos-ocultos/'
    },
    key: 'FeaturesMajorityAccountNoFees',
    component: 'majority-account/no-hidden-fees',
    sitemap: true
  },

  {
    paths: {
      en: '/majority-account/no-ssn/',
      es: '/majority-account/no-ssn/'
    },
    key: 'FeaturesMajorityAccountNoSSN',
    component: 'majority-account/no-ssn',
    sitemap: true
  },
  {
    paths: {
      en: '/visa-debit-card/',
      es: '/tarjeta-visa-debito/'
    },
    key: 'ServicesDebitCard',
    component: 'visa-debit-card/index',
    sitemap: true
  },
  {
    paths: {
      en: '/visa-debit-card/atms/',
      es: '/tarjeta-visa-debito/atms/'
    },
    key: 'FeaturesDebitCardAtms',
    component: 'visa-debit-card/atms',
    sitemap: true
  },
  {
    paths: {
      en: '/visa-debit-card/discounts/',
      es: '/tarjeta-visa-debito/descuentos/'
    },
    key: 'FeaturesDebitCardSaveSupportLocal',
    component: 'visa-debit-card/discounts/index',
    sitemap: true
  },
  {
    paths: {
      en: '/visa-debit-card/discounts/explore-discounts/',
      es: '/tarjeta-visa-debito/descuentos/explorar-descuentos/'
    },
    key: 'FeaturesDebitCardExploreDiscounts',
    component: 'visa-debit-card/discounts/explore-discounts',
    sitemap: true
  },
  {
    paths: {
      en: '/visa-debit-card/no-ssn/',
      es: '/tarjeta-visa-debito/no-ssn/'
    },
    key: 'FeaturesDebitCardNoSSN',
    component: 'visa-debit-card/no-ssn',
    sitemap: true
  },

  {
    paths: {
      en: '/money-transfers/',
      es: '/envios-dinero/'
    },
    key: 'ServicesMoneyTransfers',
    component: 'money-transfers/index',
    sitemap: true
  },

  {
    paths: {
      en: '/international-calling/',
      es: '/llamadas-internacionales/'
    },
    key: 'ServicesInternationalCalling',
    component: 'international-calling/index',
    sitemap: true
  },
  {
    paths: {
      en: '/international-calling/calling-rates/',
      es: '/llamadas-internacionales/tarifas-llamadas/'
    },
    key: 'FeaturesInternationalCallingCallingRates',
    component: 'international-calling/calling-rates',
    sitemap: true
  },
  {
    paths: {
      en: '/international-calling/unlimited/',
      es: '/llamadas-internacionales/ilimitadas/'
    },
    key: 'FeaturesInternationalCallingUnlimitedCalling',
    component: 'international-calling/unlimited',
    sitemap: true
  },
  {
    paths: {
      en: '/mobile-top-ups/',
      es: '/recargas-moviles/'
    },
    key: 'ServicesMobileTopUps',
    component: 'mobile-top-ups/index',
    sitemap: true
  },
  {
    paths: {
      en: '/personalized-advisor/',
      es: '/asesor-personal/'
    },
    key: 'ServicesPersonalizedAdvisors',
    component: 'personalized-advisor/index',
    sitemap: true
  },
  // Dynamic Routes
  {
    paths: {
      en: '/[country]/',
      es: '/[country]/'
    },
    key: 'MembershipDynamic',
    component: '[country]/index',
    sitemap: true
  },
  {
    paths: {
      en: '/[country]/international-calling/',
      es: '/[country]/llamadas-internacionales/'
    },
    key: 'ServicesInternationalCallingDynamic',
    component: '[country]/international-calling',
    sitemap: true
  },
  {
    paths: {
      en: '/[country]/money-transfers/',
      es: '/[country]/envios-dinero/'
    },
    key: 'ServicesMoneyTransfersDynamic',
    component: '[country]/money-transfers',
    sitemap: true
  },
  {
    paths: {
      en: '/[country]/[mtu]/',
      es: '/[country]/[mtu]/'
    },
    key: 'ServicesMobileTopUpsDynamic',
    component: '[country]/[mtu]',
    sitemap: true
  },
  {
    paths: {
      en: '/[country]/majority-account/',
      es: '/[country]/cuenta-majority/'
    },
    key: 'MajorityAccountDynamic',
    component: '[country]/majority-account',
    sitemap: true
  },
  {
    paths: {
      en: '/[country]/visa-debit-card/',
      es: '/[country]/tarjeta-visa-debito/'
    },
    key: 'ServicesDebitCardDynamic',
    component: '[country]/visa-debit-card',
    sitemap: true
  },
  {
    paths: {
      en: '/majority-pay',
      es: '/majority-pay'
    },
    component: 'majority-pay/index',
    sitemap: true
  },
  {
    paths: {
      en: '/[country]/majority-pay',
      es: '/[country]/majority-pay'
    },
    key: 'MajorityAccountDynamic',
    component: '[country]/majority-pay',
    sitemap: true
  }
]
