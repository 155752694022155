export default [
  {
    paths: {
      en: '/press/',
      es: '/prensa/'
    },
    key: 'Press',
    component: 'press/index',
    sitemap: true,
    crawler: true,
    translation: ['press']
  },
  {
    paths: {
      en: '/press/[category]/',
      es: '/prensa/[category]/'
    },
    key: 'PressCategory',
    component: 'press/[category]',
    sitemap: true,
    crawler: true,
    translation: ['press']
  },
  {
    paths: {
      en: '/press/material/[category]/',
      es: '/prensa/materiales/[category]/'
    },
    key: 'PressMaterialCategory',
    component: 'press/PressMaterialCategory',
    sitemap: true,
    crawler: true,
    translation: ['press']
  },
  {
    paths: {
      en: '/press/article/[slug]/',
      es: '/prensa/articulo/[slug]/'
    },
    key: 'PressArticle',
    component: 'press/article/[slug]',
    sitemap: true,
    crawler: true,
    translation: ['press']
  },
  {
    paths: {
      en: '/press/tag/[slug]/',
      es: '/prensa/tag/[slug]/'
    },
    key: 'PressTag',
    component: 'press/PressTag',
    sitemap: true,
    crawler: true,
    translation: ['press']
  }
]
