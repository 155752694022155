// to ensure that the locale is set before rendering the page, check the url for a locale. if not, insert the default locale and redirect to the new url.
import { SUPPORTED_CULTURES } from '@/utils/constants'

export default defineNuxtRouteMiddleware((to) => {
  let fullPath = to.fullPath
  const locale = fullPath.split('/')[1]
  const culture = SUPPORTED_CULTURES.find(
    culture => culture.twoLetter === locale
  )
  if (!culture) {
    fullPath = `/${SUPPORTED_CULTURES[0].twoLetter}${fullPath}`
    return navigateTo(fullPath)
  }
})
