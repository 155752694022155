<template>
  <div>
    <NuxtLayout>
      <NuxtLoader type="pulse" color="black" />
      <div id="mParticle" />
      <NuxtPage />
    </NuxtLayout>
  </div>
</template>
<script setup>
import { useMainStore } from '@/store/index'
const locale = useLocale()
const route = useRoute()
const { $track } = useNuxtApp()
const mainStore = useMainStore()
const config = useRuntimeConfig().public
useHead({
  htmlAttrs: {
    lang: locale
  }
})
onMounted(() => {
  mainStore.referrer = config.websiteUrl + route.fullPath
  $track.pageView(route)
  let prevScrollpos = window.scrollY
  let userScrollAmount = 150
  if (window.innerWidth < 768) {
    // 270 is the approx height of the virtual keyboard on iOS. This is done because on iOS,
    // the virtual keyboard pushes the content up and the header gets hidden.
    // however when the keyboard is closed, the header is not shown again.
    userScrollAmount += 270
  }
  window.addEventListener('scroll', () => {
    if (window.scrollY > userScrollAmount) {
      const navbar = document.getElementById('navbar')
      if (navbar) {
        const currentScrollPos = window.scrollY
        if (prevScrollpos > currentScrollPos) {
          navbar.style.top = '0px'
        } else {
          navbar.style.top = '-80px'
        }
        prevScrollpos = currentScrollPos
      }
    }
  })
  watch(() => route.path, () => {
    if (navbar) {
      navbar.style.top = '0px'
    }
  })
})
</script>
<style>
@font-face {
  font-family: 'GT-America';
  font-weight: 200;
  font-display: swap;
  src:
    url('https://majoritycdn.freetls.fastly.net/fonts/gt-america/GT-America-Standard-Thin.woff2') format('woff2'),
    url('https://majoritycdn.freetls.fastly.net/fonts/gt-america/GT-America-Standard-Thin.woff') format('woff');
}

@font-face {
  font-family: 'GT-America';
  font-weight: 300;
  font-display: swap;
  src:
    url('https://majoritycdn.freetls.fastly.net/fonts/gt-america/GT-America-Standard-Light.woff2') format('woff2'),
    url('https://majoritycdn.freetls.fastly.net/fonts/gt-america/GT-America-Standard-Light.woff') format('woff');
}

@font-face {
  font-family: 'GT-America';
  font-weight: normal;
  font-display: swap;
  src:
    url('https://majoritycdn.freetls.fastly.net/fonts/gt-america/GT-America-Standard-Regular.woff2') format('woff2'),
    url('https://majoritycdn.freetls.fastly.net/fonts/gt-america/GT-America-Standard-Regular.woff') format('woff');
}

@font-face {
  font-family: 'GT-America';
  font-weight: 500;
  font-display: swap;
  src:
    url('https://majoritycdn.freetls.fastly.net/fonts/gt-america/GT-America-Standard-Medium.woff2') format('woff2'),
    url('https://majoritycdn.freetls.fastly.net/fonts/gt-america/GT-America-Standard-Medium.woff') format('woff');
}

@font-face {
  font-family: 'GT-America';
  font-weight: 700;
  font-display: swap;
  src:
    url('https://majoritycdn.freetls.fastly.net/fonts/gt-america/GT-America-Standard-Bold.woff2') format('woff2'),
    url('https://majoritycdn.freetls.fastly.net/fonts/gt-america/GT-America-Standard-Bold.woff') format('woff');
}

@font-face {
  font-family: 'GT-America-Extended';
  font-weight: 200;
  font-display: swap;
  src:
    url('https://majoritycdn.freetls.fastly.net/fonts/gt-america/GT-America-Extended-Thin.woff2') format('woff2'),
    url('https://majoritycdn.freetls.fastly.net/fonts/gt-america/GT-America-Extended-Thin.woff') format('woff');
}

@font-face {
  font-family: 'GT-America-Extended';
  font-weight: 300;
  font-display: swap;
  src:
    url('https://majoritycdn.freetls.fastly.net/fonts/gt-america/GT-America-Extended-Light.woff2') format('woff2'),
    url('https://majoritycdn.freetls.fastly.net/fonts/gt-america/GT-America-Extended-Light.woff') format('woff');
}

@font-face {
  font-family: 'GT-America-Extended';
  font-weight: 500;
  font-display: swap;
  src:
    url('https://majoritycdn.freetls.fastly.net/fonts/gt-america/GT-America-Extended-Medium.woff') format('woff');
}

@font-face {
  font-family: 'GT-America-Extended';
  font-weight: normal;
  font-display: swap;
  src:
    url('https://majoritycdn.freetls.fastly.net/fonts/gt-america/GT-America-Extended-Regular.woff2') format('woff2'),
    url('https://majoritycdn.freetls.fastly.net/fonts/gt-america/GT-America-Extended-Regular.woff') format('woff');
}

@font-face {
  font-family: 'Druk';
  /* font-weight: bolder; */
  font-display: swap;
  src:
    url('https://majoritycdn.freetls.fastly.net/fonts/druk/Druk-Medium-Web.woff2') format('woff2'),
    url('https://majoritycdn.freetls.fastly.net/fonts/druk/Druk-Medium-Web.woff') format('woff');
}

.page-enter-active,
.page-leave-active {
  transition: all 0.4s;
}
.page-enter-from,
.page-leave-to {
  opacity: 0;
  filter: blur(1rem);
}
.layout-enter-active,
.layout-leave-active {
  transition: all 0.4s;
}
.layout-enter-from,
.layout-leave-to {
  opacity: 0;
  filter: blur(1rem);
}

input:focus {
  --tw-ring-color: transparent !important;
}

</style>
