import unhead_KgADcZ0jPj from "/vercel/path0/node_modules/nuxt/dist/head/runtime/plugins/unhead.js";
import router_jmwsqit4Rs from "/vercel/path0/node_modules/nuxt/dist/pages/runtime/plugins/router.js";
import debug_hAQZccAnsC from "/vercel/path0/node_modules/nuxt/dist/app/plugins/debug.js";
import payload_client_yVLowv6hDl from "/vercel/path0/node_modules/nuxt/dist/app/plugins/payload.client.js";
import plugin_vue3_A0OWXRrUgq from "/vercel/path0/node_modules/@pinia/nuxt/dist/runtime/plugin.vue3.mjs";
import components_plugin_KR1HBZs4kY from "/vercel/path0/.nuxt/components.plugin.mjs";
import prefetch_client_5tzzN0oIVL from "/vercel/path0/node_modules/nuxt/dist/pages/runtime/plugins/prefetch.client.js";
import plugin_t2GMTTFnMT from "/vercel/path0/node_modules/@nuxtjs/device/dist/runtime/plugin.mjs";
import composition_sLxaNGmlSL from "/vercel/path0/node_modules/@nuxtjs/i18n/dist/runtime/plugins/composition.mjs";
import i18n_yfWm7jX06p from "/vercel/path0/node_modules/@nuxtjs/i18n/dist/runtime/plugins/i18n.mjs";
import chunk_reload_client_UciE0i6zes from "/vercel/path0/node_modules/nuxt/dist/app/plugins/chunk-reload.client.js";
import check_outdated_build_client_8vK7RkfGxZ from "/vercel/path0/node_modules/nuxt/dist/app/plugins/check-outdated-build.client.js";
import _00cookies_tzjlAttuOl from "/vercel/path0/plugins/00cookies.js";
import _00options_TbQRNioOWw from "/vercel/path0/plugins/00options.js";
import _00router_aRTl5WuIFc from "/vercel/path0/plugins/00router.js";
import sentry_PZVGm6LH3G from "/vercel/path0/plugins/sentry.js";
import gtm_MVzSxF8h1r from "/vercel/path0/plugins/gtm.js";
import bus_GHaWeQPCWC from "/vercel/path0/plugins/bus.js";
import tracking_client_fEUopUxxSj from "/vercel/path0/plugins/tracking.client.js";
import outsideClickDirective_xmHeowfmVK from "/vercel/path0/plugins/directives/outsideClickDirective.js";
import pageViews_Fl7H2HIntY from "/vercel/path0/plugins/pageViews.js";
export default [
  unhead_KgADcZ0jPj,
  router_jmwsqit4Rs,
  debug_hAQZccAnsC,
  payload_client_yVLowv6hDl,
  plugin_vue3_A0OWXRrUgq,
  components_plugin_KR1HBZs4kY,
  prefetch_client_5tzzN0oIVL,
  plugin_t2GMTTFnMT,
  composition_sLxaNGmlSL,
  i18n_yfWm7jX06p,
  chunk_reload_client_UciE0i6zes,
  check_outdated_build_client_8vK7RkfGxZ,
  _00cookies_tzjlAttuOl,
  _00options_TbQRNioOWw,
  _00router_aRTl5WuIFc,
  sentry_PZVGm6LH3G,
  gtm_MVzSxF8h1r,
  bus_GHaWeQPCWC,
  tracking_client_fEUopUxxSj,
  outsideClickDirective_xmHeowfmVK,
  pageViews_Fl7H2HIntY
]