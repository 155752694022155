export default defineNuxtPlugin((nuxtApp) => {
  let clickHandler

  nuxtApp.vueApp.directive('outside-click', {
    bind (el, binding, vnode) {
      clickHandler = (e) => {
        e.stopPropagation()
        const { handler, exclude } = binding.value || {}

        if (!handler) {
          return
        }

        // Gives you the ability to exclude certain elements if you want, pass as array of strings to exclude
        if (exclude && Array.isArray(exclude)) {
          for (const elementRef of exclude) {
            const excludedEl = vnode.context.$refs[elementRef]

            if (excludedEl && excludedEl.contains(e.target)) {
              return
            }
          }
        }

        if (!el.contains(e.target)) {
          handler(e)
        }
      }

      if ('ontouchstart' in document.documentElement) {
        document.addEventListener('touchstart', clickHandler)
      } else {
        document.addEventListener('click', clickHandler)
      }
    },

    unbind () {
      if ('ontouchstart' in document.documentElement) {
        document.removeEventListener('touchstart', clickHandler)
      } else {
        document.removeEventListener('click', clickHandler)
      }
    }
  })
})
