import { computed, defineComponent, h, onBeforeUnmount, ref } from 'vue'
import { useNuxtApp } from '#app/nuxt'
import { useRouter } from '#app/composables/router'

// @ts-expect-error virtual file
import { globalMiddleware } from '#build/middleware'
import '@/assets/css/nuxt-loader.css'

export default defineComponent({
  name: 'NuxtLoadingIndicator',
  props: {
    type: {
      type: String,
      default: 'bar'
    },
    throttle: {
      type: Number,
      default: 200
    },
    duration: {
      type: Number,
      default: 2000
    },
    height: {
      type: Number,
      default: 3
    },
    color: {
      type: [String, Boolean],
      default: 'white'
    }
  },
  setup (props, { slots }) {
    const indicator = useLoadingIndicator({
      duration: props.duration,
      throttle: props.throttle
    })

    // Hook to app lifecycle
    // TODO: Use unified loading API
    const nuxtApp = useNuxtApp()
    const router = useRouter()

    globalMiddleware.unshift(indicator.start)
    router.onError(() => {
      indicator.finish()
    })
    router.beforeResolve((to, from) => {
      if (to === from || to.matched.every((comp, index) => comp.components && comp.components?.default === from.matched[index]?.components?.default)) {
        indicator.finish()
      }
    })

    router.afterEach((_to, _from, failure) => {
      if (failure) {
        indicator.finish()
      }
    })

    nuxtApp.hook('page:finish', indicator.finish)
    nuxtApp.hook('vue:error', indicator.finish)

    onBeforeUnmount(() => {
      const index = globalMiddleware.indexOf(indicator.start)
      if (index >= 0) {
        globalMiddleware.splice(index, 1)
      }
      indicator.clear()
    })

    switch (props.type) {
      case 'pulse':
        return () => renderPulseLoader(props, indicator)
      case 'bar':
        return () => renderLoadingBar(props, indicator, slots)
      default:
        return () => renderLoadingBar(props, indicator, slots)
    }
  }
})

function renderPulseLoader (props: any, indicator: any) {
  return h('div', {
    class: 'nuxt-loading-indicator-backdrop',
    style: {
      position: 'fixed',
      top: 0,
      right: 0,
      left: 0,
      pointerEvents: 'none',
      width: '100vw',
      height: '100vh',
      background: props.backgroundColor || 'rgba(255, 255, 255, 0.6)',
      opacity: indicator.isLoading.value ? 1 : 0,
      zIndex: 999998
    }
  }, [
    h('div', {
      class: 'nuxt-loading-indicator pulse',
      style: {
        position: 'fixed',
        top: '50%',
        left: '50%',
        pointerEvents: 'none',
        width: '30px',
        height: '30px',
        borderRadius: '35px',
        marginTop: '-15px',
        marginLeft: '-15px',
        background: props.color || 'black',
        animation: 'pulse 1.5s infinite',
        opacity: 0.4,
        zIndex: 999999
      }
    })
  ])
}

function renderLoadingBar (props: any, indicator: any, slots: any) {
  return h('div', {
    class: 'nuxt-loading-indicator',
    style: {
      position: 'fixed',
      top: 0,
      right: 0,
      left: 0,
      pointerEvents: 'none',
      width: 'auto',
      height: `${props.height}px`,
      opacity: indicator.isLoading.value ? 1 : 0,
      background: props.color || undefined,
      backgroundSize: `${(100 / indicator.progress.value) * 100}% auto`,
      transform: `scaleX(${indicator.progress.value}%)`,
      transformOrigin: 'left',
      transition: 'transform 0.1s, height 0.4s, opacity 0.4s',
      zIndex: 999999
    }
  }, slots)
}

function useLoadingIndicator (opts: {
  duration: number,
  throttle: number
}) {
  const progress = ref(0)
  const isLoading = ref(false)
  const step = computed(() => 10000 / opts.duration)

  let _timer: any = null
  let _throttle: any = null

  function start () {
    clear()
    progress.value = 0
    if (opts.throttle && process.client) {
      _throttle = setTimeout(() => {
        isLoading.value = true
        _startTimer()
      }, opts.throttle)
    } else {
      isLoading.value = true
      _startTimer()
    }
  }
  function finish () {
    progress.value = 100
    _hide()
  }

  function clear () {
    clearInterval(_timer)
    clearTimeout(_throttle)
    _timer = null
    _throttle = null
  }

  function _increase (num: number) {
    progress.value = Math.min(100, progress.value + num)
  }

  function _hide () {
    clear()
    if (process.client) {
      setTimeout(() => {
        isLoading.value = false
        setTimeout(() => { progress.value = 0 }, 400)
      }, 500)
    }
  }

  function _startTimer () {
    if (process.client) {
      _timer = setInterval(() => { _increase(step.value) }, 100)
    }
  }

  return {
    progress,
    isLoading,
    start,
    finish,
    clear
  }
}
