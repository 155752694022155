
export default [
  // {
  //   paths: {
  //     en: '/survey/[slug]/',
  //     es: '/encuesta/[slug]/'
  //   },
  //   key: 'DynamicSurvey',
  //   component: 'survey/DynamicSurvey',
  //   sitemap: true
  // }
]
