/**
* Is a given variable undefined or equal to null
* @param {obj} object
*/
export const isNullOrUndefined = (obj) => {
  return isNull(obj) || isUndefined(obj)
}

/**
* Is a given variable undefined, null or empty string
* @param {obj} object
*/
export const isEmpty = (obj) => {
  return isNull(obj) || isUndefined(obj) || obj === ''
}

/**
* Is a given variable undefined, null, empty string or white space
* @param {obj} object
*/
export const isEmptyOrWhiteSpace = (obj) => {
  return isNull(obj) || isUndefined(obj) || obj === '' || (obj.trim && obj.trim() === '')
}

/**
* Is a given variable equal to null
* @param {obj} object
*/
export const isNull = (obj) => {
  return obj === null
}

/**
* Is a given variable undefined
* @param {obj} object
*/
export const isUndefined = (obj) => {
  return obj === undefined
}

/**
* Returns true if any keys are set on the object
* @param {obj} object
*/
export const objectHasKeys = (obj) => {
  if (!obj || typeof obj !== 'object') {
    return false
  }
  return !isNullOrUndefined(obj) && Object.keys(obj).length > 0
}

export default {
  isNullOrUndefined,
  isEmpty,
  isEmptyOrWhiteSpace,
  isNull,
  isUndefined,
  objectHasKeys
}
