<template>
  <transition name="drawer">
    <div v-show="diasporaDrawerIsOpen" id="diasporaDrawer" class="drawer fixed inset-0 z-30">
      <div
        class="drawer-menu-wrapper absolute bg-white right-0 inset-y-0 w-full md:w-[480px] px-0 pb-0 flex flex-col justify-between z-[41]"
      >
        <MajorityUiMajorityIcon
          icon="close"
          class="absolute top-[20px] right-[20px] cursor-pointer"
          @click="closeDrawer"
        />
        <div>
          <div class="w-full p-8 md:p-16">
            <h4 class="text-black text-smallest font-normal font-gt-america-extended">
              {{ $messages('selector.select_community') }}
            </h4>
            <p class="text-dark-grey font-light text-smaller">
              {{ $messages('selector.select_community_details') }}
            </p>
            <MajorityUiMajorityCountryInput v-if="appStore.diasporaDrawerIsOpen" class="mt-4" :populate="selectedDiaspora.country" :suggested-countries="DIASPORA_SUGGESTED_COUNTRIES" @input="selectCountry" />
          </div>
          <div class="w-full px-8 md:px-16">
            <h4 class="text-black text-smallest font-normal font-gt-america-extended">
              {{ $messages('selector.select_language') }}
            </h4>
            <MajorityUiMajorityLocaleInput v-if="appStore.diasporaDrawerIsOpen" class="mt-4" :populate="selectedDiaspora.locale" @input="selectLocale" />
          </div>
        </div>
        <div>
          <div class="w-full p-8 md:px-16 md:pb-10">
            <button class="btn btn-infra-violette w-full py-3" @click="setDiasporaOnClick">
              {{ $messages('selector.confirm_button') }}
            </button>
          </div>
        </div>
      </div>
      <button
        aria-label="Close drawer"
        class="drawer-backdrop fixed inset-0 w-screen bg-dark/50 focus:outline-none z-40 cursor-default hidden md:block"
        @click="closeDrawer"
      />
    </div>
  </transition>
</template>
<script setup>
import { storeToRefs } from 'pinia'
import { applicationStore } from '@/store/application'
import { COUNTRY_NAME_COOKIE_KEY, COUNTRY_COOKIE_KEY, THIRDY_DAYS_IN_SECONDS, DIASPORA_SUGGESTED_COUNTRIES } from '@/utils/constants'

const appStore = applicationStore()
const { diasporaDrawerIsOpen } = storeToRefs(appStore)
const route = useRoute()
const { $bus, $messages } = useNuxtApp()

const selectedCountry = ref(null)
const selectedLocale = ref(null)
const isCountryChanged = ref(false)
const countryName = ref(null)
const countrySlugEn = ref(null)
const countrySlugEs = ref(null)

const selectedDiaspora = getDiaspora()
selectedCountry.value = selectedDiaspora.country
selectedLocale.value = selectedDiaspora.locale || useLocale() || 'en'
countryName.value = useCookie(COUNTRY_NAME_COOKIE_KEY).value
countrySlugEn.value = useCookie(`${COUNTRY_COOKIE_KEY}.en`).value
countrySlugEs.value = useCookie(`${COUNTRY_COOKIE_KEY}.es`).value

const closeDrawer = () => {
  appStore.diasporaDrawerIsOpen = false
}
const selectCountry = (event) => {
  isCountryChanged.value = true
  selectedCountry.value = event.id
  countryName.value = event.name
  countrySlugEn.value = event.id === 'global' ? null : event.alternativeSlugs.en
  countrySlugEs.value = event.id === 'global' ? null : event.alternativeSlugs.es
}

const selectLocale = (event) => {
  selectedLocale.value = event
}

const setDiasporaOnClick = () => {
  //  To avoid fetching the country again for the footer, let's save the country name as a cookie for now. We can revisit this later.
  useCookie(COUNTRY_NAME_COOKIE_KEY, {
    maxAge: THIRDY_DAYS_IN_SECONDS
  }).value = countryName.value

  useCookie(`${COUNTRY_COOKIE_KEY}.en`, {
    maxAge: THIRDY_DAYS_IN_SECONDS
  }).value = countrySlugEn.value

  useCookie(`${COUNTRY_COOKIE_KEY}.es`, {
    maxAge: THIRDY_DAYS_IN_SECONDS
  }).value = countrySlugEs.value

  setDiaspora(selectedCountry.value === 'global' ? null : selectedCountry.value, selectedLocale.value)
  if (route.meta.stopDefaultSwitch) {
    $bus.emitDynamicRouteChangeLanguage(route, selectedLocale.value, isCountryChanged ? selectedCountry.value === 'global' ? null : selectedCountry.value : null)
  } else {
    $bus.emitChangeLanguage(route, selectedLocale.value)
  }
}

</script>
<style>
.drawer-enter-active,
.drawer-leave-active {
  transition: all 0.3s;
  .drawer-menu-wrapper {
    transition: transform 0.3s;
  }
  .drawer-backdrop {
    transition: opacity 0.3s;
  }
}
.drawer-enter-from, .drawer-leave-to /* .fade-leave-active below version 2.1.8 */ {
  .drawer-menu-wrapper {
    transform: translateX(100%);
  }
  .drawer-backdrop {
    opacity: 0;
  }
}

.drawer-enter-to, .drawer-leave /* .fade-leave-active below version 2.1.8 */ {
  .drawer-menu-wrapper {
    transform: translateX(0);
  }
  .drawer-backdrop {
    opacity: 1;
  }
}
</style>
