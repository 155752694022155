<template>
  <transition name="desktop-navigation-open">
    <div v-if="showMenu" class="py-8">
      <ul class="max-w-3/5 mx-auto flex justify-end flex-wrap">
        <li v-for="(item, index) in list.sublist" :key="index" class="sub-items block text-dark flex-grow">
          <NuxtLink :to="item.route" :prefetch="false">
            <div class="min-w-[304px] image_section h-full bg-contain bg-center bg-no-repeat  md:min-w-fit flex-item flex shrink-0 relative mx-2" :style="{backgroundImage: 'url(' + item.image?.url + ')' }">
              <div class="image_section overflow-hidden w-full p-4 bg-origin-content" />
              <div class="w-full h-14 bg-black/30 absolute bottom-0 flex flex-row items-center justify-between px-4">
                <div>
                  <h4 class="text-white text-smallest lg:text-smaller font-normal text-left max-lines">
                    {{ item.title }}
                  </h4>
                  <p v-if="item.subtitle" class="text-white hidden xxl:block lg:text-tiny font-light text-left max-lines">
                    {{ item.subtitle }}
                  </p>
                </div>
                <MajorityUiMajorityIcon icon="arrow-right" class="card-carousel-icon bg-white text-black rounded-full text-tiniest lg:text-smallest ml-2 leading-0 p-2" />
              </div>
            </div>
          </NuxtLink>
        </li>
      </ul>
    </div>
  </transition>
</template>

<script>
import { objectHasKeys } from '@/utils/comparators'
export default {
  props: {
    list: {
      type: Object,
      required: true
    }
  },
  data () {
    return {
      show: true
    }
  },
  computed: {
    showMenu () {
      return objectHasKeys(this.list) && this.show
    }
  },
  watch: {
    list (newValue, oldValue) {
      if (!objectHasKeys(oldValue)) {
        this.show = true
      } else {
        this.show = false
        setTimeout(() => {
          this.show = true
        }, 5)
      }
    }
  }
}
</script>

<style lang="postcss" scoped>
.desktop-navigation-open-enter-active, .desktop-navigation-open-leave-active {
  transition: opacity 200ms ease-in-out;
}
.desktop-navigation-open-enter, .desktop-navigation-open-leave-to /* .fade-leave-active below version 2.1.8 */ {
  opacity: 0;
}

.desktop-navigation-open-enter-to, .desktop-navigation-open-leave /* .fade-leave-active below version 2.1.8 */ {
  opacity: 1;
}
.image_section{
    height: calc(100% - 56px);
}
.flex-item {
      color: white;
      font-weight: bold;
      font-size: 1.5em;
      text-align: center;
      flex: 1 0 auto;
      height:auto;
  }
  .flex-item::before {
      content:'';
      float:left;
      padding-top:82%;
  }
  .max-lines {
    overflow: hidden;
    width: 100%;
    display: -webkit-box;
    -webkit-line-clamp: 2;
    -webkit-box-orient: vertical;
  }
  .card-carousel-icon {
    color: #222120;
    background-color: #FFF5F0 !important;
  }
</style>
