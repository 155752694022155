export default {
  ambassador: () => import("/vercel/path0/layouts/ambassador.vue").then(m => m.default || m),
  campaign: () => import("/vercel/path0/layouts/campaign.vue").then(m => m.default || m),
  community: () => import("/vercel/path0/layouts/community.vue").then(m => m.default || m),
  default: () => import("/vercel/path0/layouts/default.vue").then(m => m.default || m),
  empty: () => import("/vercel/path0/layouts/empty.vue").then(m => m.default || m),
  external: () => import("/vercel/path0/layouts/external.vue").then(m => m.default || m),
  legal: () => import("/vercel/path0/layouts/legal.vue").then(m => m.default || m),
  link: () => import("/vercel/path0/layouts/link.vue").then(m => m.default || m),
  press: () => import("/vercel/path0/layouts/press.vue").then(m => m.default || m),
  presssingle: () => import("/vercel/path0/layouts/presssingle.vue").then(m => m.default || m)
}