export default {
  getReleaseVersion: () => {
    try {
      // const release = import('../.nuxt/sentryRelease.json')

      // return release.version || 'not-found'
      return '12345'
    // eslint-disable-next-line no-unreachable
    } catch (ex) {
      return 'error'
    }
  }
}
