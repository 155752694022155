import getMessage from '../utils/i18n'
export default defineNuxtPlugin((nuxtApp) => {
  const locale = useLocale()
  const messages = nuxtApp.$i18n.getLocaleMessage(locale)
  const env = useRuntimeConfig().public
  return {
    provide: {
      env,
      messages: (msg, params) => {
        return getMessage(messages, msg, params)
      }
    }
  }
})
