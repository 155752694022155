<template>
  <ul
    v-if="sections"
    class="navigation-collapsible-wrapper hide-scroll"
    :class="{ 'grid md:grid-cols-2 md:gap-x-24': twoColumn }"
  >
    <li v-for="(section, sectionIndex) in sections" :key="sectionIndex">
      <span
        v-for="(item, index) in section"
        :key="index"
        ref="navigationMainItem"
        class="block py-8 border-b border-[#F3EFED]"
        :class="{ 'hidden': (!twoColumn && !item.showOnTopNav) }"
      >

        <template v-if="!isNullOrUndefined(item.route)">

          <MajorityUiMajorityLink
            :to="item.route"
            class="menu-item-header text-black active-class"
            exact
            tracking-name="menuItem"
            tracking-type="main navigation"
            :text="item.title"
          >
            {{ item.title }}
          </MajorityUiMajorityLink>

        </template>

        <template v-else>

          <button
            class="menu-item-header focus:outline-none"
            :class="{ 'text-[#8A8D86]': openIndex !== null }"
            @click="openSubitems(item.id)"
          >
            {{ item.title }}

          </button>

          <MajorityUiMajorityCollapseBox
            :open="item.id === openIndex"
            :duration="collapsibleDuration"
            :class="{ openned: item.id === openIndex }"
          >

            <ul class="list-item">

              <li
                v-for="(subItem, subIndex) in item.sublist"
                :key="subIndex"
                class="sub-items h-medium flex items-end mt-4"
              >

                <MajorityUiMajorityLink
                  :to="subItem.route"
                  class="text-black font-medium text-display-5 leading-6"
                  active-class="text-black active-class"
                  exact
                  tracking-name="menuItem"
                  tracking-type="main navigation"
                  :text="subItem.title"
                >
                  {{ subItem.title }}
                </MajorityUiMajorityLink>

              </li>

            </ul>

          </MajorityUiMajorityCollapseBox>

        </template>

      </span>
    </li>
    <li class="mt-4">
      <LayoutNavigationLanguageSwitch arrow-icon="chevron-right" icon-color="black" text-color="black" class="w-full" />
    </li>
  </ul>
</template>

<script>
import { isNullOrUndefined } from '@/utils/comparators'
export default {
  props: {
    twoColumn: {
      type: Boolean,
      default: () => false
    },
    noIcon: {
      type: Boolean,
      default: () => false
    }
  },
  data () {
    return {
      openIndex: null,
      collapsibleDurationInSeconds: 0.5,
      scrollTimeout: null
    }
  },
  computed: {
    collapsibleDuration () {
      return `${this.collapsibleDurationInSeconds}s`
    },
    sections () {
      return [this.items.slice(0, 5), this.items.slice(5)]
    },
    items () {
      return [
        {
          id: 0,
          title: this.$messages('page_labels.community'),
          isDetailed: false,
          showOnTopNav: false,
          route: this.$mRouter.getLocalRoute({ name: 'community' })
        },
        {
          id: 1,
          title: this.$messages('page_labels.company'),
          showOnTopNav: false,
          route: this.$mRouter.getLocalRoute({ name: 'company' })
        },
        {
          title: this.$messages('page_labels.press'),
          showOnTopNav: false,
          route: this.$mRouter.getLocalRoute({ name: 'press' }),
          alternatives: [
            // this.$mRouter.getLocalRoute({ key: 'PressCategory' }),
            // this.$mRouter.getLocalRoute({ key: 'PressMaterialCategory' }),
            // this.$mRouter.getLocalRoute({ key: 'PressArticle' }),
            // this.$mRouter.getLocalRoute({ key: 'PressTag' }),
          ]
        },
        {
          title: this.$messages('page_labels.careers'),
          showOnTopNav: false,
          route: this.$mRouter.getLocalRoute({ name: 'company-career' }),
          alternatives: [
            this.$mRouter.getLocalRoute({ name: 'company-career-position-slug' }),
            this.$mRouter.getLocalRoute({ name: 'company-career-apply' })
          ]
        },
        {
          id: 2,
          title: this.$messages('page_labels.legal'),
          showOnTopNav: false,
          route: this.$mRouter.getLocalRoute({ name: 'terms-of-use' })
        },
        {
          id: 3,
          title: this.$messages('page_labels.membership'),
          showOnTopNav: false,
          route: this.$mRouter.getLocalRoute({ name: 'membership' })
        },
        {
          id: 4,
          title: this.$messages('page_labels.banking'),
          isDetailed: true,
          showOnTopNav: true,
          sublist: [
            {
              icon: 'account',
              title: this.$messages('page_labels.majority_account'),
              route: this.$mRouter.getLocalRoute({ name: 'majority-account' })
            },
            {
              icon: 'card',
              title: this.$messages('page_labels.debit_card'),
              route: this.$mRouter.getLocalRoute({ name: 'visa-debit-card' })
            },
            {
              icon: 'm-pay',
              title: this.$messages('page_labels.m_pay'),
              route: this.$mRouter.getLocalRoute({ name: 'majority-pay' })
            }
          ]
        },
        {
          id: 5,
          title: this.$messages('page_labels.services'),
          isDetailed: true,
          showOnTopNav: true,
          sublist: [
            {
              icon: 'money-transfer',
              title: this.$messages('page_labels.money_transfers'),
              route: this.$mRouter.getLocalRoute({ name: 'money-transfers' })
            },
            {
              icon: 'topup',
              title: this.$messages('page_labels.mobile_top_ups'),
              route: this.$mRouter.getLocalRoute({ name: 'mobile-top-ups' })
            },
            {
              icon: 'calling',
              title: this.$messages('page_labels.international_calling'),
              route: this.$mRouter.getLocalRoute({ name: 'international-calling' })
            }
          ]
        },
        {
          id: 6,
          title: this.$messages('page_labels.help'),
          showOnTopNav: true,
          sublist: [
            {
              title: this.$messages('page_labels.help_center'),
              route: this.$mRouter.getLocalRoute({ name: 'help' }),
              alternatives: [
                this.$mRouter.getLocalRoute({ name: 'help-category-slug' }),
                this.$mRouter.getLocalRoute({ name: 'help-article-slug' })
              ]
            },
            {
              title: this.$messages('page_labels.locations'),
              route: this.$mRouter.getLocalRoute({ name: 'locations' })
            },
            {
              title: this.$messages('page_labels.migrant_handbook'),
              route: this.$mRouter.getLocalRoute({ name: 'community-migrant-handbook' })
            }
          ]
        }
      ]
    }
  },
  methods: {
    isNullOrUndefined,
    openSubitems (index) {
      if (this.openIndex === index || isNullOrUndefined(index)) {
        this.openIndex = null
      } else {
        this.openIndex = index
      }
    },
    isAlternativeActive (
      alternatives,
      currentKey = this.$route.meta.key
    ) {
      if (!alternatives) {
        return false
      }

      return alternatives.find(x => x.key === currentKey) !== undefined
    }
  }
}
</script>

<style lang="postcss" scoped>
.navigation-collapsible-wrapper {
  -webkit-transform: translateZ(0);
  backface-visibility: hidden;

  li {
    -webkit-transform: translateZ(0);
    backface-visibility: hidden;
  }
}

.menu-item-header {
  @apply font-light font-gt-america-extended text-smaller text-black leading-6 uppercase no-underline flex justify-between w-full;
}
</style>
