<template>
  <div v-if="!isMajorityBrowser" class="header-wrapper z-navbar-10">
    <div id="navbar" class="header-bars fixed inset-x-0 z-50">
      <LayoutTheHeaderDownloadAppBanner
        v-if="showDownloadAppBanner"
        @hide="isDownloadBannerHidden = true"
      />
      <LayoutTheHeaderNavbar />
    </div>
    <div class="bg-black">
      <!-- <span class="navbar-placeholder block" /> -->
      <span
        v-if="showDownloadAppBanner"
        class="download-app-banner-placeholder block"
      />
      <MajorityUiMajorityInfoBar
        v-if="showEmergency"
        :message="emergencyText"
        :type="emergencyType"
        container
        lazy-animation
        @dismiss="onDismissEmergency"
      />
    </div>
    <Transition name="slide-fade">
      <LazySharedModal v-if="signupModalIsOpen" />
    </Transition>
  </div>
</template>

<script>
import { mapState } from 'pinia'
import { applicationStore } from '~/store/application'
import { isNullOrUndefined } from '@/utils/comparators'

export default {

  data () {
    return {
      isDownloadBannerHidden: false,
      showEmergency: false,
      emergencyText: null,
      emergencyType: null
    }
  },
  computed: {
    ...mapState(applicationStore, ['isMajorityBrowser', 'signupModalIsOpen']),
    showDownloadAppBanner () {
      return (
        this.$device.isMobile &&
        !this.$cookies.get('hideStoreBanner') &&
        !this.isDownloadBannerHidden &&
        !['campaign-slug', 'referral'].includes(this.getRouteBaseName())
      )
    }
  },
  mounted () {
    if (
      isNullOrUndefined(this.$cookies.get('dismissEmergency')) &&
      window.energencyMessage &&
      !isNullOrUndefined(window.energencyMessage[this.$i18n.locale])
    ) {
      this.emergencyType = window.energencyMessage.type
      this.emergencyText = window.energencyMessage[this.$i18n.locale]
      this.showEmergency = true
    }
  },
  methods: {
    onDismissEmergency () {
      this.$cookies.set('dismissEmergency', 1, { path: '/' })
    }
  }
}
</script>

<style lang="postcss">
.header-wrapper {
  --download-app-banner-height: 60px;

  @screen lg {
    --download-app-banner-height: 0px;
  }
}
</style>

<style lang="postcss" scoped>
.slide-fade-enter-active,
.slide-fade-leave-active {
  transition: all 0.2s cubic-bezier(0, 0, 0.2, 1);
}

.slide-fade-enter, .slide-fade-leave-to
/* .slide-fade-leave-active below version 2.1.8 */ {
  transform: translateY(15px);
  opacity: 0;
}
.navbar-placeholder {
  height: var(--navbar-height);
}

.download-app-banner-placeholder {
  height: var(--download-app-banner-height);
}
#navbar{
  top: 0;
  transition: top 0.3s;
}
</style>
