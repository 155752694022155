export default defineNuxtPlugin((nuxtApp) => {
  const get = (key, options = {}) => {
    const cookie = useCookie(key, options)
    return cookie.value
  }
  const set = (key, value, options = {}) => {
    const cookie = useCookie(key, options)
    cookie.value = value
  }

  const remove = (key, options = {}) => {
    const cookie = useCookie(key, options)
    cookie.value = null
  }

  return {
    provide: {
      cookies: {
        get, set, remove
      }
    }
  }
})
