export const GLOBE_IMAGE = 'https://majority.imgix.net/web-assets/flags/global.svg'

export const BANKING_MENU_ITEMS = [
  'https://majority.imgix.net/web-assets/navigation/banking_1.webp',
  'https://majority.imgix.net/web-assets/navigation/banking_2.webp',
  'https://majority.imgix.net/web-assets/navigation/banking_3.webp'
]

export const SERVICES_MENU_ITEMS = [
  'https://majority.imgix.net/web-assets/navigation/services_1.webp',
  'https://majority.imgix.net/web-assets/navigation/services_2.webp',
  'https://majority.imgix.net/web-assets/navigation/services_3.webp'
]

export const HELP_MENU_ITEMS = [
  'https://majority.imgix.net/web-assets/navigation/help_1.webp',
  'https://majority.imgix.net/web-assets/navigation/help_2.webp',
  'https://majority.imgix.net/web-assets/navigation/help_3.webp'
]

export const OG_IMAGE = 'https://majority.imgix.net/web-assets/meta/og.png'

export const GLOBAL_COUNTRY = {
  id: 'global',
  name: 'Global',
  flags: {
    '1:1': 'https://majority.imgix.net/web-assets/flags/global.svg'
  }
}
