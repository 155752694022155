import { defineStore } from 'pinia'

const state = () => ({
  referrer: null
})

export const useMainStore = defineStore('main', {
  state: () => state()
})

export default useMainStore
