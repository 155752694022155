import URI from 'urijs'
import { isEmpty } from '@/utils/comparators'
import { BREAKPOINTS as breakpoints } from '@/utils/constants'

/**
 *
 * @param {String} imgixUrl
 * @param {String} bgClass Class name of the elemnt who will have the background image
 */
const lazyloadingBackground = (imgixUrl, bgClass) => {
  if (imgixUrl) {
    const optimalImage = optimizeImgixUrl(imgixUrl)
    const blurredImage = `${optimalImage}&blur=100&q=50&cs=strip`

    let bgsetAttr = null
    let styleAttr = ''

    /**
     * That piece here should only run in client-side.
     * This plugin (Lazysizes) manipulates DOM. If component
     * that contains lazyloading bg image hydrates before the page
     * we receive mismatch error.
     *
     * This attribute sets real background-image
     */
    if (process.client) {
      bgsetAttr = `${optimalImage}&w=${breakpoints.sm} [(max-width: ${breakpoints.sm}px)] | `
      bgsetAttr += `${optimalImage}&w=${breakpoints.md} [(max-width: ${breakpoints.md}px)] | `
      bgsetAttr += `${optimalImage}&w=${breakpoints.lg} [(max-width: ${breakpoints.lg}px)] | `
      bgsetAttr += `${optimalImage}&w=${breakpoints.xl} [(max-width: ${breakpoints.xl}px)] | `
      bgsetAttr += `${optimalImage}&w=2540`
    }

    /**
     * This attribute sets blurred placeholder background-image
     * Transition provide smooth transition from blured image to real one
     */
    styleAttr += `.${bgClass} { transition: background-image 0.2s ease-in-out }`
    styleAttr += `@media( min-width: ${breakpoints.xs}px     ) and ( max-width: ${breakpoints.sm}px ) { .${bgClass} { background-image: url(${blurredImage}&w=${breakpoints.sm}) } }`
    styleAttr += `@media( min-width: ${breakpoints.sm + 1}px ) and ( max-width: ${breakpoints.md}px ) { .${bgClass} { background-image: url(${blurredImage}&w=${breakpoints.md}) } }`
    styleAttr += `@media( min-width: ${breakpoints.md + 1}px ) and ( max-width: ${breakpoints.lg}px ) { .${bgClass} { background-image: url(${blurredImage}&w=${breakpoints.lg}) } }`
    styleAttr += `@media( min-width: ${breakpoints.lg + 1}px ) and ( max-width: ${breakpoints.xl}px ) { .${bgClass} { background-image: url(${blurredImage}&w=${breakpoints.xl}) } }`
    styleAttr += `@media( min-width: ${breakpoints.xl + 1}px )                                        { .${bgClass} { background-image: url(${blurredImage}&w=2540) } }`

    return {
      bgsetAttr,
      styleAttr
    }
  } else {
    return {
      bgsetAttr: null,
      styleAttr: null
    }
  }
}

/**
 * This method takes and imgix or CDN url and modifies it with predefined imgix
 * query parameter.
 * @param {String} imgixUrl Image URL
 * @param {Boolean} blur
 * @param {Object} options Additional query parameters to send to Imgix
 * @returns {String} An imgix URL modified with optimal parameters
 */
const optimizeImgixUrl = (imgixUrl, blur = false, options = {}) => {
  if (isEmpty(imgixUrl)) {
    return imgixUrl
  }

  const baseUrl = URI(process.env.imgixUrl)

  const urlObj = URI(imgixUrl).hostname(baseUrl.hostname()).protocol(baseUrl.protocol())
  urlObj.removeQuery(...Object.keys(options))

  urlObj.addQuery({
    fit: 'crop',
    auto: 'format,compress',
    lossless: 'true',
    fm: urlObj.suffix()
  })

  urlObj.addQuery(options)

  if (blur) {
    urlObj.addQuery({
      blur: '100',
      q: '50',
      cs: 'strip'
    })
  }

  return urlObj.toString()
}

/**
 * This method takes and imgix query parameter.
 * @param {String} imgixUrl Image URL
 * @param {Boolean} blur
 * @param {Object} options Additional query parameters to send to Imgix
 * @returns {String} An imgix URL modified with optimal parameters
 */
const optimize = (imgixUrl, blur = false, options = {}) => {
  if (isEmpty(imgixUrl)) {
    return imgixUrl
  }

  const urlObj = URI(imgixUrl)
  urlObj.removeQuery(...Object.keys(options))

  urlObj.addQuery({
    fit: 'crop',
    auto: 'format,compress',
    fm: 'webp'
  })

  urlObj.addQuery(options)

  if (blur) {
    urlObj.addQuery({
      blur: '100',
      q: '50',
      cs: 'strip'
    })
  }

  return urlObj.toString()
}

export {
  lazyloadingBackground,
  optimizeImgixUrl,
  optimize
}
