export default [
  // {
  //   paths: {
  //     en: '/redirect/[slug]',
  //     es: '/redireccion/[slug]'
  //   },
  //   key: 'Redirect',
  //   component: 'redirect/index',
  //   sitemap: false
  // }
]
