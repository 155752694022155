<template>
  <svg
    viewBox="0 0 167 20"
    version="1.1"
    data-test-id="component-the-logo"
  >
    <g stroke="none" stroke-width="1" fill="none" fill-rule="evenodd">
      <g transform="translate(-129.000000, -184.000000)" fill="currentColor" fill-rule="nonzero">
        <g transform="translate(129.000000, 184.000000)">
          <rect x="114.461684" y="0.81392844" width="5.39015508" height="18.4286073" />
          <polygon points="160.367736 0.813944954 154.920856 8.56623853 149.474153 0.813944954 143.18877 0.813944954 152.225847 13.2192661 152.225847 19.2425688 157.616043 19.2425688 157.616043 13.2192661 166.652941 0.813944954" />
          <polygon points="121.965062 0.813944954 121.965062 5.22880734 129.458111 5.22880734 129.458111 19.2425688 134.848307 19.2425688 134.848307 5.22880734 142.341355 5.22880734 142.341355 0.813944954" />
          <path d="M107.566959,13.0452128 C110.058369,12.1903284 111.677465,9.86188257 111.677465,7.01658899 L111.677465,7.01655596 C111.677465,3.45710642 109.004779,0.813917431 104.935235,0.813917431 L91.8995847,0.813917431 L91.8981497,19.2425266 L97.1548093,19.2425248 L97.1548093,13.4112569 L101.855971,13.4112569 L106.103922,19.2425266 L112.081016,19.2425266 L107.566959,13.0452128 Z M104.667627,9.18931376 L97.1548021,9.18931376 L97.1548021,5.22889358 L104.667627,5.22889358 C105.729353,5.2299456 106.58951,6.11620765 106.58951,7.20910367 C106.58951,8.30199969 105.729353,9.18826174 104.667627,9.18931376 Z" />
          <polygon points="19.7399287 0.813944954 14.0663102 12.4941284 8.39286988 0.813944954 0.142067736 0.813944954 0.142067736 19.2425688 5.33048128 19.2425688 5.33048128 6.50311927 11.8424242 19.2425688 16.2903743 19.2425688 22.8023173 6.50311927 22.8023173 19.2425688 27.9919786 19.2425688 27.9919786 0.813944954" />
          <path d="M77.6382638,0.16606789 C70.3516132,0.16606789 65.6368378,4.41973028 65.6368378,10.0281284 C65.6368378,15.6371193 70.3516203,19.8903872 77.6382638,19.8903872 C84.8996132,19.8903872 89.6396898,15.6371248 89.6396898,10.0281284 C89.639697,4.41973028 84.8996132,0.16606789 77.6382638,0.16606789 Z M77.6370357,15.389044 C74.0417433,15.389044 71.1769643,13.5248239 71.1769643,10.0281266 C71.1769643,6.53142936 74.0417451,4.66840734 77.6370357,4.66840734 C81.2094349,4.66840734 84.0758645,6.53142569 84.0758645,10.0281266 C84.0758645,13.5248275 81.2094349,15.389044 77.6370357,15.389044 Z" />
          <path d="M47.1811658,19.2425376 L52.6158645,19.2425376 L43.8325134,0.81392844 L37.6112193,0.81392844 L28.8394153,19.2425376 L34.0119234,19.2425193 L35.80182,15.5292349 L45.3792103,15.5292349 L47.1811658,19.2425376 Z M37.5675882,11.3550972 L40.5742977,5.19688073 L43.6002353,11.3550972 L37.5675882,11.3550972 Z" />
          <path d="M53.4633173,14.8275706 L56.0413547,14.8275706 C57.7752228,14.8275706 58.2374563,14.2053835 58.2374563,11.9939505 L58.2374563,0.81392844 L63.4412656,0.81392844 L63.4412656,12.9653211 C63.4412656,16.8721853 61.5793512,19.2425339 57.7839127,19.2425339 L53.4633173,19.2425339 L53.4633173,14.8275706 Z" />
        </g>
      </g>
    </g>
  </svg>
</template>

<script>
export default {
}
</script>
