class RequestException extends Error {
  constructor (message, originalError) {
    super(message)
    Error.captureStackTrace(this, RequestException)
    this.name = 'RequestException'

    if (originalError) {
      this.original = originalError
      this.originalStack = originalError.stack
    }
  }
}

class NotFoundException extends RequestException {
  response = {
    status: 404
  }

  constructor (message, originalError) {
    super(message, originalError)
    Error.captureStackTrace(this, NotFoundException)
    this.name = 'NotFoundException'
  }
}

class BadRequestException extends RequestException {
  response = {
    status: 400
  }

  constructor (message, originalError) {
    super(message, originalError)
    Error.captureStackTrace(this, BadRequestException)
    this.name = 'BadRequestException'
  }
}

class InternalServerErrorException extends RequestException {
  response = {
    status: 500
  }

  constructor (message, originalError) {
    super(message, originalError)
    Error.captureStackTrace(this, InternalServerErrorException)
    this.name = 'InternalServerErrorException'
  }
}

class TimeoutException extends RequestException {
  response = {
    status: 504
  }

  constructor (message, originalError) {
    super(message, originalError)
    Error.captureStackTrace(this, TimeoutException)
    this.name = 'TimeoutException'

    this.isTimeout = true
  }
}

export {
  RequestException,
  NotFoundException,
  BadRequestException,
  InternalServerErrorException,
  TimeoutException
}
