<!-- eslint-disable vue/no-multiple-template-root -->
<template>
  <div>
    <div id="mParticle" />
    <LayoutTheHeader />
    <div v-if="!isMajorityBrowser" class="flex flex-col justify-center py-36 text-center h-full bg-white">
      <div class="container">
        <div
          v-if="error.statusCode === 404 || error.statusCode === 500"
          class="flex flex-col items-center"
        >
          <h1 class="h1 mb-standart">
            {{ $t('errors.pages.page_not_found') }}
          </h1>
          <p>{{ $t('errors.pages.page_not_found_description') }}</p>
        </div>
        <div
          v-else
          class="flex flex-col items-center"
        >
          <h1 class="h1">
            {{ $t('errors.pages.page_error') }}
          </h1>
          <p>{{ $t('errors.pages.page_error_description') }}</p>
        </div>
        <p>
          {{ $t('errors.pages.suggested_pages') }}
        </p>
        <ul class="p-0">
          <li
            v-for="(route, index) in routes"
            :key="index"
            class="list-unstyled mb-tiny text-center"
          >
            <MajorityUiMajorityLink
              :to="route.to"
              no-tracking
            >
              {{ route.text }}
            </MajorityUiMajorityLink>
          </li>
        </ul>
      </div>
    </div>
    <div v-else class="flex flex-col justify-center px-standart py-tiny md:py-semi">
      <p class="in-app-message font-bold">
        {{ $t('errors.pages.page_error_description') }}
      </p>
      <button
        class="btn rounded-full self-start align-baseline"
        aria-label="Refresh page"
        @click="refreshPage"
      >
        <i icon="refresh" class="align-middle mr-1" /> Try again
      </button>
    </div>
    <LayoutTheFooter />
  </div>
</template>
<script setup>
import { applicationStore } from '~/store/application'

const props = defineProps({
  error: {
    type: Object,
    default: () => null
  }
})

const appStore = applicationStore()

const isMajorityBrowser = appStore.isMajorityBrowser

if (props.error && !isMajorityBrowser) {
  console.error(props.error)
  clearError({ redirect: '/' })
}

const { $mRouter, $messages } = useNuxtApp()

const routes = [
  {
    to: $mRouter.getLocalRoute({ name: 'index' }),
    text: $messages('label.home_page')
  },
  {
    to: $mRouter.getLocalRoute({ name: 'community' }),
    text: $messages('label.community')
  },
  {
    to: $mRouter.getLocalRoute({ name: 'help' }),
    text: $messages('label.help')
  }
]

const router = useRouter()
const route = useRoute()

const refreshPage = () => {
  if (props.error.statusCode === 404) {
    const route404 = $mRouter.getLocalRoute({ name: 'index' })
    router.push(route404)
  } else {
    try {
      let noOfTry = route.query.try || 1
      noOfTry = parseInt(noOfTry)

      if (noOfTry >= 3) {
        const route = $mRouter.getLocalRoute({ name: 'community' })
        router.push(route)
        return
      }
      route.query.try = noOfTry + 1
    } catch (error) {
      router.pushLocalRoute({ key: 'Community' })
    }
  }
}
</script>

<style lang="postcss" scoped>
.in-app-message {
  color: rgb(34, 33, 32);
  font-size: 33px;
  letter-spacing: -0.85px;
  line-height: 40px;
}
</style>
