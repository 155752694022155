import { defineStore } from 'pinia'

const namespaced = true

const state = () => ({
  externalReferenceId: null,
  currency: 'USD',
  locale: 'en',
  toCountry: null,
  callCountry: null,
  sendMoneyCountry: null,
  userState: null
})

const getters = {
  userContext (state) {
    return {
      currency: state.currency,
      locale: state.locale,
      toCountry: ''
    }
  },

  getToCountry (state) {
    return state.toCountry
  },

  getLocale (state) {
    return state.locale
  },

  getExternalId (state) {
    return state.externalReferenceId
  },

  getUserState (state) {
    return state.userState
  }
}

const actions = {
  setToCountry (id) {
    this.SET_TO_COUNTRY(id)
  },

  setUserLocale (locale) {
    this.SET_LOCALE(locale)
  },

  SET_CURRENCY (val) {
    this.currency = val
  },

  SET_LOCALE (val) {
    this.locale = val
  },

  SET_TO_COUNTRY (val) {
    this.toCountry = val
  },

  SET_EXTERNAL_ID (val) {
    this.externalReferenceId = val
  },

  SET_CALL_COUNTRY (val) {
    this.callCountry = val
  },

  SET_SEND_MONEY_COUNTRY (val) {
    this.sendMoneyCountry = val
  },

  SET_USER_STATE (val) {
    this.userState = val
  }
}

export const userContextStore = defineStore('userContext', {
  namespaced,
  state: () => state(),
  actions,
  getters
})

export default userContextStore
