import VueScrollTo from 'vue-scrollto'

export default defineNuxtRouteMiddleware(() => {
  useNuxtApp().hook('page:finish', () => {
    if (history.state.scroll) {
      setTimeout(() => window.scrollTo(history.state.scroll), 400)
    } else {
      const hash = window.location.hash
      if (!hash) {
        setTimeout(() => window.scrollTo(0, 0), 400)
      } else {
        const elId = hash.replace('#', '')
        const element = document.getElementById(elId)
        if (!isNullOrUndefined(element)) {
          setTimeout(() => {
            VueScrollTo.scrollTo(element, 0)
          }, 300)
        }
      }
    }
  })
})
