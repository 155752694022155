export default [
  {
    paths: {
      en: '/terms-of-use/',
      es: '/terminos-de-uso/'
    },
    key: 'LegalIndexSection',
    component: 'terms-of-use/index',
    sitemap: false,
    crawler: false,
    translation: ['legal']
  },
  {
    paths: {
      en: '/terms-of-use/[slug]/',
      es: '/terminos-de-uso/[slug]/'
    },
    key: 'LegalTermsOfService',
    component: 'terms-of-use/[slug]',
    sitemap: false,
    translation: ['legal']
  },
  {
    paths: {
      en: '/terms-and-conditions/team-majority/',
      es: '/terms-and-conditions/team-majority/'
    },
    key: 'LegalTermsAndConditionsTeamMajority',
    component: 'terms-and-conditions/team-majority',
    sitemap: false,
    translation: ['legal']
  },
  {
    paths: {
      en: '/privacy-policy/team-majority/',
      es: '/privacy-policy/team-majority/'
    },
    key: 'LegalPrivacyPolicyTeamMajority',
    component: 'privacy-policy/team-majority',
    sitemap: false,
    translation: ['legal']
  },
  {
    paths: {
      en: '/legal/terms-and-conditions/team-majority/',
      es: '/legal/terms-and-conditions/team-majority/'
    },
    key: 'LegalTermsAndConditionsTeamMajorityOld',
    component: 'legal/terms-and-conditions/team-majority',
    sitemap: false,
    translation: ['legal']
  },
  {
    paths: {
      en: '/legal/privacy-policy/team-majority/',
      es: '/legal/privacy-policy/team-majority/'
    },
    key: 'LegalPrivacyPolicyTeamMajorityOld',
    component: 'legal/privacy-policy/team-majority',
    sitemap: false,
    translation: ['legal']
  }
]
