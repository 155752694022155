<!-- eslint-disable semi -->
<template>
  <div v-outside-click="{ handler: outsideClick }" class="text-white">
    <ul class="navigation-desktop-menu-list flex mr-[20px]" @mouseleave="closeMenuItems()">
      <li v-for="(item, index) in items" :key="index">
        <MajorityUiMajorityLink
          v-if="!isNullOrUndefined(item.route)"
          :to="item.route"
          class="navigation-desktop-menu-item no-underline text-black"
          tracking-name="desktopMenuItem"
          tracking-type="main navigation"
          :text="item.title"
        />
        <button
          v-else
          class="navigation-desktop-menu-item transition-colors duration-200 text-black"
          :class="{ 'text-dark-grey': (openItemIndex !== null && openItemIndex !== index) }"
          @click="openMenuItem(index)"
        >
          {{ item.title }}
        </button>
      </li>
    </ul>
    <button class="btn btn-black self-center mr-[40px]" @click="showModal()">
      {{ $messages("action.sign_up") }}
    </button>
    <button class="flex items-center" @click="showDiasporaModal()">
      <div class="h-[28px] w-[28px] rounded-full overflow-hidden">
        <MajorityUiMajorityImage v-if="!selectedDiaspora.country" class="h-[28px] w-[28px]" alt="flag" :src="globeImage" />
        <MajorityUiMajorityImage v-if="selectedDiaspora.country" alt="flag" :src="flagPrefix + selectedDiaspora.country.toLowerCase() + '.svg'" />
      </div>
      <span class="text-black leading-0 ml-3 text-tiny font-gt-america-extended">{{ selectedDiaspora.locale ? selectedDiaspora.locale.toUpperCase() : 'EN' }}</span>
    </button>
    <div class="bg-white absolute top-full inset-x-0" @mouseover="openMenuItem(prevOpenItemIndex)" @mouseleave="closeMenuItems()">
      <LayoutNavigationDesktopMenuSubmenu :list="openItem" />
    </div>
  </div>
</template>

<script>
import { mapActions } from 'pinia'
import { mixin as VueClickAway } from 'vue3-click-away'
import { applicationStore } from '~/store/application'
import { isNullOrUndefined } from '@/utils/comparators'
import { GLOBE_IMAGE, BANKING_MENU_ITEMS, SERVICES_MENU_ITEMS, HELP_MENU_ITEMS } from '@/utils/resources'
export default {
  mixins: [VueClickAway],
  setup () {
    const countriesApi = useCountries()
    const selectedDiaspora = getDiaspora()
    return {
      countriesApi,
      selectedDiaspora
    }
  },
  data () {
    return {
      showLanguageSwitch: false,
      openItemIndex: null,
      prevOpenItemIndex: null,
      closeSubmenuTimeout: null,
      forceSelection: null,
      globeImage: GLOBE_IMAGE,
      flagPrefix: 'https://majority.imgix.net/web-assets/flags/1x1/'
    }
  },
  computed: {
    openItem () {
      return !isNullOrUndefined(this.openItemIndex)
        ? this.items[this.openItemIndex]
        : {}
    },
    currentLanguage () {
      if (this.forceSelection) {
        return this.forceSelection
      }

      return this.$i18n.locale
    },
    items () {
      return [
        {
          title: this.$messages('page_labels.banking'),
          isDetailed: true,
          sublist: [
            {
              image: {
                url: BANKING_MENU_ITEMS[0]
              },
              title: this.$messages('page_labels.majority_account'),
              subtitle: this.$messages('page_labels.majority_account_subtitle'),
              backgroundColor: {
                hex: '#283A35'
              },
              route: this.$mRouter.getLocalRoute({ name: 'majority-account' })
            },
            {
              image: {
                url: BANKING_MENU_ITEMS[1]
              },
              title: this.$messages('page_labels.debit_card'),
              subtitle: this.$messages('page_labels.debit_card_subtitle'),
              backgroundColor: {
                hex: '#2F2A1B'
              },
              route: this.$mRouter.getLocalRoute({ name: 'visa-debit-card' })
            },
            {
              image: {
                url: BANKING_MENU_ITEMS[2]
              },
              title: this.$messages('page_labels.m_pay'),
              subtitle: this.$messages('page_labels.m_pay_subtitle'),
              backgroundColor: {
                hex: '#FAE724'
              },
              route: this.$mRouter.getLocalRoute({ name: 'majority-pay' })
            }
          ]
        },
        {
          title: this.$messages('page_labels.services'),
          isDetailed: true,
          sublist: [
            {
              image: {
                url: SERVICES_MENU_ITEMS[0]
              },
              title: this.$messages('page_labels.money_transfers'),
              subtitle: this.$messages('page_labels.money_transfers_subtitle'),
              backgroundColor: {
                hex: '#283A35'
              },
              route: this.$mRouter.getLocalRoute({ name: 'money-transfers' })
            },
            {
              image: {
                url: SERVICES_MENU_ITEMS[1]
              },
              title: this.$messages('page_labels.mobile_top_ups'),
              subtitle: this.$messages('page_labels.mobile_top_ups_subtitle'),
              backgroundColor: {
                hex: '#2F2A1B'
              },
              route: this.$mRouter.getLocalRoute({ name: 'mobile-top-ups' })
            },
            {
              image: {
                url: SERVICES_MENU_ITEMS[2]
              },
              title: this.$messages('page_labels.international_calling'),
              subtitle: this.$messages('page_labels.international_calling_subtitle'),
              backgroundColor: {
                hex: '#FAE724'
              },
              route: this.$mRouter.getLocalRoute({ name: 'international-calling' })
            }
          ]
        },
        {
          title: this.$messages('page_labels.help'),
          sublist: [
            {
              image: {
                url: HELP_MENU_ITEMS[0]
              },
              title: this.$messages('page_labels.help_center'),
              subtitle: this.$messages('page_labels.help_center_subtitle'),
              backgroundColor: {
                hex: '#283A35'
              },
              route: this.$mRouter.getLocalRoute({ name: 'help' })
            },
            {
              image: {
                url: HELP_MENU_ITEMS[1]
              },
              title: this.$messages('page_labels.locations'),
              subtitle: this.$messages('page_labels.locations_subtitle'),
              backgroundColor: {
                hex: '#2F2A1B'
              },
              route: this.$mRouter.getLocalRoute({ name: 'locations' })
            },
            {
              image: {
                url: HELP_MENU_ITEMS[2]
              },
              title: this.$messages('page_labels.migrant_handbook'),
              subtitle: this.$messages('page_labels.migrant_handbook_subtitle'),
              backgroundColor: {
                hex: '#FAE724'
              },
              route: this.$mRouter.getLocalRoute({ name: 'community-migrant-handbook' })
            }
          ]
        }
      ]
    }
  },
  watch: {
    $route () {
      this.openItemIndex = null
    }
  },
  methods: {
    ...mapActions(applicationStore, ['setSignupModalIsOpen', 'setDiasporaDrawerIsOpen']),
    isNullOrUndefined,
    openMenuItem (index) {
      this.openItemIndex = index
      this.prevOpenItemIndex = index
    },
    closeMenuItems () {
      if (this.openItemIndex !== null) {
        this.openItemIndex = null
      }
    },
    outsideClick () {
      this.openItemIndex = null
    },
    showModal () {
      this.setSignupModalIsOpen(true)
    },
    showDiasporaModal () {
      this.setDiasporaDrawerIsOpen(true)
    }
  }
}
</script>

<style lang="postcss" scoped>
.navigation-desktop-menu-list {
  height: var(--navbar-height);
}
.navigation-desktop-menu-item {
  @apply flex items-center font-gt-america font-medium text-smaller leading-7 h-full px-5;

  &:focus {
    @apply outline-none;
  }
}
</style>
