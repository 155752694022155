import { useMainStore } from '@/store/index'
export default defineNuxtPlugin((nuxtApp) => {
  const store = useMainStore()
  const config = useRuntimeConfig().public
  nuxtApp.hook('page:transition:finish', () => {
    const route = useRoute()
    store.referrer = config.websiteUrl + route.fullPath
    nuxtApp.$track.pageView(route)
  })
})
