import { getRoutes, getLocaleRoutes } from '@/router/routes'
import { isEmpty, isNull, isUndefined } from '@/utils/comparators'
import { SUPPORTED_CULTURES } from '@/utils/constants'
export default defineNuxtPlugin(async (nuxtApp) => {
  const siteLocale = useLocale() || 'en'
  const routes = getRoutes()
  const router = useRouter()
  const localeRoute = useLocaleRoute()
  const allLocaleRoutes = getLocaleRoutes()

  const getLocalRoute = (route, msg = null) => {
    // route can have key and parameters objects.
    const path = localeRoute(route)

    return path
  }

  const getRouteForLocale = (route, locale = siteLocale) => {
    const path = localeRoute(route, locale)
    return path
  }

  const resolveLocalRoute = (route, locale = siteLocale) => {
    route = getLocalRoute(route, locale)
    if (route) {
      return router.resolve(route)
    }
    return null
  }

  const getRouteByLocale = (route, locale = siteLocale) => {
    let name = route.name.split('-').join('/')
    name = name.split('+').join('-')
    name = name.replace('slug', '[slug]')
    const routes = allLocaleRoutes[name]
    let localeRoute = routes[locale]
    if (route.params) {
      for (const param in route.params) {
        localeRoute = localeRoute.replace(
          `[${param}]`,
          route.params[param]
        )
      }
    }
    return localeRoute
  }

  const getUrlByKey = (route, locale = siteLocale) => {
    if (isEmpty(route) || isEmpty(route.key)) {
      return ''
    }

    let searchedRoute = routes.find(
      x => x.name === getLocalRoute(route, locale).name
    )

    if (
      isNull(searchedRoute) ||
      isUndefined(searchedRoute) ||
      !searchedRoute.path
    ) {
      return ''
    }

    searchedRoute = JSON.parse(JSON.stringify(searchedRoute))

    for (const param in route.params || {}) {
      searchedRoute.path = searchedRoute.path.replace(
        `:${param}`,
        route.params[param]
      )
    }
    return searchedRoute.path
  }

  const getAlternatives = (route) => {
    const alternatives = {}
    const supportedLanguages = SUPPORTED_CULTURES.map(
      culture => culture.twoLetter
    )
    const availableAlternatives = routes.filter((r) => {
      return r.meta && r.meta.key === route.meta.key
    })

    supportedLanguages.forEach((locale) => {
      alternatives[locale] =
        availableAlternatives.filter(a => a.meta.locale === locale)[0] ||
        getLocalRoute({ key: 'Home' }, locale)
    })
    return alternatives
  }

  const pushLocalRoute = (route, ignoreDuplicatedRoute = false) => {
    const dest = { ...getLocalRoute(route) }

    // NavigationFailureType.redirected = 2
    router.push(dest).catch((ex) => {
      if (ex.type === 2) {
        return
      }

      if (ex.type === 16 && ignoreDuplicatedRoute) {
        return
      }

      throw ex
    })
  }

  const replaceLocalRoute = (route, ignoreDuplicatedRoute = false) => {
    const dest = { ...getLocalRoute(route) }

    // NavigationFailureType.redirected = 2
    router.replace(dest).catch((ex) => {
      if (ex.type === 2) {
        return
      }

      if (ex.type === 16 && ignoreDuplicatedRoute) {
        return
      }

      throw ex
    })
  }

  return {
    provide: {
      mRouter: {
        getLocalRoute,
        resolveLocalRoute,
        getRouteForLocale,
        getUrlByKey,
        getAlternatives,
        pushLocalRoute,
        replaceLocalRoute,
        getRouteByLocale
      }
    }
  }
})
