export default [
  {
    paths: {
      en: '/referral/',
      es: '/referral/'
    },
    key: 'Referral',
    component: 'referral/index',
    sitemap: false
  }
]
