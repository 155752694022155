<template>
  <dialog
    :open="props.modelValue"
    class="fixed w-full bottom-0 left-0 p-4 rounded-tr-lg rounded-tl-lg bottom_sheet"
    :class="{
      hide: addHideAnimationClass,
    }"
    :style="{
      backgroundColor: props.backgroundColor,
      color: textColor
    }"
  >
    <div class="flex items-center justify-between mb-2">
      <h4 class="text-black text-smallest font-normal font-gt-america-extended">
        {{ title }}
      </h4>
      <MajorityUiMajorityIcon
        icon="close"
        class="mt-1 ml-1 pointer"
        :color="iconColor"
        @click="closeSheet"
      />
    </div>
    <slot />
  </dialog>
</template>
<script setup>

defineComponent({
  name: 'BottomSheet'
})

const addHideAnimationClass = ref(false)

const props = defineProps({
  modelValue: {
    type: Boolean,
    default: false
  },
  backgroundColor: {
    type: String,
    default: 'white'
  },
  textColor: {
    type: String,
    default: 'black'
  },
  iconColor: {
    type: String,
    default: 'white'
  },
  title: {
    type: String,
    default: ''
  }
})

const emits = defineEmits(['update:modelValue'])

const closeSheet = () => {
  addHideAnimationClass.value = true
  setTimeout(() => {
    emits('update:modelValue', false)
    addHideAnimationClass.value = false
  }, 500)
}

</script>
<style scoped>
.bottom_sheet {
    max-height: 100%;
    overflow: scroll;
    z-index: 9999;
}
dialog[open] {
    -webkit-animation: show 0.5s ease normal;
    animation: show 0.5s ease normal;
}
@keyframes show{
    from {
        transform: translateY(110%);
    }
    to {
        transform: translateY(0%);
    }
}
dialog.hide {
    -webkit-animation: hide 0.5s ease normal;
    animation: hide 0.5s ease normal;
}
@keyframes hide{
    from {
        transform: translateY(0%);
    }
    to {
        transform: translateY(110%);
    }
}
.bottom_sheet :deep(h1) {
    font-family: Druk,Impact,sans-serif!important;
    font-size: 42px;
    font-weight: 500;
    text-align: center;
    line-height: 42px;
    letter-spacing: 2px;
    text-transform: uppercase;
    margin-bottom: 0.5em;
}
.bottom_sheet :deep(p){
    font-family: GT-America,sans-serif !important;
    font-size: 17px;
    font-weight: 400;
    line-height: 24px;
    letter-spacing: 0px;
    text-align: left;
    margin-bottom: 3em;
}
.bottom_sheet :deep(ol) {
    list-style: decimal;
    font-family: GT-America,sans-serif !important;
    font-size: 17px;
    font-weight: 400;
    padding-left: 20px;
    margin-bottom: 3em;
}
.bottom_sheet :deep(li) {
    margin-bottom: 2em;
}
</style>
