export default [
  {
    paths: {
      en: '/locations/',
      es: '/ubicaciones/'
    },
    key: 'Locations',
    component: 'locations/index',
    sitemap: true,
    translation: ['locations']
  }
]
