<template>
  <div class="navbar sticky flex items-center justify-between px-0 bg-white">
    <div class="container-wrap z-10 flex justify-between items-center bg-white px-8 md:px-16">
      <MajorityUiMajorityLink
        :to="localRoute"
        aria-label="Majority Logo"
        class="majority-logo z-10"
        data-test-id="link-the-navbar-home-logo"
        tracking-name="navbarLogo"
        tracking-type="main navigation"
        text="majorityLogo"
      >
        <SvgTheLogo class="text-black h-tiny lg:h-[18px] z-10" />
      </MajorityUiMajorityLink>
      <div class="flex items-center justify-center ">
        <div class="h-[28px] w-[28px] rounded-full overflow-hidden lg:hidden mr-4">
          <MajorityUiMajorityImage v-if="!selectedDiaspora.country" height="28px" alt="flag" :src="globeImage" />
          <MajorityUiMajorityImage v-if="selectedDiaspora.country" alt="flag" :src="flagPrefix + selectedDiaspora.country.toLowerCase() + '.svg'" />
        </div>
        <button
          class="navbar-toggler flex flex-col justify-between lg:hidden z-20 text-black"
          :class="{ 'drawer-open': isDrawerOpen }"
          aria-label="Toggle nav"
          @click="toggleDrawer"
        >
          <div class="toggle-line toggle-line-1" />
          <div class="toggle-line toggle-line-2" />
          <div class="toggle-line toggle-line-3" />
        </button>
      </div>
      <LayoutNavigationDesktopMenu class="hidden lg:flex" />
    </div>
    <Transition name="drawer">
      <div v-show="isDrawerOpen" class="drawer fixed inset-0 z-0 lg:hidden">
        <div
          class="drawer-menu-wrapper absolute bg-white left-0 inset-y-0 w-full md:w-5/12 z-10 px-tiny pb-tiny flex flex-col justify-between"
        >
          <LayoutNavigationCollapsible
            class="h-full overflow-y-scroll overflow-x-hidden mt-standart"
          />
          <div class="block pt-4">
            <button
              type="button"
              class="w-full mb-tiny btn btn-black"
              @click="showModal()"
            >
              {{ $messages('action.sign_up') }}
            </button>
            <LayoutNavigationSocialMediaLinks class="mt-semi hidden lg:block" />
          </div>
        </div>
        <button
          aria-label="Close drawer"
          class="drawer-backdrop fixed inset-0 w-screen bg-dark/50 focus:outline-none hidden lg:block"
          @click="closeDrawer"
        />
      </div>
    </Transition>
    <LayoutDiasporaDrawer />
  </div>
</template>

<script>
import { mapActions } from 'pinia'
import { applicationStore } from '~/store/application'
import { GLOBE_IMAGE } from '@/utils/resources'
export default {
  setup () {
    const { setLocale } = useI18n()
    const switchLocalePath = useSwitchLocalePath()
    const selectedDiaspora = ref(getDiaspora())
    return { setLocale, switchLocalePath, selectedDiaspora }
  },
  data () {
    return {
      isDrawerOpen: false,
      localRoute: this.$mRouter.getLocalRoute({ name: 'index' }),
      globeImage: GLOBE_IMAGE,
      flagPrefix: 'https://majority.imgix.net/web-assets/flags/1x1/'
    }
  },
  watch: {
    $route () {
      this.closeDrawer()
    }
  },
  mounted () {
    this.closeDrawer()

    this.$bus.onChangeLanguage((val) => {
      this.setLocale(val.destinationLocale).then(() => {
        const path = this.switchLocalePath(val.destinationLocale)
        window.location.href = path
      })
    })
  },
  beforeUnmount () {
    this.closeDrawer()
  },
  methods: {
    ...mapActions(applicationStore, {
      setMenuDrawerIsOpen: 'setMenuDrawerIsOpen',
      setSignupModalIsOpen: 'setSignupModalIsOpen'
    }),
    showModal () {
      this.closeDrawer()
      this.setSignupModalIsOpen(true)
    },
    openDrawer () {
      this.isDrawerOpen = true
      window.document.body.style.overflow = 'hidden'
      this.setMenuDrawerIsOpen(true)
    },
    closeDrawer () {
      this.isDrawerOpen = false
      window.document.body.style.overflow = ''
      this.setMenuDrawerIsOpen(false)
    },
    toggleDrawer () {
      this.isDrawerOpen ? this.closeDrawer() : this.openDrawer()
    },
    onLogoClicked () {
      const route = this.$mRouter.getLocalRoute({ name: 'index' })
      this.$router.push(route)
      if (this.isSameRoute(route)) {
        this.closeDrawer()
      }
    },
    isSameRoute (route) {
      return (
        ((route && route.location && route.location.name) || '') ===
        this.$route.name
      )
    }
  }
}
</script>

<style lang="postcss" scoped>
.navbar {
  height: var(--navbar-height);

  .drawer {
    .drawer-menu-wrapper {
      padding-top: var(--navbar-height);
    }
  }
}

.drawer-enter-active,
.drawer-leave-active {
  transition: all 0.3s;
  .drawer-menu-wrapper {
    transition: transform 0.3s;
  }
  .drawer-backdrop {
    transition: opacity 0.3s;
  }
}
.drawer-enter-from, .drawer-leave-to /* .fade-leave-active below version 2.1.8 */ {
  .drawer-menu-wrapper {
    transform: translateX(-100%);
  }
  .drawer-backdrop {
    opacity: 0;
  }
}

.drawer-enter-to, .drawer-leave /* .fade-leave-active below version 2.1.8 */ {
  .drawer-menu-wrapper {
    transform: translateX(0);
  }
  .drawer-backdrop {
    opacity: 1;
  }
}

.navbar-toggler {
  &:active,
  &:focus {
    outline: 0;
  }
  height: 1rem;
  width: 1.25rem;

  .toggle-line {
    width: 1.25rem;
    height: 3px;
    border-radius: 0;
    transition: all 0.35s;
    background-color: currentColor;

    &:nth-of-type(1) {
      transform: rotate(0);
    }

    &:nth-of-type(2) {
      opacity: 1;
    }

    &:nth-of-type(3) {
      transform: rotate(0);
      width: 60%;
    }
  }

  &.drawer-open {
    .toggle-line {
      &:nth-of-type(1) {
        transform: rotate(45deg);
        transform-origin: top left;
      }

      &:nth-of-type(2) {
        opacity: 0;
      }

      &:nth-of-type(3) {
        transform: rotate(-45deg);
        transform-origin: bottom left;
        width: 100%;
      }
    }
  }
}
</style>
