import {
  BrowserClient,
  defaultStackParser,
  getCurrentHub,
  makeFetchTransport,
  BrowserTracing
} from '@sentry/browser/esm'
import sentryUtil from '@/utils/sentry'

export default defineNuxtPlugin(() => {
  const env = useRuntimeConfig().public
  const release = env.isProduction
    ? sentryUtil.getReleaseVersion()
    : undefined

  const client = new BrowserClient({
    dsn: env.sentryKey,
    release,
    enabled: env.isProduction,
    environment: env.environment,
    integrations: [new BrowserTracing()],
    sampleRate: 1,
    tracesSampleRate: 1,
    transport: makeFetchTransport,
    stackParser: defaultStackParser,
    ignoreErrors: ['NotFoundException', '404']
  })

  getCurrentHub().bindClient(client)

  return {
    provide: {
      sentry: client
    }
  }
})
