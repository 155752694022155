export const appPageTransition = {"name":"page","mode":"out-in"}

export const appHead = {"meta":[{"charset":"utf-8"},{"name":"viewport","content":"width=device-width, initial-scale=1, minimum-scale=1"},{"hid":"og:type","property":"og:type","content":"website"},{"hid":"og:image","property":"og:image","content":"https://majority.imgix.net/web-assets/meta/og.png?fit=fill&auto=format%2Ccompress&lossless=true&fm=png&w=1200&h=630&fill=solid&fill-color=794DFF"}],"link":[{"rel":"icon","type":"image/png","href":"https://majority.imgix.net/web-assets/favicons/favicon96.png?fit=crop&auto=format%2Ccompress&lossless=true&fm=png&q=50&w=16","sizes":"16x16"},{"rel":"icon","type":"image/png","href":"https://majority.imgix.net/web-assets/favicons/favicon96.png?fit=crop&auto=format%2Ccompress&lossless=true&fm=png&q=50&w=32","sizes":"32x32"},{"rel":"icon","type":"image/png","href":"https://majority.imgix.net/web-assets/favicons/favicon96.png?fit=crop&auto=format%2Ccompress&lossless=true&fm=png&q=50&w=96","sizes":"96x96"},{"rel":"preload","as":"font","type":"font/woff2","href":"https://majoritycdn.freetls.fastly.net/fonts/druk/Druk-Medium-Web.woff2","crossorigin":"anonymous"},{"rel":"preload","as":"font","type":"font/woff2","href":"https://majoritycdn.freetls.fastly.net/fonts/gt-america/GT-America-Standard-Regular.woff2","crossorigin":"anonymous"},{"rel":"preload","as":"font","type":"font/woff2","href":"https://majoritycdn.freetls.fastly.net/fonts/gt-america/GT-America-Standard-Light.woff2","crossorigin":"anonymous"},{"rel":"preload","as":"font","type":"font/woff2","href":"https://majoritycdn.freetls.fastly.net/fonts/gt-america/GT-America-Standard-Medium.woff2","crossorigin":"anonymous"},{"rel":"preload","as":"font","type":"font/woff2","href":"https://majoritycdn.freetls.fastly.net/fonts/gt-america/GT-America-Extended-Regular.woff2","crossorigin":"anonymous"}],"style":[],"script":[],"noscript":[]}

export const appBaseURL = "/"

export const appBuildAssetsDir = "/_nuxt/"

export const appCdnURL = ""

export const appLayoutTransition = false

export const appKeepalive = false

export const appRootId = "__nuxt"

export const appRootTag = "div"

export const renderJsonPayloads = false

export const componentIslands = false

export const payloadExtraction = true

export const appManifest = true

export const remoteComponentIslands = false

export const devPagesDir = null

export const devRootDir = null

export const nuxtLinkDefaults = {"componentName":"NuxtLink"}

export const asyncDataDefaults = {"deep":true}

export const fetchDefaults = {}

export const vueAppRootContainer = '#__nuxt'