const getHelpEmail = (state) => {
  return state.helpEmail
}

const getPressEmail = (state) => {
  return state.pressEmail
}

const getContactNumber = (state) => {
  return state.contactNumber
}

const getFooterDisclaimer = (state) => {
  return state.footerDisclaimer
}

const getReviews = state => (locale) => {
  return state.reviews[locale]
}

const getMoneyTransferReview = state => (locale, country) => {
  return state.moneyTransferReviews[locale].find(x => x.country === country)
}

const getRandomReview = state => (locale, amount = 1) => {
  const reviews = JSON.parse(JSON.stringify(state.reviews[locale] || []))
  return reviews.sort(() => Math.random() - Math.random()).slice(0, amount)
}

export default {
  getContactNumber,
  getHelpEmail,
  getPressEmail,
  getFooterDisclaimer,
  getReviews,
  getMoneyTransferReview,
  getRandomReview
}
