import { isNullOrUndefined } from '@/utils/comparators'
import { applicationStore } from '@/store/application'

export default defineNuxtRouteMiddleware(async (to, from) => {
  const headers = useRequestHeaders()
  const head = useHead()
  const appStore = applicationStore()
  const userAgent = process.server ? headers['user-agent'] : navigator.userAgent
  appStore.setUserAgent(userAgent)

  if (process.server) {
    const isMajorityBrowser = !isNullOrUndefined(headers['x-majority-app'])
    const majorityApp = isMajorityBrowser ? headers['x-majority-app'] : null
    appStore.setIsMajorityBrowser(isMajorityBrowser)
    appStore.setMajorityApp(majorityApp)
    appStore.SET_HEADERS(headers)

    if (isMajorityBrowser) {
      if (isNullOrUndefined(head.htmlAttrs)) {
        head.htmlAttrs = {}
      }
      head.htmlAttrs['data-is-app'] = isMajorityBrowser
    }
  }
})
