import { defineStore } from 'pinia'
import getters from './getters'
import actions from './actions'

const namespaced = true

const state = () => ({
  userAgent: null,
  majorityBrowser: false,
  majorityApp: null,
  seenWordmark: false,
  headers: {},
  googleMapsStatus: null,
  menuDrawerIsOpen: false,
  diasporaDrawerIsOpen: false,
  signupModalIsOpen: false,
  completedReferralFlow: false,
  isReferralLinkValid: false
})

export const applicationStore = defineStore('application', {
  namespaced,
  state: () => state(),
  actions,
  getters
})

export default applicationStore
