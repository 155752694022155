<template>
  <a
    v-if="isUrl"
    v-bind="getProps"
    data-test-id="majority-link"
    :aria-label="text"
    v-on="getEvents"
  >
    <slot />
    <template v-if="!hasDefaultSlot">
      {{ text }}
    </template>
  </a>
  <NuxtLink
    v-else
    :to="to"
    data-test-id="majority-link"
    :aria-label="text"
    :exact="exact"
    :active-class="activeClass"
    :prefetch="false"
    event=""
    @click.prevent="handleLink(to)"
  >
    <slot />
    <template v-if="!hasDefaultSlot">
      {{ text }}
    </template>
  </NuxtLink>
</template>

<script>
import { mapState } from 'pinia'
import { applicationStore } from '~/store/application'
import { LINK_CLICKED_TYPE } from '@/utils/constants'
import { isEmpty } from '@/utils/comparators'
import { isUrl } from '~/utils/url'
export default {
  props: {
    to: {
      type: [String, Object],
      required: true
    },
    trackingName: {
      type: String,
      default: ''
    },
    trackingType: {
      type: String,
      validator: val => LINK_CLICKED_TYPE.includes(val),
      default: 'button'
    },
    text: {
      type: String,
      default: null
    },
    noTracking: {
      type: Boolean,
      default: false
    },
    noScrollTop: {
      type: Boolean,
      default: false
    },
    exact: {
      type: Boolean,
      default: false
    },
    replace: {
      type: Boolean,
      default: false
    },
    activeClass: {
      type: [String, Object, Array],
      default: ''
    },
    callback: {
      type: Function,
      default: () => {}
    }
  },
  computed: {
    ...mapState(applicationStore, ['menuDrawerIsOpen']),
    hasDefaultSlot () {
      return !!this.$slots.default || !!this.$slots.default
    },
    isUrl () {
      return isUrl(this.to)
    },
    getProps () {
      if (!this.isUrl) { return {} }
      if (this.to.includes('majority.com')) {
        return {
          href: this.to.split('majority.com')[1] || '/'
        }
      } else {
        return {
          href: this.to,
          target: '_blank',
          rel: 'noopener'
        }
      }
    },
    getEvents () {
      if (!this.isUrl) { return {} }

      if (this.to.includes('majority.com')) {
        return {
          click: (evt) => {
            evt.preventDefault()
            this.onLinkTrigger(evt)
          }
        }
      } else {
        return {
          click: () => this.handleLink(this.to)
        }
      }
    },
    getTo () {
      if (!this.isUrl) { return this.to }
      if (!this.to.includes('majority.com')) { return this.to }

      return this.to.split('majority.com')[1] || '/'
    }
  },
  methods: {
    onLinkTrigger (e) {
      let formattedInternalLink = this.to
      if (this.to.includes('majority.com')) {
        formattedInternalLink = this.to.split('majority.com')[1] || 'index'
      }

      if (this.guardEvent(e)) {
        this.$router.push(formattedInternalLink)
      }
    },
    isSameRoute (from, to) {
      return JSON.stringify(this.getRoute(from)) === JSON.stringify(this.getRoute(to))
    },
    getRoute ({ name, query = {}, params = {} }) {
      const response = {
        name,
        query,
        params
      }

      if (response.params.manual) {
        delete response.params.manual
      }

      return response
    },
    handleLink (url, key) {
      if (this.$route.name === url.name) {
        window.scrollTo({
          top: 0,
          left: 0,
          behavior: 'smooth'
        })
      }
      if (this.noTracking || isEmpty(this.trackingName)) { return }

      const data = { linkText: this.text }

      if (url) {
        data.linkUrl = url
      }

      if (key) {
        data.linkRouteKey = key
      }

      this.$track.linkClicked(this.trackingName, this.trackingType, data)
    },
    guardEvent (e) {
      // don't redirect with control keys
      if (e.metaKey || e.altKey || e.ctrlKey || e.shiftKey) { return }
      // don't redirect on right click
      if (e.button !== undefined && e.button !== 0) { return }
      // don't redirect if `target="_blank"`
      if (e.currentTarget && e.currentTarget.getAttribute) {
        const target = e.currentTarget.getAttribute('target')
        if (/\b_blank\b/i.test(target)) { return }
      }
      // this may be a Weex event which doesn't have this method
      if (e.preventDefault && !e.defaultPrevented) {
        e.preventDefault()
      }

      return true
    }
  }
}
</script>
