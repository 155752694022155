<template>
  <div
    v-click-away="onOutsideDropdown"
    class="relative self-center border border-medium-grey rounded-xl p-3"
    data-test-id="component-majority-country-input"
  >
    <button
      ref="country"
      type="button"
      aria-label="flag"
      data-test-id="button-flag"
      class="country-button flex items-center pr-4 focus:outline-none w-full relative"
      @click="toggleDropdown(null)"
    >
      <h4 class="text-black text-smaller font-light ml-3 mb-1">
        {{ selectedLocale.name }}
      </h4>
      <img
        src="/svg/caret-down.svg"
        aria-hidden
        alt="caret-down"
        class="chevron ml-1 absolute right-0"
        :class="{ 'transform rotate-180': showDropdown }"
      >
    </button>
    <transition
      name="expand"
      @enter="enter"
      @after-enter="afterEnter"
      @leave="leave"
    >
      <div
        v-if="showDropdown"
        class="dropdown flex-col bg-white text-dark shadow-dropdown-2 absolute top-[-2px] left-[-2px] right-[-2px] min-w-full rounded-xl z-10 border-white"
      >
        <ul
          ref="dropdownList"
          class="dropdown-list"
        >
          <li
            v-for="(item, index) in localeList"
            :key="index"
            class="hover:bg-light-grey hover:rounded-xl cursor-pointer block w-full text-left text-smaller leading-6 py-4 px-4 focus:outline-none md:truncate"
            @mousedown="onEnter(item)"
          >
            <div class="flex items-center select-none">
              {{ item.name }}
            </div>
          </li>
        </ul>
        <div v-if="showFadeEffect" class="fade-effect absolute w-full bottom-0" />
      </div>
    </transition>
  </div>
</template>

<script>
import { mixin as VueClickAway } from 'vue3-click-away'
import { isNullOrUndefined } from '@/utils/comparators'
export default {
  mixins: [VueClickAway],
  props: {
    populate: {
      type: String,
      default: null
    },
    value: {
      type: [String],
      default: ''
    },
    placeholder: {
      type: String,
      default: ''
    },
    emptyPlaceholder: {
      type: String,
      default: ''
    },
    hasFadeEffect: {
      type: Boolean,
      default: () => false
    },
    inputClass: {
      type: [String, Object, Array],
      default: ''
    }
  },
  emits: ['input'],
  setup () {
    const countriesApi = useCountries()
    return {
      countriesApi
    }
  },
  data () {
    return {
      showDropdown: false,
      displayFadeEffect: false,
      selectedItem: -1,
      selectedLocale: {
        name: 'English',
        value: 'en'
      },
      globeText: 'Global',
      localeList: [
        {
          name: 'English',
          value: 'en'
        },
        {
          name: 'Español',
          value: 'es'
        }
      ]
    }
  },
  computed: {
    getEmptyPlaceholder () {
      if (this.emptyPlaceholder) {
        return this.emptyPlaceholder
      }

      return this.$messages('dropdown.empty_placeholder')
    },
    showFadeEffect () {
      if (!this.hasFadeEffect) { return false }

      return this.displayFadeEffect
    }
  },
  mounted () {
    if (this.populate) {
      this.selectedLocale = this.localeList.find(x => x.value === this.populate)
    }
  },
  methods: {
    onOutsideDropdown () {
      this.showDropdown = false
    },
    toggleDropdown (forceState) {
      const targetState = isNullOrUndefined(forceState) ? !this.showDropdown : forceState
      this.showDropdown = targetState

      if (!this.showDropdown) {
        this.selectedItem = -1
      }
    },
    enter (el) {
      el.style.height = 'auto'
      const height = getComputedStyle(el).height
      el.style.height = 0
      getComputedStyle(el)
      setTimeout(() => {
        el.style.height = height
      })
    },
    afterEnter (el) {
      el.style.height = 'auto'
    },
    leave (el) {
      el.style.height = getComputedStyle(el).height

      getComputedStyle(el)

      setTimeout(() => {
        el.style.height = 0
      })
    },
    onEnter (item) {
      this.selectedLocale = item
      this.$emit('input', item.value)
      this.toggleDropdown(false)
    }
  }
}
</script>

  <style lang="postcss" scoped>
  .chevron {
  filter: invert(84%) sepia(5%) saturate(15%) hue-rotate(133deg) brightness(96%) contrast(92%);
}
  .expand-enter-active,
  .expand-leave-active {
    transition: height 0.2s ease-in-out;
    overflow: hidden;
  }

  .dropdown {
    display: flex;
    left: 0;
  }
  .dropdown-list {
    max-height: 120px;
    overflow-y: auto;

    @screen md {
      max-height: 230px;
    }
  }

  .fade-effect {
    height: 15%;
    pointer-events: none;
    background: linear-gradient(rgba(255, 255, 255, 0), rgba(255, 255, 255, 1) 81%)
  }

  @screen lg {
      .dropdown {
          left: 0;
          transform: none;
      }
  }
  </style>
