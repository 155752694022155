import { defineNuxtPlugin } from '#app/nuxt'
import { LazySvgoHelp2944728, LazySvgoHelp2952923, LazySvgoHelp2952980, LazySvgoHelp2952984, LazySvgoHelp2952989, LazySvgoHelp2952990, LazySvgoHelp2952994, LazySvgoHelp2955375, LazySvgoHelp2955394, LazySvgoHelp3033749 } from '#components'
const lazyGlobalComponents = [
  ["SvgoHelp2944728", LazySvgoHelp2944728],
["SvgoHelp2952923", LazySvgoHelp2952923],
["SvgoHelp2952980", LazySvgoHelp2952980],
["SvgoHelp2952984", LazySvgoHelp2952984],
["SvgoHelp2952989", LazySvgoHelp2952989],
["SvgoHelp2952990", LazySvgoHelp2952990],
["SvgoHelp2952994", LazySvgoHelp2952994],
["SvgoHelp2955375", LazySvgoHelp2955375],
["SvgoHelp2955394", LazySvgoHelp2955394],
["SvgoHelp3033749", LazySvgoHelp3033749],
  
]

export default defineNuxtPlugin({
  name: 'nuxt:global-components',
  setup (nuxtApp) {
    for (const [name, component] of lazyGlobalComponents) {
      nuxtApp.vueApp.component(name, component)
      nuxtApp.vueApp.component('Lazy' + name, component)
    }
  }
})
