/* eslint-disable @typescript-eslint/no-unused-vars */
import { SEVEN_DAYS_IN_SECONDS } from '@/utils/constants'

export default defineNuxtRouteMiddleware((to, from) => {
  const cookieOptions = {
    maxAge: SEVEN_DAYS_IN_SECONDS
  }

  if (process.client) {
    let hostname = window.location.hostname
    hostname = hostname.replace('www', '')
    cookieOptions.domain = hostname
  }

  const cookieNames = [
    'channel',
    'utm_campaign',
    'utm_source',
    'utm_medium',
    'utm_content',
    'utm_term',
    'campaign_code'
  ]

  const cookies = cookieNames.map((name) => {
    return [name, to.query[name]]
  })

  cookies.forEach(([name, value = null]) => {
    if (to.query[name]) {
      value = to.query[name]
    }
    if (value) {
      const c = useCookie(name, cookieOptions)
      c.value = value
    }
  })

  // FBC and FBP for Meta tracking. Backup for if the Meta Pixel fails to load.

  const timestamp = Date.now()

  const _fbc = useCookie('_fbc', cookieOptions)
  const fbclid = to.query.fbclid
  if (fbclid) {
    const fbctoken = `fb.1.${timestamp}.${fbclid}`
    _fbc.value = fbctoken
  }

  const _fbp = useCookie('_fbp', cookieOptions)
  if (!_fbp.value) {
    const randomNumber = Math.floor(Math.random() * 10000000000)
    const fbptoken = `fb.1.${timestamp}.${randomNumber}`
    _fbp.value = fbptoken
  }

  // TikTok, Google, and Microsoft tracking

  const tiktokTtclid = useCookie('tiktok_ttclid', cookieOptions)
  const ttclid = to.query.ttclid
  if (ttclid) {
    tiktokTtclid.value = ttclid
  }

  const googleGclid = useCookie('google_gclid', cookieOptions)
  const gclid = to.query.gclid
  if (gclid) {
    googleGclid.value = gclid
  }

  const microsoftMsclkid = useCookie('microsoft_msclkid', cookieOptions)
  const msclkid = to.query.msclkid
  if (msclkid) {
    microsoftMsclkid.value = msclkid
  }
})
