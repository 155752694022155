<template>
  <NuxtImg
    ref="currentImg"
    :src="outputSrc"
    :alt="alt"
    data-test-id="component-majority-image"
    :loading="lazy ? 'lazy' : 'eager'"
    :class="{
      'opacity-0': hasFadeIn && !showFadeIn,
      'opacity-100': hasFadeIn && showFadeIn,
      'w-full h-full': isBlank
    }"
    :style="{
      height: height
    }"
    @load="onLoad"
  />
</template>

<script setup>
import { isNull } from '@/utils/comparators'
import { optimize } from '@/utils/image-optimization'

const currentImg = ref(null)

const props = defineProps({
  src: {
    type: String,
    required: true
  },
  alt: {
    type: String,
    default: null
  },
  lazy: {
    type: Boolean,
    default: false
  },
  blur: {
    type: Boolean,
    default: false
  },
  fadeIn: {
    type: Boolean,
    default: false
  },
  ignoreDefaultOptmization: {
    type: Boolean,
    default: false
  },
  rootMargin: {
    type: String,
    default: '100px'
  },
  optimizedWidth: {
    type: Number,
    default: 0
  },
  optmizeOptions: {
    type: Object,
    default: () => {}
  },
  height: {
    type: String,
    default: 'auto'
  }
})

const loaded = ref(false)
const downloaded = ref(true)
const BLANK = 'data:image/svg+xml;charset=UTF-8,%3Csvg%20width%3D%221%22%20height%3D%221%22%20xmlns%3D%22http%3A%2F%2Fwww.w3.org%2F2000%2Fsvg%22%20viewBox%3D%220%200%20%25%7Bw%7D%20%25%7Bh%7D%22%20preserveAspectRatio%3D%22none%22%3E%3Crect%20width%3D%22100%25%22%20height%3D%22100%25%22%20style%3D%22fill%3Atransparent%3B%22%3E%3C%2Frect%3E%3C%2Fsvg%3E'
const { $device } = useNuxtApp()

const outputSrc = computed(() => {
  if (!props.lazy || loaded.value) {
    return (props.ignoreDefaultOptmization) ? props.src : optimize(props.src, false, getOptmizeOptions.value)
  }
  return props.blur ? optimize(props.src, true, getOptmizeOptions.value) : BLANK
})

const isBlank = computed(() => {
  return outputSrc.value === BLANK
})

const hasFadeIn = computed(() => {
  return props.fadeIn
})

const showFadeIn = computed(() => {
  if (!props.fadeIn) {
    return false
  }
  return loaded.value && downloaded.value
})

const getOptmizeOptions = computed(() => {
  const imgWidth = props.optimizedWidth ? props.optimizedWidth : $device.isMobile ? 430 : 640
  return { w: imgWidth, ...props.optmizeOptions }
})

const initializeIntersectionObserver = () => {
  try {
    return new IntersectionObserver(intersectionObserverCallback, {
      rootMargin: props.rootMargin,
      threshold: 0
    })
  } catch (error) {
    return null
  }
}

const intersectionObserverCallback = (entries, observer) => {
  if (!loaded.value) {
    const [entry] = entries
    if (entry && entry.isIntersecting) {
      loaded.value = true
    }
  }
}

const onLoad = () => {
  downloaded.value = true
}

onMounted(() => {
  const observer = initializeIntersectionObserver()
  if (!isNull(observer) && (currentImg.value.$el instanceof HTMLElement)) {
    observer.observe(currentImg.value.$el)
  } else {
    loaded.value = true
  }
})

</script>

<style lang="postcss" scoped>
img {
  transition: opacity .1s cubic-bezier(0.76, 0, 0.24, 1);
}
</style>
