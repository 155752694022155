<template>
  <div class="navigation-social-media-links-wrapper">
    <a
      v-for="(link, index) in links"
      :key="index"
      :href="link.link"
      :aria-label="link.label"
      target="_blank"
      rel="external nofollow noreferrer"
      class="inline-flex justify-center items-center w-large h-large text-white no-underline text-biggest mr-standart border-1 border-[#413E3D] rounded-full"
    >
      <MajorityUiMajorityIcon :icon="link.icon" class="-mb-2 text-base" />
    </a>
  </div>
</template>

<script>
export default {
  computed: {
    links () {
      return [
        {
          icon: 'facebook-simple',
          label: 'facebook icon',
          link: (this.$i18n.locale === 'es') ? 'https://www.facebook.com/majoritylatino/' : 'https://www.facebook.com/MAJORITYOfficial/'
        },
        {
          icon: 'instagram',
          label: 'instagram icon',
          link: (this.$i18n.locale === 'es') ? 'https://www.instagram.com/majoritylatino/' : 'https://instagram.com/majorityofficial'
        }
      ]
    }
  }
}
</script>

<style lang="postcss" scoped>

</style>
