import { useCountriesStore } from '@/store/countries'
export const useCountries = () => {
  const locale = useLocale()
  const countriesStore = useCountriesStore()
  const baseUrl = useRuntimeConfig().public.goliathUrl
  const client = useFetchClient(baseUrl, 'Goliath API - Countries endpoint')
  return {
    getAllCountries: async () => {
      if (countriesStore.countries.length === 0) {
        const url = `/countries/all?locale=${locale}`
        const response = await client.get(url)
        const countries = response.data.countries
        countriesStore.setCountries(countries)
      }
      return countriesStore.countries
    },
    getCountry: async (id) => {
      if (!countriesStore.country[id]) {
        const url = `/countries/${id}?locale=${locale}`
        const response = await client.get(url)
        const country = response.data.country
        countriesStore.setCountry(id, country)
      }
      return countriesStore.country[id]
    },
    getCountryBySlug: async (slug) => {
      if (!countriesStore.country[slug]) {
        const url = `/countries/slug/${slug}?locale=${locale}`
        const response = await client.get(url)
        const country = response.data.country
        countriesStore.setCountry(slug, country)
      }
      return countriesStore.country[slug]
    },
    getAllCountriesSorted: async (order = null) => {
      const key = order ? order.toString().replace(',', '-') : 'default'
      if (!countriesStore.sorted[key]) {
        const url = `/countries/sorted?locale=${locale}`
        const response = await client.post(url, { order })
        const countries = response.data.countries
        countriesStore.setSorted(key, countries)
      }
      return countriesStore.sorted[key]
    }
  }
}
