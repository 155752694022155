export const DEFAULT_LANGUAGE = 'en'
export const FALLBACK_LANGUAGE = 'en'

export const SUPPORTED_CULTURES = [{
  englishName: 'English',
  nativeName: 'English',
  twoLetter: 'en',
  culture: 'en-US',
  momentLocale: 'en',
  fbCulture: 'en_US'
}, {
  englishName: 'Spanish',
  nativeName: 'Español',
  twoLetter: 'es',
  culture: 'es-US',
  momentLocale: 'es-US',
  fbCulture: 'es_LA'
}]

export const DIASPORA_SUGGESTED_COUNTRIES = ['CO', 'CU', 'MX', 'VE']

export const DEFAULT_COOKIE_PATH = '/'

export const ONE_MINUTE_IN_MS = 60000

export const ONE_YEAR_IN_SECONDS = 31556952

export const SEVEN_DAYS_IN_SECONDS = 604800

export const THIRDY_DAYS_IN_SECONDS = 2592000

export const API_DEFAULT_TIMEOUT_MS = 30000

export const COUNTRY_COOKIE_KEY = 'mj.co.ct.nue'

export const COUNTRY_NAME_COOKIE_KEY = 'country_name'

export const LOCALE_COOKIE_KEY = 'i18n_redirected'

export const REFERRAL_COOKIE_KEY = 'referralId'

export const REFERRAL_DETAILS_COOKIE_KEY = 'referralDetails'

export const REFERRAL_COOKIE_EXPIRY = 86400

/**
 * Country Redirect Parameter used in route change to force a country.
 *
 * Valid values - countryId (Ex: CO, CU, US), and false (in case of going to generic)
 */
export const COUNTRY_REDIRECT_PARAMETER = 'countryRedirect'

/**
 * Key used in routes to set the generic key route, if there's no country specific page and a page with no country specific
 */
export const COUNTRY_KEY_GENERIC = 'GENERIC'

/**
 * Key used in routes to set the dynamic key route, if there's no country specific page and has a generic page for other countries
 */
export const COUNTRY_KEY_DYNAMIC = 'DYNAMIC'

/**
 * Route property to have the countryId of the route
 */
export const COUNTRY_ID_ROUTE_PROP = 'countryId'

/**
 * Route property that contains an object of routes for specific countries.
 *
 * Ex: { CO: { key: 'Colombia' } }
 */
export const COUNTRY_KEY_ROUTE_PROP = 'countryKeys'

export const ROUTE_INTERNAL_PARAMS = [COUNTRY_REDIRECT_PARAMETER, 'manual']

/**
 * CAUTION: If you breakpoint constants,
 * update Bootstrap breakpoints on assets/styles/variables/index.scss file as well
 */
export const BREAKPOINTS = {
  xs: 0,
  sm: 576,
  md: 768,
  lg: 1024,
  xl: 1200
}

export const DESTINATION_QUERY = 'destination'

//  Tracking
export const LINK_CLICKED_TYPE = ['button', 'main navigation', 'community navigation', 'community content', 'help content', 'cta', 'text', 'social', 'expand', 'sentence', 'app store', 'image', 'content tag']

// Brand Background Colors
export const BRAND_BACKGROUND_COLORS = [
  'bg-burgundy',
  'bg-coffee',
  'bg-red',
  'bg-turquoise',
  'bg-pink',
  'bg-beige',
  'bg-bitter-chocolate',
  'bg-forest-green',
  'bg-midnight-gray',
  'bg-dusty-gray',
  'bg-infra-violette',
  'bg-acid-green',
  'bg-bright-yellow',
  'bg-powder-blue',
  'bg-light-purple',
  'bg-light-green',
  'bg-transparent'
]

//  Community Active Content
export const COMMUNITY_ARTICLES = 'Articles'
export const COMMUNITY_EVENTS = 'Events'
export const COMMUNITY_VIDEOS = 'Videos'
export const COMMUNITY_SEARCH = 'Search'
export const COMMUNITY_ARTICLE_CATEGORY = 'ArticleCategory'
export const COMMUNITY_TAGS = 'Tags'
export const COMMUNITY_HOME = 'Home'

//  Country Diasporas
export const DIASPORAS_COUNTRY_ID = ['NG', 'CU', 'MX', 'CO', 'CM', 'GH', 'KE', 'ET', 'EC', 'NI', 'HN', 'DO']

export const GOOGLE_MAPS_API_URL = apiKey => `https://maps.googleapis.com/maps/api/js?key=${apiKey}&libraries=places`

export const DATO_IMAGE_TYPES = ['.png', '.jpg', '.jpeg']

export const APP_PRICE = 5.99

// Hard-coded sanctioned remittance countries
// TODO: remove this when the same information is available from the backend
export const SANCTIONED_REMITTANCE_COUNTRIES = ['AF', 'IR', 'KP', 'RU', 'SY', 'UA', 'VE']

// service-specific country sort
export const SERVICE_COUNTRIES = {
  MobileTopUps: ['CU', 'NG', 'MX', 'GH', 'KE', 'ET', 'CM'],
  InternationalCalling: ['NG', 'CU', 'MX', 'CO', 'CM', 'GH', 'KE', 'ET'],
  MoneyTransfers: ['CO', 'NG', 'MX', 'GH', 'KE', 'ET', 'CM', 'EC', 'HN', 'DO'],
  Membership: ['CU', 'CO', 'NG', 'MX', 'GH', 'KE', 'ET', 'CM', 'EC', 'HN', 'DO']
}

// Form Validation

export const NAME_VALIDATION = /^[a-zA-ZàáâäãåąaāčćęèéêëėēįìíîïīłńñòóôöõōøùúûüųūÿýżźñçčšžÀÁÂÄÃÅĀĄĆČĖĘÈÉÊËĒÌÍÎÏĪĮŁŃÒÓÔÖÕŌØÙÚÛÜŲŪŸÝŻŹÑßÇŒÆČŠŽ∂ð ,.'-]+$/u

export default {
  DEFAULT_LANGUAGE,
  FALLBACK_LANGUAGE,
  SUPPORTED_CULTURES,
  DEFAULT_COOKIE_PATH,
  ONE_MINUTE_IN_MS,
  ONE_YEAR_IN_SECONDS,
  SEVEN_DAYS_IN_SECONDS,
  THIRDY_DAYS_IN_SECONDS,
  API_DEFAULT_TIMEOUT_MS,
  COUNTRY_COOKIE_KEY,
  REFERRAL_COOKIE_KEY,
  REFERRAL_DETAILS_COOKIE_KEY,
  REFERRAL_COOKIE_EXPIRY,
  COUNTRY_REDIRECT_PARAMETER,
  COUNTRY_KEY_GENERIC,
  COUNTRY_KEY_DYNAMIC,
  COUNTRY_ID_ROUTE_PROP,
  COUNTRY_KEY_ROUTE_PROP,
  ROUTE_INTERNAL_PARAMS,
  BREAKPOINTS,
  DESTINATION_QUERY,
  LINK_CLICKED_TYPE,
  BRAND_BACKGROUND_COLORS,
  COMMUNITY_ARTICLES,
  COMMUNITY_EVENTS,
  COMMUNITY_VIDEOS,
  COMMUNITY_SEARCH,
  COMMUNITY_ARTICLE_CATEGORY,
  COMMUNITY_TAGS,
  COMMUNITY_HOME,
  DIASPORAS_COUNTRY_ID,
  GOOGLE_MAPS_API_URL,
  DATO_IMAGE_TYPES,
  APP_PRICE,
  SANCTIONED_REMITTANCE_COUNTRIES,
  SERVICE_COUNTRIES,
  NAME_VALIDATION
}
