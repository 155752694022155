<template>
  <button class="flex items-center justify-between" :class="[width === 'full' ? 'w-full' : '']" @click="showDiasporaModal()">
    <div class="flex items-center">
      <div class="h-8 w-8 rounded-full overflow-hidden">
        <MajorityUiMajorityImage v-if="!selectedDiaspora.country" height="32px" alt="flag" :src="globeImage" :style="{filter: iconColor === 'white' ? 'invert(1)' : 'invert(0)'}" />
        <MajorityUiMajorityImage v-if="selectedDiaspora.country" alt="flag" :src="flagPrefix + selectedDiaspora.country.toLowerCase() + '.svg'" />
      </div>
      <p class="font-light px-4" :style="{color: textColor}">
        {{ (countryCookie && countryTitle) ? countryTitle : $messages('label.global') }}
      </p>
    </div>
    <div class="h-[30px] w-[1px] bg-dark-grey" />
    <div class="flex items-center">
      <p class="font-light px-4" :style="{color: textColor}">
        {{ languageTitle }}
      </p>
      <MajorityUiMajorityIcon color="black" :icon="arrowIcon" class="arrow-button-icon w-8 h-8 flex justify-center items-center rounded-full bg-white" />
    </div>
  </button>
</template>
<script setup>
import { applicationStore } from '~/store/application'
import { SUPPORTED_CULTURES, COUNTRY_NAME_COOKIE_KEY, COUNTRY_COOKIE_KEY } from '@/utils/constants'
import { GLOBE_IMAGE } from '@/utils/resources'
const globeImage = GLOBE_IMAGE
const flagPrefix = 'https://majority.imgix.net/web-assets/flags/1x1/'
const appStore = applicationStore()
const selectedDiaspora = ref(getDiaspora())

const countryCookie = useCookie(COUNTRY_COOKIE_KEY).value

defineProps({
  textColor: {
    type: String,
    default: 'white'
  },
  iconColor: {
    type: String,
    default: 'white',
    enum: ['white', 'black']
  },
  width: {
    type: String,
    default: 'wrap',
    enum: ['wrap', 'full']
  },
  arrowIcon: {
    type: String,
    default: 'arrow-right'
  }
})

const showDiasporaModal = () => {
  appStore.setMenuDrawerIsOpen(false)
  appStore.setDiasporaDrawerIsOpen(true)
}

const languageTitle = computed(() => {
  return SUPPORTED_CULTURES.filter((el) => { return el.twoLetter === (selectedDiaspora.value.locale ? selectedDiaspora.value.locale : 'en') })[0].nativeName
})

const countryTitle = computed(() => {
  return useCookie(COUNTRY_NAME_COOKIE_KEY).value
})

</script>
