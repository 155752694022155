import validate from "/vercel/path0/node_modules/nuxt/dist/pages/runtime/validate.js";
import acquisition_45global from "/vercel/path0/middleware/acquisition.global.js";
import footer_45global from "/vercel/path0/middleware/footer.global.js";
import guard_45global from "/vercel/path0/middleware/guard.global.js";
import legal_45redirect_45global from "/vercel/path0/middleware/legal-redirect.global.js";
import query_45user_45context_45global from "/vercel/path0/middleware/query-user-context.global.js";
import route_45guard_45global from "/vercel/path0/middleware/route-guard.global.js";
import route_45global from "/vercel/path0/middleware/route.global.js";
import set_45locale_45global from "/vercel/path0/middleware/set-locale.global.js";
import user_45agent_45global from "/vercel/path0/middleware/user-agent.global.js";
import manifest_45route_45rule from "/vercel/path0/node_modules/nuxt/dist/app/middleware/manifest-route-rule.js";
export const globalMiddleware = [
  validate,
  acquisition_45global,
  footer_45global,
  guard_45global,
  legal_45redirect_45global,
  query_45user_45context_45global,
  route_45guard_45global,
  route_45global,
  set_45locale_45global,
  user_45agent_45global,
  manifest_45route_45rule
]
export const namedMiddleware = {
  community: () => import("/vercel/path0/middleware/community.js"),
  "country-redirect": () => import("/vercel/path0/middleware/country-redirect.js"),
  "diaspora-redirect": () => import("/vercel/path0/middleware/diaspora-redirect.js")
}