<template>
  <div
    v-click-away="onOutsideDropdown"
    class="relative self-center border border-medium-grey rounded-xl p-3"
    data-test-id="component-majority-country-input"
  >
    <button
      ref="country"
      type="button"
      aria-label="flag"
      data-test-id="button-flag"
      class="country-button flex items-center pr-4 focus:outline-none w-full relative"
      @click="toggleDropdown(null)"
    >
      <div class="h-8 w-8 rounded-full overflow-hidden">
        <MajorityUiMajorityImage v-if="!selectedCountry" class="h-8 w-8" alt="flag" :src="globeImage" />
        <MajorityUiMajorityImage v-if="selectedCountry" class="w-8 h-8" alt="flag" :src="selectedCountry.flags['1:1']" />
      </div>
      <h4 class="text-black text-smaller font-light ml-3 mb-1">
        {{ !selectedCountry ? globeText : selectedCountry.name }}
      </h4>
      <img
        src="/svg/caret-down.svg"
        aria-hidden
        alt="down-arrow"
        class="chevron ml-1 absolute right-0"
        :class="{ 'transform rotate-180': showDropdown }"
      >
    </button>
    <transition
      name="expand"
      @enter="enter"
      @after-enter="afterEnter"
      @leave="leave"
    >
      <div
        v-if="$device.isDesktop && showDropdown"
        class="dropdown flex-col bg-white text-dark shadow-dropdown-2 absolute top-[-2px] left-[-2px] right-[-2px] min-w-full rounded-xl z-10 border-white"
      >
        <input
          ref="searchInput"
          type="text"
          :placeholder="$messages('label.search')"
          class="countrySearchInput bg-transparent border border-medium-grey rounded-xl mb-2"
          data-test-id="search-input"
          @input="onSearchInput"
        >
        <div
          v-if="filteredItems.length === 0"
          class="px-2 py-1 text-center text-smaller"
        >
          {{ getEmptyPlaceholder }}
        </div>
        <div v-for="(listItem, i) in formatList(filteredItems)" :key="i">
          <p v-if="listItem.value.length > 0" class="pt-2 px-2 uppercase font-gt-america-extended text-tiny text-dark-grey">
            {{ listItem.name }}
          </p>
          <ul
            ref="dropdownList"
            class="dropdown-list mt-2"
          >
            <li
              v-for="(item, index) in listItem.value"
              :key="index"
              class="hover:bg-light-grey hover:rounded-xl cursor-pointer block w-full text-left text-smaller leading-6 py-2 px-2 focus:outline-none md:truncate"
              @mousedown="onEnter(item)"
            >
              <div class="flex items-center select-none">
                <div class="flag flex items-center mr-4 w-8 h-8 rounded-full overflow-hidden">
                  <MajorityUiMajorityImage class="w-8 h-8" alt="flag" :src="item.flags['1:1']" />
                </div> {{ item.name }}
              </div>
            </li>
          </ul>
        </div>
        <div v-if="showFadeEffect" class="fade-effect absolute w-full bottom-0" />
      </div>
    </transition>
    <SharedBottomSheet
      v-if="$device.isMobile && showDropdown"
      v-model="showDropdown"
      icon-color="black"
      class="h-full top-0"
      :title="$messages('selector.select_community')"
    >
      <input
        ref="searchInput"
        type="text"
        :placeholder="$messages('label.search')"
        class="countrySearchInput bg-transparent border border-medium-grey rounded-xl mb-8 mt-8"
        data-test-id="search-input"
        @input="onSearchInput"
      >
      <div
        v-if="filteredItems.length === 0"
        class="px-2 py-1 text-center text-smaller"
      >
        {{ getEmptyPlaceholder }}
      </div>
      <div v-for="(listItem, i) in formatList(filteredItems)" :key="i">
        <p v-if="listItem.value.length > 0" class="pt-2 px-2 mb-4 uppercase font-gt-america-extended text-tiny text-dark-grey">
          {{ listItem.name }}
        </p>
        <ul
          ref="dropdownList"
          class="dropdown-list"
        >
          <li
            v-for="(item, index) in listItem.value"
            :key="index"
            class="hover:bg-light-grey hover:rounded-xl cursor-pointer block w-full text-left text-smaller leading-6 py-2 px-2 focus:outline-none md:truncate"
            @mousedown="onEnter(item)"
          >
            <div class="flex items-center select-none">
              <div class="flag flex items-center mr-4 w-8 h-8 rounded-full overflow-hidden">
                <MajorityUiMajorityImage class="w-8 h-8" alt="flag" :src="item.flags['1:1']" />
              </div> {{ item.name }}
            </div>
          </li>
        </ul>
      </div>
    </SharedBottomSheet>
  </div>
</template>

<script>
import { mixin as VueClickAway } from 'vue3-click-away'
import { isNullOrUndefined } from '@/utils/comparators'
import { GLOBE_IMAGE } from '@/utils/resources'
export default {
  mixins: [VueClickAway],
  props: {
    populate: {
      type: String,
      default: null
    },
    value: {
      type: [String],
      default: ''
    },
    placeholder: {
      type: String,
      default: ''
    },
    emptyPlaceholder: {
      type: String,
      default: ''
    },
    hasFadeEffect: {
      type: Boolean,
      default: () => false
    },
    inputClass: {
      type: [String, Object, Array],
      default: ''
    },
    suggestedCountries: {
      type: Array,
      default: () => []
    }
  },
  emits: ['input'],
  setup () {
    const countriesApi = useCountries()
    return {
      countriesApi
    }
  },
  data () {
    return {
      showDropdown: false,
      displayFadeEffect: false,
      selectedItem: -1,
      items: [],
      filteredItems: [],
      selectedCountry: null,
      globeImage: GLOBE_IMAGE,
      globeText: 'Global',
      globalCountry: {
        id: 'global',
        name: 'Global',
        flags: {
          '1:1': GLOBE_IMAGE
        }
      }

    }
  },
  computed: {
    getEmptyPlaceholder () {
      if (this.emptyPlaceholder) {
        return this.emptyPlaceholder
      }

      return this.$messages('label.loading') + '...'
    },
    showFadeEffect () {
      if (!this.hasFadeEffect) { return false }

      return this.displayFadeEffect
    }
  },
  watch: {
    showDropdown (val) {
      if (val) {
        this.filteredItems = this.items
      }
    }
  },
  async mounted () {
    const items = await this.countriesApi.getAllCountries()
    this.items = Object.values(items)
    this.items.push(this.globalCountry)
    if (this.populate) {
      this.selectedCountry = await this.countriesApi.getCountry(this.populate)
    }
  },
  methods: {
    formatList (countries) {
      const suggestedCountries = countries.filter(country => this.suggestedCountries.includes(country.id))
      const remainingCountries = countries.filter(country => !this.suggestedCountries.includes(country.id))
      return [
        { name: this.$messages('label.suggested'), value: suggestedCountries },
        { name: this.$messages('label.all'), value: remainingCountries }
      ]
    },
    onSearchInput (event) {
      const term = event.target.value
      if (term.length >= 2) {
        this.filteredItems = this.items.filter(x => this.normalizeString(x.name).includes(this.normalizeString(term)))
      } else {
        this.filteredItems = this.items
      }
    },
    onOutsideDropdown () {
      this.showDropdown = false
    },
    toggleDropdown (forceState) {
      const targetState = isNullOrUndefined(forceState) ? !this.showDropdown : forceState
      this.showDropdown = targetState

      if (!this.showDropdown) {
        this.selectedItem = -1
      }
    },
    enter (el) {
      el.style.height = 'auto'
      const height = getComputedStyle(el).height
      el.style.height = 0
      getComputedStyle(el)
      setTimeout(() => {
        el.style.height = height
      })
    },
    afterEnter (el) {
      el.style.height = 'auto'
    },
    leave (el) {
      el.style.height = getComputedStyle(el).height

      getComputedStyle(el)

      setTimeout(() => {
        el.style.height = 0
      })
    },
    onEnter (item) {
      this.selectedCountry = item
      this.$emit('input', this.selectedCountry)
      this.toggleDropdown(false)
    },
    normalizeString (str) {
      return str
        .normalize('NFD')
        .replace(/[\u0300-\u036F]/g, '')
        .toLowerCase()
    }
  }
}
</script>

<style lang="postcss" scoped>
.chevron {
  filter: invert(84%) sepia(5%) saturate(15%) hue-rotate(133deg) brightness(96%) contrast(92%);
}
.countrySearchInput{
  width: 100%;
  background-image: url('https://majority.imgix.net/web-assets/svg/search.svg');
  background-position: 16px 19px;
  padding: 1rem 1rem 1rem 2.875rem;
  background-repeat: no-repeat;
}
.countrySearchInput::placeholder {
  color: #CBCDC9;
  font-weight: 300 !important;
}
.expand-enter-active,
.expand-leave-active {
  transition: height 0.2s ease-in-out;
  overflow: hidden;
}

.dropdown {
  display: flex;
  left: 0;
  max-height: 90vw;
}
.dropdown {
  max-height: 300px;
  overflow-y: auto;

  @screen md {
    max-height: 75vh;
  }
}

.fade-effect {
  height: 15%;
  pointer-events: none;
  background: linear-gradient(rgba(255, 255, 255, 0), rgba(255, 255, 255, 1) 81%)
}

@screen lg {
    .dropdown {
        transform: none;
    }
}
</style>
