import { COUNTRY_COOKIE_KEY, LOCALE_COOKIE_KEY, THIRDY_DAYS_IN_SECONDS } from '@/utils/constants'

export const setDiaspora = (country = null, locale = null) => {
  const countryCookie = useCookie(COUNTRY_COOKIE_KEY, {
    maxAge: THIRDY_DAYS_IN_SECONDS
  })
  const localeCookie = useCookie(LOCALE_COOKIE_KEY, {
    maxAge: THIRDY_DAYS_IN_SECONDS
  })
  countryCookie.value = country
  localeCookie.value = locale
}

export const getDiaspora = () => {
  const countryCookie = useCookie(COUNTRY_COOKIE_KEY)
  const localeCookie = useCookie(LOCALE_COOKIE_KEY)
  return {
    country: countryCookie.value,
    locale: localeCookie.value
  }
}
