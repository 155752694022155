<template>
  <MajorityUiMajorityCollapseBox :open="isVissible">
    <div class="info-bar-wrapper text-tiny" :class="[colorClasses]">
      <div class="flex justify-start py-4" :class="[paddingClass]">
        <button
          aria-label="Close icon"
          class="pr-2"
          @click="onDismiss"
        >
          <MajorityUiMajorityIcon icon="close" class="text-tiny mt-1" />
        </button>
        <p class="flex-grow m-0" v-html="message" />
      </div>
    </div>
  </MajorityUiMajorityCollapseBox>
</template>

<script>

export default {
  props: {
    type: {
      type: String,
      default: () => 'message',
      validator: (val) => {
        return ['message', 'info', 'warning'].includes(val)
      }
    },
    message: {
      type: String,
      required: true
    },
    container: {
      type: Boolean,
      default: () => false
    },
    show: {
      type: Boolean,
      default: () => true
    },
    lazyAnimation: {
      type: Boolean,
      default: () => false
    }
  },
  data () {
    return {
      isDismissed: this.lazyAnimation
    }
  },
  computed: {
    isVissible () {
      return this.show && !this.isDismissed
    },
    colorClasses () {
      switch (this.type) {
        case 'info':
          return 'bg-powder-blue text-dark'
        case 'warning':
          return 'bg-red text-white'
        default:
          return 'bg-light-green text-dusty-gray'
      }
    },
    paddingClass () {
      return this.container
        ? 'container'
        : 'px-4'
    }
  },
  mounted () {
    this.isDismissed = false
  },
  methods: {
    onDismiss () {
      this.isDismissed = true
      this.$emit('dismiss')
    }
  }
}
</script>

<style lang="postcss" scoped>

</style>
