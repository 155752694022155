<template>
  <i
    :class="iconClass"
    :style="{ color: color ? color : 'inherit' }"
    data-test-id="component-majority-icon"
  />
</template>

<script>
export default {
  props: {
    icon: {
      type: String,
      required: true
    },
    color: {
      type: String,
      default: null
    }
  },
  computed: {
    iconClass () {
      return `m-${this.icon}`
    }
  }
}
</script>

<style lang="postcss" scoped>
[data-icon]:before {
  font-family: 'majority-dsm' !important;
  content: attr(data-icon);
  font-style: normal !important;
  font-weight: normal !important;
  font-variant: normal !important;
  text-transform: none !important;
  speak: none;
  line-height: 1;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

[class^="m-"]:before,
[class*="m-"]:before {
  font-family: 'majority-dsm' !important;
  font-style: normal !important;
  font-weight: normal !important;
  font-variant: normal !important;
  text-transform: none !important;
  speak: none;
  line-height: 1;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

.m-facebook:before {
  content: "\e039";
}
.m-print:before {
  content: "\e03a";
}
.m-twitter:before {
  content: "\e03b";
}
.m-linkedin:before {
  content: "\e020";
}
.m-e-mail:before {
  content: "\e03c";
}
.m-back:before {
  content: "\e03d";
}
.m-download:before {
  content: "\e03e";
}
.m-playstore-simple:before {
  content: "\e040";
}
.m-twitter-simple:before {
  content: "\e041";
}
.m-instagram:before {
  content: "\e042";
}
.m-pdf:before {
  content: "\e03f";
}
.m-facebook-simple:before {
  content: "\e043";
}
.m-account:before {
  content: "\e000";
}
.m-activity:before {
  content: "\e001";
}
.m-add:before {
  content: "\e002";
}
.m-apple:before {
  content: "\e003";
}
.m-arrow-down:before {
  content: "\e004";
}
.m-arrow-left:before {
  content: "\e005";
}
.m-arrow-right:before {
  content: "\e006";
}
.m-arrow-up:before {
  content: "\e007";
}
.m-bills:before {
  content: "\e009";
}
.m-calling:before {
  content: "\e00a";
}
.m-card:before {
  content: "\e00b";
}
.m-checkmark:before {
  content: "\e00c";
}
.m-chevron-down:before {
  content: "\e00d";
}
.m-chevron-left:before {
  content: "\e00e";
}
.m-chevron-right:before {
  content: "\e00f";
}
.m-chevron-up:before {
  content: "\e010";
}
.m-clock:before {
  content: "\e011";
}
.m-close:before {
  content: "\e012";
}
.m-copy:before {
  content: "\e013";
}
.m-customer-support:before {
  content: "\e014";
}
.m-dialpad:before {
  content: "\e015";
}
.m-earning:before {
  content: "\e016";
}
.m-error:before {
  content: "\e017";
}
.m-faq:before {
  content: "\e018";
}
.m-feedback:before {
  content: "\e019";
}
.m-frustrated:before {
  content: "\e01a";
}
.m-gift:before {
  content: "\e01b";
}
.m-gps:before {
  content: "\e01c";
}
.m-happy:before {
  content: "\e01d";
}
.m-heart:before {
  content: "\e01e";
}
.m-info:before {
  content: "\e01f";
}
.MajorityUiMajorityLinkedin:before {
  content: "\e020";
}
.m-list:before {
  content: "\e021";
}
.m-local:before {
  content: "\e022";
}
.m-mail:before {
  content: "\e023";
}
.m-map-pin:before {
  content: "\e024";
}
.m-minus:before {
  content: "\e025";
}
.m-mobile-top-up:before {
  content: "\e026";
}
.m-people:before {
  content: "\e027";
}
.m-phone:before {
  content: "\e028";
}
.m-placeholder:before {
  content: "\e029";
}
.m-playstore:before {
  content: "\e02a";
}
.m-plus:before {
  content: "\e02b";
}
.m-refresh:before {
  content: "\e02c";
}
.m-search:before {
  content: "\e02d";
}
.m-send-money:before {
  content: "\e02e";
}
.m-settings:before {
  content: "\e02f";
}
.m-speech-bubble:before {
  content: "\e030";
}
.m-star:before {
  content: "\e031";
}
.m-success:before {
  content: "\e032";
}
.m-transfer:before {
  content: "\e033";
}
.m-trash:before {
  content: "\e034";
}
.m-wallet:before {
  content: "\e035";
}
.m-warning:before {
  content: "\e036";
}
.m-wifi:before {
  content: "\e037";
}
.m-world:before {
  content: "\e038";
}
.m-new-recipient:before {
  content: "\e008";
}
.m-bank:before {
  content: "\e044";
}

</style>
