import { withScope, captureException } from '@sentry/browser/esm'
import { BadRequestException, InternalServerErrorException, RequestException } from '../api/exceptions'

export const useFetchClient = (baseUrl = '', apiDescription = 'API') => {
  // write get, post, put, delete methods here using the native node fetch
  // https://fetch.spec.whatwg.org/#methods

  const predefinedHeaders = {
    'Content-Type': 'application/json; charset=UTF-8'
  }

  const get = async (url, headers = {}, ignoreSentry = false) => {
    const method = 'GET'
    const appendedHeaders = { ...predefinedHeaders, ...headers }
    try {
      const response = await fetch(`${baseUrl}${url}`, {
        method,
        headers: appendedHeaders
      })
      const data = await response.json()
      if(!ignoreSentry){
        const regex = /^40\d$/;
        const status = response.status
        if (!response.ok && status && !regex.test(status)) {
          captureError(response, method)
        }
      }
      return data
    } catch (error) {
      console.error(error)
    }
  }

  const post = async (url, body = {}, headers = {}, ignoreSentry = false) => {
    try {
      const method = 'POST'
      const appendedHeaders = { ...predefinedHeaders, ...headers }
      const response = await fetch(`${baseUrl}${url}`, {
        method,
        headers: appendedHeaders,
        body: JSON.stringify(body)
      })
      const data = await response.json()
      if(!ignoreSentry){
        const regex = /^40\d$/;
        const status = response.status
        if (!response.ok && status && !regex.test(status)) {
          captureError(response, method, body, data)
        }
      }
      return data
    } catch (error) {
      console.error(error)
    }
  }

  const put = async (url, body = {}, headers = {}, ignoreSentry = false) => {
    try {
      const method = 'PUT'
      const appendedHeaders = { ...predefinedHeaders, ...headers }
      const response = await fetch(`${baseUrl}${url}`, {
        method,
        headers: appendedHeaders,
        body: JSON.stringify(body)
      })
      const data = await response.json()
      if(!ignoreSentry){
        const regex = /^40\d$/;
        const status = response.status
        if (!response.ok && status && !regex.test(status)) {
          captureError(response, method, body, data)
        }
      }
      return data
    } catch (error) {
      console.error(error)
    }
  }

  const captureError = (response, method, body = null, data = null) => {
    const error = getException(apiDescription, response)
    const extras = {
      api: apiDescription,
      request: {
        url: response.url,
        method,
        headers: response.headers,
        body
      },
      response: {
        status: response.status,
        statusText: response.statusText,
        data: data || '-',
        headers: response.headers
      }
    }
    withScope((scope) => {
      scope.setExtras(extras)
      captureException(error)
    })
  }

  const getException = (apiName, response) => {
    const message = `[${apiName}] Request failed with status code ${response.status}`

    let newError = new RequestException(message, response)

    if (response && response.status === 400) {
      newError = new BadRequestException(message, response)
    }

    if (response && response.status === 500) {
      newError = new InternalServerErrorException(message, response)
    }

    return newError
  }

  return {
    get,
    post,
    put
  }
}
