export default [
  {
    paths: {
      en: '/help/',
      es: '/ayuda/'
    },
    key: 'Help',
    component: 'help/index',
    sitemap: true,
    translation: [
      'help'
    ],
    keepAlive: true
  },
  {
    paths: {
      en: '/help/article/[slug]/',
      es: '/ayuda/article/[slug]/'
    },
    key: 'HelpArticle',
    component: 'help/article/[slug]',
    sitemap: true,
    translation: [
      'help'
    ],
    keepAlive: true
  },
  {
    paths: {
      en: '/help/search/',
      es: '/ayuda/search/'
    },
    key: 'HelpSearchResult',
    component: 'help/search',
    sitemap: false,
    translation: [
      'help'
    ],
    keepAlive: true
  },
  {
    paths: {
      en: '/help/category/[slug]/[subcategory]/',
      es: '/ayuda/categoria/[slug]/[subcategory]/'
    },
    key: 'HelpSubcategory',
    component: 'help/category/[slug]/[subcategory]',
    sitemap: false,
    translation: [
      'help'
    ],
    keepAlive: true
  },
  {
    paths: {
      en: '/help/category/[slug]/',
      es: '/ayuda/categoria/[slug]/'
    },
    key: 'HelpCategory',
    component: 'help/category/[slug]/index',
    sitemap: true,
    translation: [
      'help'
    ],
    keepAlive: true
  }
]
