export default [
  {
    paths: {
      en: '/',
      es: '/'
    },
    key: 'Home',
    component: 'index',
    sitemap: true
  }
]
