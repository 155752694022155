import { createGtm } from '@gtm-support/vue-gtm'

export default defineNuxtPlugin((nuxtApp) => {
  const env = useRuntimeConfig().public
  return true
  nuxtApp.vueApp.use(
    createGtm({
      id: env.gtmContainerKey,
      defer: false,
      compatibility: false,
      enabled: true,
      debug: !env.isProduction,
      loadScript: true,
      vueRouter: useRouter(),
      trackOnNextTick: false,
      queryParams: env.isProduction
        ? {}
        : {
            gtm_auth: env.gtmAuthKey,
            gtm_preview: env.gtmPreviewKey,
            gtm_cookies_win: env.gtmCookiesWinKey
          }
    })
  )
})
