import { COUNTRY_ID_ROUTE_PROP, COUNTRY_KEY_ROUTE_PROP } from '../../utils/constants'
import aboutRoutes from './about'
import campaignRoutes from './campaign'
import communityRoutes from './community'
import helpRoutes from './help'
import internalRoutes from './internal'
import legalRoutes from './legal'
import locationsRoutes from './locations'
import mainRoutes from './main'
import pressRoutes from './press'
import productRoutes from './product'
import surveyRoutes from './survey'
import referralRoutes from './referral'
import redirectRoutes from './redirect'

const routes = [
  ...mainRoutes,
  ...redirectRoutes,
  ...productRoutes,
  ...campaignRoutes,
  ...communityRoutes,
  ...helpRoutes,
  ...aboutRoutes,
  ...legalRoutes,
  ...locationsRoutes,
  ...internalRoutes,
  ...pressRoutes,
  ...surveyRoutes,
  ...referralRoutes
]

const getRoutes = () => {
  return routes
}

const getLocaleRoutes = () => {
  const paths = {}
  for (const route of routes) {
    paths[route.component] = route.paths
  }
  return paths
}

const generateLocalRoutes = (routes, group) => {
  return new Promise((resolve) => {
    const _localRoutes = []
    routes.forEach((route) => {
      Object.keys(route.paths).forEach((locale) => {
        _localRoutes.push({
          path: route.paths[locale],
          name: `${route.key}__${locale}`,
          component: route.component,
          beforeEnter: route.beforeEnter,
          pathToRegexpOptions: { strict: true },
          meta: {
            locale,
            key: route.key,
            translation: route.translation,
            group,
            [COUNTRY_KEY_ROUTE_PROP]: route[COUNTRY_KEY_ROUTE_PROP],
            [COUNTRY_ID_ROUTE_PROP]: route[COUNTRY_ID_ROUTE_PROP],
            keepAlive: route.keepAlive === true
          }
        })
      })
    })
    resolve(_localRoutes)
  })
}

const routeBuilder = async () => {
  const defaultRoutes = [
    // {
    //   path: '/health/',
    //   name: 'Health',
    //   component: 'Health',
    //   pathToRegexpOptions: { strict: true }
    // }
  ]

  const promises = [
    generateLocalRoutes(mainRoutes, 'main'),
    generateLocalRoutes(referralRoutes, 'referral'),
    generateLocalRoutes(campaignRoutes, 'campaign'),
    generateLocalRoutes(communityRoutes, 'community'),
    generateLocalRoutes(helpRoutes, 'help'),
    generateLocalRoutes(aboutRoutes, 'about'),
    generateLocalRoutes(legalRoutes, 'legal'),
    generateLocalRoutes(locationsRoutes, 'locations'),
    generateLocalRoutes(internalRoutes, 'internal'),
    generateLocalRoutes(pressRoutes, 'press'),
    generateLocalRoutes(surveyRoutes, 'survey'),
    generateLocalRoutes(productRoutes, 'product')

  ]

  const newRoutes = await Promise.all(promises)

  return defaultRoutes.concat(...newRoutes)
}

export {
  routeBuilder,
  routes,
  getRoutes,
  getLocaleRoutes
}
