<template>
  <div v-if="!isMajorityBrowser" class="footer bg-dark">
    <div class="w-full bg-dark text-white pt-8 pb-10 d-print-none" data-test-id="component-the-footer">
      <div class="container-wrap">
        <div class="grid grid-cols-12 border-b border-b-white/5">
          <div class="col-span-6 col-start-2 mb-2">
            <SvgTheLogo class="footer-logo h-tiny lg:h-[18px]" />
          </div>
          <div v-if="!isExtPage" class="col-span-2 col-start-2 md:col-start-9 mt-8 md:mt-0">
            <h4 class="text-tiny opacity-50 mb-4">
              {{ $messages('label.app') }}
            </h4>
            <ul>
              <li v-for="(item, index) in footerSectionFirst" :key="index" class="mb-4">
                <MajorityUiMajorityLink
                  :to="item.route"
                  class="text-smallest text-white"
                >
                  {{ item.title }}
                </MajorityUiMajorityLink>
              </li>
            </ul>
          </div>
          <div class="col-span-2 col-start-7 md:col-start-11 mt-8 md:mt-0">
            <h4 v-if="!isExtPage" class="text-tiny opacity-50 mb-4">
              {{ $messages('label.about_us') }}
            </h4>
            <ul v-if="!isExtPage">
              <li v-for="(item, index) in footerSectionSecond" :key="index" class="mb-4">
                <MajorityUiMajorityLink
                  :to="item.route"
                  class="text-smallest text-white"
                >
                  {{ item.title }}
                </MajorityUiMajorityLink>
              </li>
            </ul>
          </div>
          <div class="col-span-6" />
        </div>
        <div class="grid grid-cols-12 border-b border-b-white/5 py-8">
          <div class="col-span-10 md:col-span-2 col-start-2 md:col-start-2">
            <LayoutNavigationLanguageSwitch v-if="!isExtPage" class="footer-language-switch text-dark" />
          </div>
          <div class="col-span-10 md:col-span-4 col-start-2 md:col-start-9 mt-8 md:mt-0">
            <LayoutNavigationSocialMediaLinks class="footer-social-media text-left" />
          </div>
        </div>
        <div class="grid grid-cols-12">
          <div class="col-span-10 col-start-2">
            <div class="footer-disclaimer text-[12px] text-dark-grey mt-10 md:mt-16 lg:mt-8">
              <template v-for="(el, index) in footerDisclaimerContent" :key="index">
                <p :class="(index === (footerDisclaimerContent.length - 1)) ? 'mb-3' : 'mb-0'" v-html="el" />
              </template>
              <p class="">
                © 2019–{{ getCurrentYear }} MAJORITY USA, LLC. {{ $messages('label.all_rights_reserved') }}
              </p>
              <p class="">
                {{ $messages('footer.address') }}
              </p>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { mapState } from 'pinia'
import { applicationContentStore } from '~/store/application/content'
import { applicationStore } from '~/store/application'

export default {
  props: {
    isExtPage: {
      type: Boolean,
      default: false
    }
  },
  data () {
    return {
      footerSectionFirst: [
        {
          title: this.$messages('page_labels.membership'),
          route: this.$mRouter.getLocalRoute({ name: 'membership' })
        },
        {
          title: this.$messages('page_labels.help_center'),
          route: this.$mRouter.getLocalRoute({ name: 'help' })
        },
        {
          title: this.$messages('page_labels.community'),
          route: this.$mRouter.getLocalRoute({ name: 'community' })
        }
      ],
      footerSectionSecond: [
        {
          title: this.$messages('page_labels.company'),
          route: this.$mRouter.getLocalRoute({ name: 'company' })
        },
        {
          title: this.$messages('page_labels.careers'),
          route: this.$mRouter.getLocalRoute({ name: 'company-career' })
        },
        {
          title: this.$messages('page_labels.press'),
          route: this.$mRouter.getLocalRoute({ name: 'press' })
        },
        {
          title: this.$messages('page_labels.legal'),
          route: this.$mRouter.getLocalRoute({ name: 'terms-of-use' })
        }
      ]
    }
  },
  computed: {
    ...mapState(applicationStore, ['isMajorityBrowser']),
    ...mapState(applicationContentStore, {
      footerDisclaimer: 'getFooterDisclaimer'
    }),
    hasFooterDisclaimer () {
      return !!this.footerDisclaimer
    },
    footerDisclaimerContent () {
      if (!this.hasFooterDisclaimer) {
        return this.$messages('footer.disclaimer')
      }

      let footer = this.footerDisclaimer

      if (!Array.isArray(this.footerDisclaimer) && this.footerDisclaimer.split) {
        footer = this.footerDisclaimer.split('\n')
      }

      return [...this.$messages('footer.disclaimer'), ...footer]
    },
    getCurrentYear () {
      return new Date().getFullYear()
    }
  }
}
</script>

<style lang="postcss" scoped>
.footer-grid {
  display: grid;

  grid-template-columns: 100%;
  grid-template-rows: auto;
  grid-template-areas:
      "logo"
      "language-switch"
      "navigation"
      "social-links"
      "disclaimer";

  .footer-logo {
    grid-area: logo
  }

  .footer-navigation-menu {
    grid-area: navigation;
  }

  .footer-language-switch {
    grid-area: language-switch;
  }

  .footer-social-media {
    grid-area: social-links;
  }

  .footer-disclaimer {
    grid-area: disclaimer;
  }

  @screen md {
    grid-template-columns: 1fr 1fr;
    grid-template-rows: auto auto auto auto;
    grid-template-areas:
      "logo logo"
      "navigation navigation"
      "disclaimer language-switch"
      "disclaimer social-links";
    column-gap: 6rem;
  }

  @screen lg {
    align-content: start;
    grid-template-columns: 1fr 2fr;
    grid-template-rows: auto auto auto 1fr;
    grid-template-areas:
      "logo logo"
      "language-switch navigation"
      "social-links navigation"
      "disclaimer navigation";
  }
}
</style>
