import { defineStore } from 'pinia'
import actions from './actions'
import getters from './getters'

const namespaced = true

const state = () => ({
  helpEmail: 'support@majority.com',
  contactNumber: '+1 (855) 553-3388',
  pressEmail: 'press@majority.com',
  footerDisclaimer: '',
  reviews: {
    en: [
      {
        locale: 'en',
        store: 'Google Play',
        stars: '5',
        body: 'Very cordial and ready-to-help customer service. Contains all I required to make things work for me. I love it with Majority!',
        name: 'Helen Ekwuribe'
      },
      {
        locale: 'en',
        store: 'Google Play',
        stars: '5',
        body: 'You guys are true to your words. You do what you say. Thanks.',
        name: 'Paul lashebikan'
      },
      {
        locale: 'en',
        store: 'Google Play',
        stars: '5',
        body: 'Excellent app, very convenient 👏👌👍😀',
        name: 'luis jimenez'
      },
      {
        locale: 'en',
        store: 'Google Play',
        stars: '5',
        body: 'Very efficient in their services',
        name: 'Joachim Ndubuisi'
      },
      {
        locale: 'en',
        store: 'Google Play',
        stars: '5',
        body: 'It’s cool and convenient to use',
        name: 'Olumuyiwya Otenaike'
      },
      {
        locale: 'en',
        store: 'Google Play',
        stars: '5',
        body: 'It has been easy transferring and recharging my people\'s phones back home.',
        name: 'Ndu ani'
      },
      {
        locale: 'en',
        store: 'App Store',
        stars: '5',
        body: 'I love that MAJORITY is accepted in all places. You can get money off it. It tells you how was used each time, money always available 24/7',
        name: 'lolo66!'
      },
      {
        locale: 'en',
        store: 'App Store',
        stars: '4',
        body: 'The service is good, I have recommended your service to my friends',
        name: 'Bobbyteejay81'
      },
      {
        locale: 'en',
        store: 'App Store',
        stars: '5',
        body: 'MAJORITY is my favorite. MAJORITY it makes things easier for me by topping up my mother cellphone and my mother in law and this gave my hope and courage for my love ones and this reason “I LOVE MAJORITY“',
        name: 'ayokunle faniyi'
      },
      {
        locale: 'en',
        store: 'App Store',
        stars: '5',
        body: 'Very good to use and friendly app and reliable',
        name: 'dapson ace'
      },
      {
        locale: 'en',
        store: 'App Store',
        stars: '5',
        body: 'Majority is great, I love using it',
        name: 'Ewurhie'
      },
      {
        locale: 'en',
        store: 'App Store',
        stars: '5',
        body: 'Majority is a great app and it has excellent customer service.',
        name: 'A Chidolue'
      }
    ],
    es: [
      {
        locale: 'es',
        store: 'Google Play',
        stars: '4',
        body: 'Es una app muy buena, Te comunica de una con muy buena calidad 👌 y lo mejor buen precio, La recomiendo',
        name: 'Heriberto Gonzalez Cabrera'
      },
      {
        locale: 'es',
        store: 'Google Play',
        stars: '5',
        body: 'Muy buena la app y la mejor para hacer recargas.',
        name: 'el joe joe'
      },
      {
        locale: 'es',
        store: 'Google Play',
        stars: '5',
        body: 'Buenisima',
        name: 'Julio Gonzalez'
      },
      {
        locale: 'es',
        store: 'Google Play',
        stars: '5',
        body: 'Muy bueno y prosperando a cada momento con todas las cosas que hace para sus cliente son buenas se lo agradecemos gracias 🙂',
        name: 'Marcial Gonzalez Paez'
      },
      {
        locale: 'es',
        store: 'Google Play',
        stars: '5',
        body: 'Hasta el momento la encuentro súper buena y más barato que en otras compañías que te cobran 22 gracias y sigan asi',
        name: 'Cienfuegos Ayy'
      },
      {
        locale: 'es',
        store: 'Google Play',
        stars: '5',
        body: 'Se oye bien clarito',
        name: 'Jose Delgado'
      },
      {
        locale: 'es',
        store: 'Google Play',
        stars: '5',
        body: 'Son muy profesional y da gusto conversar con ellos',
        name: 'Amelio Hernandez'
      },
      {
        locale: 'es',
        store: 'Google Play',
        stars: '5',
        body: '¡Me encanta! Práctica, económica y muy buena seguridad. La atención al cliente es la mejor. Se las recomiendo',
        name: 'IElizabetha Gonzalez'
      },
      {
        locale: 'es',
        store: 'Google Play',
        stars: '5',
        body: 'Desde que salió la app siempre me gusto y hoy me doy cuenta q es la mejor opción q hay en la actualidad',
        name: 'Alejandro Bravo'
      },
      {
        locale: 'es',
        store: 'Google Play',
        stars: '5',
        body: 'Me encanta MAJORITY utilizo mucho esta tarjeta y el dinero está seguro',
        name: 'Carmen ivon Rivas'
      },
      {
        locale: 'es',
        store: 'Google Play',
        stars: '5',
        body: 'MAJORITY para mi es una de las aplicación más perfecta y decente, confíen en ella llevo 2 años y me a ido espectacular y atienden muy bien a sus clientes gracias 🙂😊😁',
        name: 'yamile paula'
      },
      {
        locale: 'es',
        store: 'Google Play',
        stars: '5',
        body: 'Complacida con la aplicación es muy útil y rápida la comunicación. Para la recarga muy efectiva',
        name: 'DAMARIS VELAZQUEZ'
      },
      {
        locale: 'es',
        store: 'App Store',
        stars: '5',
        body: 'Me encanta, es muy buena y rápida la recomendaré a todas mis amistades y familia',
        name: 'MIGC0628'
      },
      {
        locale: 'es',
        store: 'App Store',
        stars: '5',
        body: 'Perfectos servicios. Funciona a la perfección la recomiendo.',
        name: 'Jaimell.'
      },
      {
        locale: 'es',
        store: 'App Store',
        stars: '5',
        body: 'Muy bueno a la verdad.',
        name: 'chinito d buen corazon'
      },
      {
        locale: 'es',
        store: 'App Store',
        stars: '5',
        body: 'Me encanta la aplicación, es muy versátil y todas las opciones las uso , es genial. La aplicación Majority es vanguardista , me encanta saber que tengo tantas herramientas en mi mano para que mi vida sea más fácil y acortar distancias , gracias Majority.',
        name: 'Alejobravro '
      },
      {
        locale: 'es',
        store: 'App Store',
        stars: '5',
        body: 'Me encanta, es súper completa, puedo manejar mi dinero, llamar a Venezuela al precio más bajo sin pagar nada fijo por llamadas internacionales y puedo llamar ilimitado a Colombia',
        name: 'Maryannga'
      }
    ]
  },
  moneyTransferReviews: {
    en: [{
      locale: 'en',
      country: 'NG',
      store: 'Google Play',
      stars: '5',
      body: 'I love the ease with which Majority helped me send money to my loved ones in Nigeria. It\'s been so helpful how easily I can add phone credits to my family\'s cell Iines. An amazing service. Kudos to the Management Team',
      name: 'Adesua Odihirin'
    }, {
      locale: 'en',
      country: 'MX',
      store: 'Google Play',
      stars: '5',
      body: 'Excellent app, very convenient',
      name: 'luis jimenez'
    }],
    es: [{
      locale: 'es',
      country: 'NG',
      store: 'Google Play',
      stars: '5',
      body: '¡Me encanta! Práctica, económica y muy buena seguridad. La atención al cliente es la mejor. Se las recomiendo',
      name: 'IElizabetha Gonzalez'
    }, {
      locale: 'es',
      country: 'MX',
      store: 'App Store',
      stars: '5',
      body: 'Me encanta la aplicación, es muy versátil y todas las opciones las uso, es genial. La aplicación Majority es vanguardista, me encanta saber que tengo tantas herramientas en mi mano para que mi vida sea más fácil y acortar distancias, gracias Majority.',
      name: 'Alejobravro'
    }]
  }
})

export const applicationContentStore = defineStore('applicationContent', {
  namespaced,
  state: () => state(),
  actions,
  getters
})

export default applicationContentStore
