export default [
  {
    paths: {
      en: '/routes/',
      es: '/routes/'
    },
    key: 'RoutesKey',
    component: 'company/Routes',
    sitemap: false,
    translation: ['about']
  },
  {
    paths: {
      en: '/company/',
      es: '/empresa/'
    },
    key: 'AboutMajority',
    component: 'company/index',
    sitemap: true,
    translation: ['about']
  },
  {
    paths: {
      en: '/company/career/',
      es: '/empresa/bolsa-trabajo/'
    },
    key: 'AboutMajorityCareer',
    component: 'company/career/index',
    sitemap: true,
    translation: ['about']
  },
  {
    paths: {
      en: '/company/career/position/[slug]/',
      es: '/empresa/bolsa-trabajo/puestos-disponibles/[slug]/'
    },
    key: 'AboutMajorityCareerDetails',
    component: 'company/career/position/[slug]',
    sitemap: true,
    translation: ['about']
  },
  {
    paths: {
      en: '/company/career/apply/',
      es: '/empresa/bolsa-trabajo/aplicar/'
    },
    key: 'AboutMajorityCareerApply',
    component: 'company/career/apply',
    sitemap: false,
    crawler: false,
    translation: ['about']
  }
]
