<template>
  <div
    class="download-banner-wrapper pr-standart bg-white flex items-center border-b border-medium-grey"
    :style="bannerTopMargin"
    data-test-id="block-the-navbar-download-banner"
  >
    <div class="">
      <button
        class="p-2 text-smallest opacity-muted"
        aria-label="Close icon"
        style="z-index: 10;"
        data-test-id="button-the-navbar-close-download-banner"
        @click="dismissBanner"
      >
        <MajorityUiMajorityIcon icon="close" />
      </button>
    </div>
    <div class="flex w-full h-full items-center flex-grow relative">
      <div>
        <MajorityUiMajorityImage
          src="https://majority.imgix.net/web-assets/logo/icon-96.png"
          height="48"
          width="48"
          class="app-icon h-12"
          alt="Logo"
        />
      </div>
      <div class="flex-grow mx-2">
        <p class="text-tiny font-gt-america leading-5 text-dark m-0">
          {{ $messages('label.install_majority') }}
        </p>

        <div>
          <MajorityUiMajorityIcon
            v-for="index in 5"
            :key="index"
            icon="star"
            class="text-bright-yellow"
          />
        </div>
      </div>
      <div>
        <button
          class="btn btn-infra-violette rounded-none text-tiny leading-4 tracking-tight py-1 px-micro uppercase"
          data-test-id="button-the-navbar-open-app-store"
          @click="goToAppSore"
        >
          {{ $messages('label.install') }}
        </button>
      </div>
    </div>
  </div>
</template>

<script>
import { mapState } from 'pinia'
import { isNullOrUndefined } from '@/utils/comparators'
import { applicationStore } from '~/store/application'
import { getAppLink } from '~/utils/acquisition-helpers'

export default {
  data () {
    return {
      currentScroll: 0,
      bannerHeight: 60
    }
  },
  computed: {
    ...mapState(applicationStore, ['isMajorityBrowser']),
    bannerTopMargin () {
      const marginValue =
        this.currentScroll > this.bannerHeight
          ? this.bannerHeight
          : this.currentScroll
      return {
        marginTop: `-${marginValue}px`
      }
    },
    getAppLink () {
      return getAppLink(this.$cookies.get('channel'), {
        utmSource: this.$cookies.get('utm_source'),
        utmMedium: this.$cookies.get('utm_medium'),
        utmCampaign: this.$cookies.get('utm_campaign'),
        utmContent: 'app_install_banner',
        utmTerm: this.$cookies.get('utm_term'),
        pageUrl: `${this.$env.websiteUrl}${this.$route.fullPath}`,
        virtualPageUrl: `${this.$env.websiteUrl}${this.$route.path}`
      })
    }
  },
  mounted () {
    this.listenScrollPosition()
    window.debug = this

    let style

    try {
      style = getComputedStyle(this.$el)
        .getPropertyValue('--download-app-banner-height')
        .match(new RegExp(/[0-9]+/, 'g'))
    } catch (error) {
      style = null
    }

    this.bannerHeight =
      !isNullOrUndefined(style) && style.length > 0 ? style[0] : 60
  },
  methods: {
    listenScrollPosition () {
      this.currentScroll = window.scrollY
      window.onscroll = () => {
        this.currentScroll = window.scrollY
      }
    },
    dismissBanner () {
      this.$cookies.set('hideStoreBanner', 1, { maxAge: 2592000, path: '/' })
      this.$emit('hide')
    },
    goToAppSore () {
      this.dismissBanner()
      window.open(this.getAppLink, '_blank')
    }
  }
}
</script>

<style lang="postcss" scoped>
.download-banner-wrapper {
  height: var(--download-app-banner-height);
}
</style>
