
export default [
  {
    paths: {
      en: '/internal/verify-email/',
      es: '/internal/verify-email/'
    },
    key: 'EmailVerification',
    component: 'internal/verify-email',
    sitemap: false
  },
  {
    paths: {
      en: '/internal/submit-ticket/',
      es: '/internal/enviar-ticket/'
    },
    key: 'AppSubmitTicket',
    component: 'internal/submit-ticket',
    sitemap: false
  }
]
