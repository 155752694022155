export default [
  {
    paths: {
      en: '/cuba/save/',
      es: '/cuba/ahorrar/'
    },
    component: 'campaign/cuba',
    sitemap: true
  },
  {
    paths: {
      en: '/nigeria/save/',
      es: '/nigeria/ahorrar/'
    },
    component: 'campaign/nigeria',
    sitemap: true
  },
  {
    paths: {
      en: '/campaign/[slug]/',
      es: '/campana/[slug]/'
    },
    component: 'campaign/[slug]',
    sitemap: false
  }
]
