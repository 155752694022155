const isMajorityBrowser = (state) => {
  return state.majorityBrowser || false
}

const getMajorityApp = (state) => {
  return state.majorityApp
}

const getUserAgent = (state) => {
  return state.userAgent
}

const hasSeenWordmark = (state) => {
  return state.seenWordmark
}

const getHeaders = (state) => {
  return state.headers
}

const getGoogleMapsStatus = (state) => {
  return state.googleMapsStatus
}

const getSignupModalIsOpen = (state) => {
  return state.signupModalIsOpen
}

const getCompletedReferralFlow = (state) => {
  return state.completedReferralFlow
}

export default {
  isMajorityBrowser,
  getMajorityApp,
  getUserAgent,
  hasSeenWordmark,
  getHeaders,
  getGoogleMapsStatus,
  getSignupModalIsOpen,
  getCompletedReferralFlow
}
