export const Channel = {
  Bing: 'bn',
  Google: 'gl',
  Yahoo: 'yh',
  Facebook: 'fb',
  Twitter: 'tw'
}

export const getAppLink = (channel, {
  utmSource = '',
  utmMedium = '',
  utmCampaign = '',
  utmContent = '',
  utmTerm = '',
  pageUrl = '',
  virtualPageUrl = ''
} = {}) => {
  const deepLink = `majority://wallet?utm_source=${encodeURIComponent(utmSource)}&utm_medium=${encodeURIComponent(utmMedium)}&utm_campaign=${encodeURIComponent(utmCampaign)}&utm_content=${encodeURIComponent(utmContent)}&utm_term=${encodeURIComponent(utmTerm)}&adj_campaign=${encodeURIComponent(utmCampaign)}&adj_adgroup=${encodeURIComponent(virtualPageUrl)}&adj_creative=${encodeURIComponent(utmContent)}&adj_fallback=${encodeURIComponent(pageUrl)}&engagement_type=fallback_click&adj_redirect_macos=${encodeURIComponent('https://apps.apple.com/us/app/majority-mobile-banking/id1458589760')}`
  const link = new URL(`https://93cz.adj.st/wallet?utm_source=${encodeURIComponent(utmSource)}&utm_medium=${encodeURIComponent(utmMedium)}&utm_campaign=${encodeURIComponent(utmCampaign)}&utm_content=${encodeURIComponent(utmContent)}&utm_term=${encodeURIComponent(utmTerm)}`)
  switch (channel) {
    case 'gl':
      link.searchParams.set('adj_t', '8t72lmf_a55xnji')
      break
    case 'fb':
      link.searchParams.set('adj_t', 'rzbnea6_s5bvtsh')
      break
    case 'yh':
      link.searchParams.set('adj_t', '7qyhpdg_z1ru7l0')
      break
    case 'bn':
      link.searchParams.set('adj_t', 'ub7bo9d_o5vluvo')
      break
    case 'tw':
      link.searchParams.set('adj_t', 'ijp5uwp_gkd569j')
      break
    default:
      link.searchParams.set('adj_t', 'hrx3hph_5zig7kc')
  }
  // Can't set the deeplink via searchParam setter since that would URL encode it. So just concatenating it here
  return `${link.toString()}&adj_deep_link=${deepLink}`
}

export const getSmsAppLink = (channel) => {
  const link = new URL('https://majority.app.link')
  switch (channel) {
    case Channel.Google:
      link.pathname = '/xDubWa2fg1'
      break
    case Channel.Facebook:
      link.pathname = '/OSRn0VVfg1'
      break
    case Channel.Yahoo:
      link.pathname = '/pQhpRiiog1'
      break
    case Channel.Bing:
      link.pathname = '/aQrtl7mog1'
      break
    case Channel.Twitter:
      link.pathname = 'klCWjgpog1'
      break
    default:
      link.pathname = '4nUsNCq3c1'
  }

  return link.toString()
}
