import { default as _91mtu_93tvH15jFbSjMeta } from "/vercel/path0/pages/[country]/[mtu].vue?macro=true";
import { default as index8ekunjS9Z9Meta } from "/vercel/path0/pages/[country]/index.vue?macro=true";
import { default as international_45callingoT9kOk3s4vMeta } from "/vercel/path0/pages/[country]/international-calling.vue?macro=true";
import { default as majority_45accountFHvQ4I2M1AMeta } from "/vercel/path0/pages/[country]/majority-account.vue?macro=true";
import { default as majority_45pay65vgqYUoNgMeta } from "/vercel/path0/pages/[country]/majority-pay.vue?macro=true";
import { default as money_45transferssQhazsqfPLMeta } from "/vercel/path0/pages/[country]/money-transfers.vue?macro=true";
import { default as visa_45debit_45cardoWdXLsDdQOMeta } from "/vercel/path0/pages/[country]/visa-debit-card.vue?macro=true";
import { default as _91slug_93XeIrtRY8x0Meta } from "/vercel/path0/pages/campaign/[slug].vue?macro=true";
import { default as cubabgVlMOJRg4Meta } from "/vercel/path0/pages/campaign/cuba.vue?macro=true";
import { default as nigerialiO74DhZYGMeta } from "/vercel/path0/pages/campaign/nigeria.vue?macro=true";
import { default as _91slug_93ue8ThhaTSsMeta } from "/vercel/path0/pages/community/[slug].vue?macro=true";
import { default as _91slug_93ROjF3reJNiMeta } from "/vercel/path0/pages/community/article/[slug].vue?macro=true";
import { default as _91slug_93aL5isVPGwHMeta } from "/vercel/path0/pages/community/discount/[slug].vue?macro=true";
import { default as _91slug_93H6ieq4I8KUMeta } from "/vercel/path0/pages/community/event/[slug].vue?macro=true";
import { default as indexww8wnGtvwPMeta } from "/vercel/path0/pages/community/index.vue?macro=true";
import { default as _91slug_93eLotGnFHyxMeta } from "/vercel/path0/pages/community/majority-tv/[slug].vue?macro=true";
import { default as migrant_45handbookHNYIKc6VZeMeta } from "/vercel/path0/pages/community/migrant-handbook.vue?macro=true";
import { default as _91slug_93RSH2oxTreVMeta } from "/vercel/path0/pages/community/tag/[slug].vue?macro=true";
import { default as applyYHmoXvZSuDMeta } from "/vercel/path0/pages/company/career/apply.vue?macro=true";
import { default as index5r8w87C1p2Meta } from "/vercel/path0/pages/company/career/index.vue?macro=true";
import { default as _91slug_936uyVozXTkgMeta } from "/vercel/path0/pages/company/career/position/[slug].vue?macro=true";
import { default as indexTHqTRxlhV2Meta } from "/vercel/path0/pages/company/index.vue?macro=true";
import { default as indexqjyoF6pbh5Meta } from "/vercel/path0/pages/data-deletion/index.vue?macro=true";
import { default as _91slug_9334sXn1PZQ3Meta } from "/vercel/path0/pages/help/article/[slug].vue?macro=true";
import { default as _91subcategory_93Ra0invBsJtMeta } from "/vercel/path0/pages/help/category/[slug]/[subcategory].vue?macro=true";
import { default as indexbzgYzYPN5fMeta } from "/vercel/path0/pages/help/category/[slug]/index.vue?macro=true";
import { default as indexluL2WM4aqkMeta } from "/vercel/path0/pages/help/index.vue?macro=true";
import { default as searchK3qv33UQFOMeta } from "/vercel/path0/pages/help/search.vue?macro=true";
import { default as indexe9Brt5DfdhMeta } from "/vercel/path0/pages/index.vue?macro=true";
import { default as _91slug_93zFawhlyJvXMeta } from "/vercel/path0/pages/internal/library/[slug].vue?macro=true";
import { default as _91country_93qQXa9iWpqDMeta } from "/vercel/path0/pages/internal/library/money-transfers/[country].vue?macro=true";
import { default as indexEjLM4FiSCqMeta } from "/vercel/path0/pages/internal/library/money-transfers/index.vue?macro=true";
import { default as submit_45ticketYke5FLqxIXMeta } from "/vercel/path0/pages/internal/submit-ticket.vue?macro=true";
import { default as verify_45emailLApvx33IjYMeta } from "/vercel/path0/pages/internal/verify-email.vue?macro=true";
import { default as calling_45ratesf1eINULvwMMeta } from "/vercel/path0/pages/international-calling/calling-rates.vue?macro=true";
import { default as indexA1LT9pfVyvMeta } from "/vercel/path0/pages/international-calling/index.vue?macro=true";
import { default as unlimited4cXKwmTbWuMeta } from "/vercel/path0/pages/international-calling/unlimited.vue?macro=true";
import { default as team_45majority2GpAGx8EkwMeta } from "/vercel/path0/pages/legal/privacy-policy/team-majority.vue?macro=true";
import { default as team_45majorityppg1N5cy7eMeta } from "/vercel/path0/pages/legal/terms-and-conditions/team-majority.vue?macro=true";
import { default as indexzKnoFAVuQpMeta } from "/vercel/path0/pages/locations/index.vue?macro=true";
import { default as get_45paid_45earlyMEhonNSXakMeta } from "/vercel/path0/pages/majority-account/get-paid-early.vue?macro=true";
import { default as indexMHIyjg1SRjMeta } from "/vercel/path0/pages/majority-account/index.vue?macro=true";
import { default as no_45hidden_45feesiCq3irP3v0Meta } from "/vercel/path0/pages/majority-account/no-hidden-fees.vue?macro=true";
import { default as no_45ssnpIPI9r5lcqMeta } from "/vercel/path0/pages/majority-account/no-ssn.vue?macro=true";
import { default as indexsCJAjPNFvkMeta } from "/vercel/path0/pages/majority-pay/index.vue?macro=true";
import { default as indexGcgr3G3PgaMeta } from "/vercel/path0/pages/membership/[country]/index.vue?macro=true";
import { default as indexZRSgJb8xJ7Meta } from "/vercel/path0/pages/membership/index.vue?macro=true";
import { default as indexHOHaQz7WNNMeta } from "/vercel/path0/pages/mobile-top-ups/index.vue?macro=true";
import { default as index4EMTMAgnfgMeta } from "/vercel/path0/pages/money-transfers/index.vue?macro=true";
import { default as indexOmJH2CTO3IMeta } from "/vercel/path0/pages/personalized-advisor/index.vue?macro=true";
import { default as _91category_93NgZvGRFck7Meta } from "/vercel/path0/pages/press/[category].vue?macro=true";
import { default as _91slug_93kAcGrqUgFwMeta } from "/vercel/path0/pages/press/article/[slug].vue?macro=true";
import { default as index9mGtdY37Z2Meta } from "/vercel/path0/pages/press/index.vue?macro=true";
import { default as _91category_93Hs1pWobJKnMeta } from "/vercel/path0/pages/press/material/[category].vue?macro=true";
import { default as _91slug_935cURNn2uRuMeta } from "/vercel/path0/pages/press/tag/[slug].vue?macro=true";
import { default as team_45majority73Ma2wxhliMeta } from "/vercel/path0/pages/privacy-policy/team-majority.vue?macro=true";
import { default as indexlqdIlivW5SMeta } from "/vercel/path0/pages/referral/index.vue?macro=true";
import { default as indexydGv8Ir5PuMeta } from "/vercel/path0/pages/t/index.vue?macro=true";
import { default as team_45majority6n4YTCP4w9Meta } from "/vercel/path0/pages/terms-and-conditions/team-majority.vue?macro=true";
import { default as _91slug_93SjHTecZFomMeta } from "/vercel/path0/pages/terms-of-use/[slug].vue?macro=true";
import { default as indexPhPz3MuV0pMeta } from "/vercel/path0/pages/terms-of-use/index.vue?macro=true";
import { default as atms9uR5VCIpt2Meta } from "/vercel/path0/pages/visa-debit-card/atms.vue?macro=true";
import { default as explore_45discounts68q40V9IUpMeta } from "/vercel/path0/pages/visa-debit-card/discounts/explore-discounts.vue?macro=true";
import { default as indexASV3CORU2eMeta } from "/vercel/path0/pages/visa-debit-card/discounts/index.vue?macro=true";
import { default as indexSZstZ9F5ngMeta } from "/vercel/path0/pages/visa-debit-card/index.vue?macro=true";
import { default as no_45ssnjwQsjIQvWHMeta } from "/vercel/path0/pages/visa-debit-card/no-ssn.vue?macro=true";
export default [
  {
    name: _91mtu_93tvH15jFbSjMeta?.name ?? "country-mtu",
    path: _91mtu_93tvH15jFbSjMeta?.path ?? "/:country()/:mtu()",
    meta: _91mtu_93tvH15jFbSjMeta || {},
    alias: _91mtu_93tvH15jFbSjMeta?.alias || [],
    redirect: _91mtu_93tvH15jFbSjMeta?.redirect || undefined,
    component: () => import("/vercel/path0/pages/[country]/[mtu].vue").then(m => m.default || m)
  },
  {
    name: _91mtu_93tvH15jFbSjMeta?.name ?? "country-mtu___en",
    path: _91mtu_93tvH15jFbSjMeta?.path ?? "/en/:country/:mtu",
    meta: _91mtu_93tvH15jFbSjMeta || {},
    alias: _91mtu_93tvH15jFbSjMeta?.alias || [],
    redirect: _91mtu_93tvH15jFbSjMeta?.redirect || undefined,
    component: () => import("/vercel/path0/pages/[country]/[mtu].vue").then(m => m.default || m)
  },
  {
    name: _91mtu_93tvH15jFbSjMeta?.name ?? "country-mtu___es",
    path: _91mtu_93tvH15jFbSjMeta?.path ?? "/es/:country/:mtu",
    meta: _91mtu_93tvH15jFbSjMeta || {},
    alias: _91mtu_93tvH15jFbSjMeta?.alias || [],
    redirect: _91mtu_93tvH15jFbSjMeta?.redirect || undefined,
    component: () => import("/vercel/path0/pages/[country]/[mtu].vue").then(m => m.default || m)
  },
  {
    name: index8ekunjS9Z9Meta?.name ?? "country",
    path: index8ekunjS9Z9Meta?.path ?? "/:country()",
    meta: index8ekunjS9Z9Meta || {},
    alias: index8ekunjS9Z9Meta?.alias || [],
    redirect: index8ekunjS9Z9Meta?.redirect || undefined,
    component: () => import("/vercel/path0/pages/[country]/index.vue").then(m => m.default || m)
  },
  {
    name: index8ekunjS9Z9Meta?.name ?? "country___en",
    path: index8ekunjS9Z9Meta?.path ?? "/en/:country",
    meta: index8ekunjS9Z9Meta || {},
    alias: index8ekunjS9Z9Meta?.alias || [],
    redirect: index8ekunjS9Z9Meta?.redirect || undefined,
    component: () => import("/vercel/path0/pages/[country]/index.vue").then(m => m.default || m)
  },
  {
    name: index8ekunjS9Z9Meta?.name ?? "country___es",
    path: index8ekunjS9Z9Meta?.path ?? "/es/:country",
    meta: index8ekunjS9Z9Meta || {},
    alias: index8ekunjS9Z9Meta?.alias || [],
    redirect: index8ekunjS9Z9Meta?.redirect || undefined,
    component: () => import("/vercel/path0/pages/[country]/index.vue").then(m => m.default || m)
  },
  {
    name: international_45callingoT9kOk3s4vMeta?.name ?? "country-international-calling",
    path: international_45callingoT9kOk3s4vMeta?.path ?? "/:country()/international-calling",
    meta: international_45callingoT9kOk3s4vMeta || {},
    alias: international_45callingoT9kOk3s4vMeta?.alias || [],
    redirect: international_45callingoT9kOk3s4vMeta?.redirect || undefined,
    component: () => import("/vercel/path0/pages/[country]/international-calling.vue").then(m => m.default || m)
  },
  {
    name: international_45callingoT9kOk3s4vMeta?.name ?? "country-international-calling___en",
    path: international_45callingoT9kOk3s4vMeta?.path ?? "/en/:country/international-calling",
    meta: international_45callingoT9kOk3s4vMeta || {},
    alias: international_45callingoT9kOk3s4vMeta?.alias || [],
    redirect: international_45callingoT9kOk3s4vMeta?.redirect || undefined,
    component: () => import("/vercel/path0/pages/[country]/international-calling.vue").then(m => m.default || m)
  },
  {
    name: international_45callingoT9kOk3s4vMeta?.name ?? "country-international-calling___es",
    path: international_45callingoT9kOk3s4vMeta?.path ?? "/es/:country/llamadas-internacionales",
    meta: international_45callingoT9kOk3s4vMeta || {},
    alias: international_45callingoT9kOk3s4vMeta?.alias || [],
    redirect: international_45callingoT9kOk3s4vMeta?.redirect || undefined,
    component: () => import("/vercel/path0/pages/[country]/international-calling.vue").then(m => m.default || m)
  },
  {
    name: majority_45accountFHvQ4I2M1AMeta?.name ?? "country-majority-account",
    path: majority_45accountFHvQ4I2M1AMeta?.path ?? "/:country()/majority-account",
    meta: majority_45accountFHvQ4I2M1AMeta || {},
    alias: majority_45accountFHvQ4I2M1AMeta?.alias || [],
    redirect: majority_45accountFHvQ4I2M1AMeta?.redirect || undefined,
    component: () => import("/vercel/path0/pages/[country]/majority-account.vue").then(m => m.default || m)
  },
  {
    name: majority_45accountFHvQ4I2M1AMeta?.name ?? "country-majority-account___en",
    path: majority_45accountFHvQ4I2M1AMeta?.path ?? "/en/:country/majority-account",
    meta: majority_45accountFHvQ4I2M1AMeta || {},
    alias: majority_45accountFHvQ4I2M1AMeta?.alias || [],
    redirect: majority_45accountFHvQ4I2M1AMeta?.redirect || undefined,
    component: () => import("/vercel/path0/pages/[country]/majority-account.vue").then(m => m.default || m)
  },
  {
    name: majority_45accountFHvQ4I2M1AMeta?.name ?? "country-majority-account___es",
    path: majority_45accountFHvQ4I2M1AMeta?.path ?? "/es/:country/cuenta-majority",
    meta: majority_45accountFHvQ4I2M1AMeta || {},
    alias: majority_45accountFHvQ4I2M1AMeta?.alias || [],
    redirect: majority_45accountFHvQ4I2M1AMeta?.redirect || undefined,
    component: () => import("/vercel/path0/pages/[country]/majority-account.vue").then(m => m.default || m)
  },
  {
    name: majority_45pay65vgqYUoNgMeta?.name ?? "country-majority-pay",
    path: majority_45pay65vgqYUoNgMeta?.path ?? "/:country()/majority-pay",
    meta: majority_45pay65vgqYUoNgMeta || {},
    alias: majority_45pay65vgqYUoNgMeta?.alias || [],
    redirect: majority_45pay65vgqYUoNgMeta?.redirect || undefined,
    component: () => import("/vercel/path0/pages/[country]/majority-pay.vue").then(m => m.default || m)
  },
  {
    name: majority_45pay65vgqYUoNgMeta?.name ?? "country-majority-pay___en",
    path: majority_45pay65vgqYUoNgMeta?.path ?? "/en/:country/majority-pay",
    meta: majority_45pay65vgqYUoNgMeta || {},
    alias: majority_45pay65vgqYUoNgMeta?.alias || [],
    redirect: majority_45pay65vgqYUoNgMeta?.redirect || undefined,
    component: () => import("/vercel/path0/pages/[country]/majority-pay.vue").then(m => m.default || m)
  },
  {
    name: majority_45pay65vgqYUoNgMeta?.name ?? "country-majority-pay___es",
    path: majority_45pay65vgqYUoNgMeta?.path ?? "/es/:country/majority-pay",
    meta: majority_45pay65vgqYUoNgMeta || {},
    alias: majority_45pay65vgqYUoNgMeta?.alias || [],
    redirect: majority_45pay65vgqYUoNgMeta?.redirect || undefined,
    component: () => import("/vercel/path0/pages/[country]/majority-pay.vue").then(m => m.default || m)
  },
  {
    name: money_45transferssQhazsqfPLMeta?.name ?? "country-money-transfers",
    path: money_45transferssQhazsqfPLMeta?.path ?? "/:country()/money-transfers",
    meta: money_45transferssQhazsqfPLMeta || {},
    alias: money_45transferssQhazsqfPLMeta?.alias || [],
    redirect: money_45transferssQhazsqfPLMeta?.redirect || undefined,
    component: () => import("/vercel/path0/pages/[country]/money-transfers.vue").then(m => m.default || m)
  },
  {
    name: money_45transferssQhazsqfPLMeta?.name ?? "country-money-transfers___en",
    path: money_45transferssQhazsqfPLMeta?.path ?? "/en/:country/money-transfers",
    meta: money_45transferssQhazsqfPLMeta || {},
    alias: money_45transferssQhazsqfPLMeta?.alias || [],
    redirect: money_45transferssQhazsqfPLMeta?.redirect || undefined,
    component: () => import("/vercel/path0/pages/[country]/money-transfers.vue").then(m => m.default || m)
  },
  {
    name: money_45transferssQhazsqfPLMeta?.name ?? "country-money-transfers___es",
    path: money_45transferssQhazsqfPLMeta?.path ?? "/es/:country/envios-dinero",
    meta: money_45transferssQhazsqfPLMeta || {},
    alias: money_45transferssQhazsqfPLMeta?.alias || [],
    redirect: money_45transferssQhazsqfPLMeta?.redirect || undefined,
    component: () => import("/vercel/path0/pages/[country]/money-transfers.vue").then(m => m.default || m)
  },
  {
    name: visa_45debit_45cardoWdXLsDdQOMeta?.name ?? "country-visa-debit-card",
    path: visa_45debit_45cardoWdXLsDdQOMeta?.path ?? "/:country()/visa-debit-card",
    meta: visa_45debit_45cardoWdXLsDdQOMeta || {},
    alias: visa_45debit_45cardoWdXLsDdQOMeta?.alias || [],
    redirect: visa_45debit_45cardoWdXLsDdQOMeta?.redirect || undefined,
    component: () => import("/vercel/path0/pages/[country]/visa-debit-card.vue").then(m => m.default || m)
  },
  {
    name: visa_45debit_45cardoWdXLsDdQOMeta?.name ?? "country-visa-debit-card___en",
    path: visa_45debit_45cardoWdXLsDdQOMeta?.path ?? "/en/:country/visa-debit-card",
    meta: visa_45debit_45cardoWdXLsDdQOMeta || {},
    alias: visa_45debit_45cardoWdXLsDdQOMeta?.alias || [],
    redirect: visa_45debit_45cardoWdXLsDdQOMeta?.redirect || undefined,
    component: () => import("/vercel/path0/pages/[country]/visa-debit-card.vue").then(m => m.default || m)
  },
  {
    name: visa_45debit_45cardoWdXLsDdQOMeta?.name ?? "country-visa-debit-card___es",
    path: visa_45debit_45cardoWdXLsDdQOMeta?.path ?? "/es/:country/tarjeta-visa-debito",
    meta: visa_45debit_45cardoWdXLsDdQOMeta || {},
    alias: visa_45debit_45cardoWdXLsDdQOMeta?.alias || [],
    redirect: visa_45debit_45cardoWdXLsDdQOMeta?.redirect || undefined,
    component: () => import("/vercel/path0/pages/[country]/visa-debit-card.vue").then(m => m.default || m)
  },
  {
    name: _91slug_93XeIrtRY8x0Meta?.name ?? "campaign-slug",
    path: _91slug_93XeIrtRY8x0Meta?.path ?? "/campaign/:slug()",
    meta: _91slug_93XeIrtRY8x0Meta || {},
    alias: _91slug_93XeIrtRY8x0Meta?.alias || [],
    redirect: _91slug_93XeIrtRY8x0Meta?.redirect || undefined,
    component: () => import("/vercel/path0/pages/campaign/[slug].vue").then(m => m.default || m)
  },
  {
    name: _91slug_93XeIrtRY8x0Meta?.name ?? "campaign-slug___en",
    path: _91slug_93XeIrtRY8x0Meta?.path ?? "/en/campaign/:slug",
    meta: _91slug_93XeIrtRY8x0Meta || {},
    alias: _91slug_93XeIrtRY8x0Meta?.alias || [],
    redirect: _91slug_93XeIrtRY8x0Meta?.redirect || undefined,
    component: () => import("/vercel/path0/pages/campaign/[slug].vue").then(m => m.default || m)
  },
  {
    name: _91slug_93XeIrtRY8x0Meta?.name ?? "campaign-slug___es",
    path: _91slug_93XeIrtRY8x0Meta?.path ?? "/es/campana/:slug",
    meta: _91slug_93XeIrtRY8x0Meta || {},
    alias: _91slug_93XeIrtRY8x0Meta?.alias || [],
    redirect: _91slug_93XeIrtRY8x0Meta?.redirect || undefined,
    component: () => import("/vercel/path0/pages/campaign/[slug].vue").then(m => m.default || m)
  },
  {
    name: cubabgVlMOJRg4Meta?.name ?? "campaign-cuba",
    path: cubabgVlMOJRg4Meta?.path ?? "/campaign/cuba",
    meta: cubabgVlMOJRg4Meta || {},
    alias: cubabgVlMOJRg4Meta?.alias || [],
    redirect: cubabgVlMOJRg4Meta?.redirect || undefined,
    component: () => import("/vercel/path0/pages/campaign/cuba.vue").then(m => m.default || m)
  },
  {
    name: cubabgVlMOJRg4Meta?.name ?? "campaign-cuba___en",
    path: cubabgVlMOJRg4Meta?.path ?? "/en/cuba/save",
    meta: cubabgVlMOJRg4Meta || {},
    alias: cubabgVlMOJRg4Meta?.alias || [],
    redirect: cubabgVlMOJRg4Meta?.redirect || undefined,
    component: () => import("/vercel/path0/pages/campaign/cuba.vue").then(m => m.default || m)
  },
  {
    name: cubabgVlMOJRg4Meta?.name ?? "campaign-cuba___es",
    path: cubabgVlMOJRg4Meta?.path ?? "/es/cuba/ahorrar",
    meta: cubabgVlMOJRg4Meta || {},
    alias: cubabgVlMOJRg4Meta?.alias || [],
    redirect: cubabgVlMOJRg4Meta?.redirect || undefined,
    component: () => import("/vercel/path0/pages/campaign/cuba.vue").then(m => m.default || m)
  },
  {
    name: nigerialiO74DhZYGMeta?.name ?? "campaign-nigeria",
    path: nigerialiO74DhZYGMeta?.path ?? "/campaign/nigeria",
    meta: nigerialiO74DhZYGMeta || {},
    alias: nigerialiO74DhZYGMeta?.alias || [],
    redirect: nigerialiO74DhZYGMeta?.redirect || undefined,
    component: () => import("/vercel/path0/pages/campaign/nigeria.vue").then(m => m.default || m)
  },
  {
    name: nigerialiO74DhZYGMeta?.name ?? "campaign-nigeria___en",
    path: nigerialiO74DhZYGMeta?.path ?? "/en/nigeria/save",
    meta: nigerialiO74DhZYGMeta || {},
    alias: nigerialiO74DhZYGMeta?.alias || [],
    redirect: nigerialiO74DhZYGMeta?.redirect || undefined,
    component: () => import("/vercel/path0/pages/campaign/nigeria.vue").then(m => m.default || m)
  },
  {
    name: nigerialiO74DhZYGMeta?.name ?? "campaign-nigeria___es",
    path: nigerialiO74DhZYGMeta?.path ?? "/es/nigeria/ahorrar",
    meta: nigerialiO74DhZYGMeta || {},
    alias: nigerialiO74DhZYGMeta?.alias || [],
    redirect: nigerialiO74DhZYGMeta?.redirect || undefined,
    component: () => import("/vercel/path0/pages/campaign/nigeria.vue").then(m => m.default || m)
  },
  {
    name: _91slug_93ue8ThhaTSsMeta?.name ?? "community-slug",
    path: _91slug_93ue8ThhaTSsMeta?.path ?? "/community/:slug()",
    meta: _91slug_93ue8ThhaTSsMeta || {},
    alias: _91slug_93ue8ThhaTSsMeta?.alias || [],
    redirect: _91slug_93ue8ThhaTSsMeta?.redirect || undefined,
    component: () => import("/vercel/path0/pages/community/[slug].vue").then(m => m.default || m)
  },
  {
    name: _91slug_93ue8ThhaTSsMeta?.name ?? "community-slug___en",
    path: _91slug_93ue8ThhaTSsMeta?.path ?? "/en/community/:slug",
    meta: _91slug_93ue8ThhaTSsMeta || {},
    alias: _91slug_93ue8ThhaTSsMeta?.alias || [],
    redirect: _91slug_93ue8ThhaTSsMeta?.redirect || undefined,
    component: () => import("/vercel/path0/pages/community/[slug].vue").then(m => m.default || m)
  },
  {
    name: _91slug_93ue8ThhaTSsMeta?.name ?? "community-slug___es",
    path: _91slug_93ue8ThhaTSsMeta?.path ?? "/es/comunidad/:slug",
    meta: _91slug_93ue8ThhaTSsMeta || {},
    alias: _91slug_93ue8ThhaTSsMeta?.alias || [],
    redirect: _91slug_93ue8ThhaTSsMeta?.redirect || undefined,
    component: () => import("/vercel/path0/pages/community/[slug].vue").then(m => m.default || m)
  },
  {
    name: _91slug_93ROjF3reJNiMeta?.name ?? "community-article-slug",
    path: _91slug_93ROjF3reJNiMeta?.path ?? "/community/article/:slug()",
    meta: _91slug_93ROjF3reJNiMeta || {},
    alias: _91slug_93ROjF3reJNiMeta?.alias || [],
    redirect: _91slug_93ROjF3reJNiMeta?.redirect || undefined,
    component: () => import("/vercel/path0/pages/community/article/[slug].vue").then(m => m.default || m)
  },
  {
    name: _91slug_93ROjF3reJNiMeta?.name ?? "community-article-slug___en",
    path: _91slug_93ROjF3reJNiMeta?.path ?? "/en/community/article/:slug",
    meta: _91slug_93ROjF3reJNiMeta || {},
    alias: _91slug_93ROjF3reJNiMeta?.alias || [],
    redirect: _91slug_93ROjF3reJNiMeta?.redirect || undefined,
    component: () => import("/vercel/path0/pages/community/article/[slug].vue").then(m => m.default || m)
  },
  {
    name: _91slug_93ROjF3reJNiMeta?.name ?? "community-article-slug___es",
    path: _91slug_93ROjF3reJNiMeta?.path ?? "/es/comunidad/article/:slug",
    meta: _91slug_93ROjF3reJNiMeta || {},
    alias: _91slug_93ROjF3reJNiMeta?.alias || [],
    redirect: _91slug_93ROjF3reJNiMeta?.redirect || undefined,
    component: () => import("/vercel/path0/pages/community/article/[slug].vue").then(m => m.default || m)
  },
  {
    name: _91slug_93aL5isVPGwHMeta?.name ?? "community-discount-slug",
    path: _91slug_93aL5isVPGwHMeta?.path ?? "/community/discount/:slug()",
    meta: _91slug_93aL5isVPGwHMeta || {},
    alias: _91slug_93aL5isVPGwHMeta?.alias || [],
    redirect: _91slug_93aL5isVPGwHMeta?.redirect || undefined,
    component: () => import("/vercel/path0/pages/community/discount/[slug].vue").then(m => m.default || m)
  },
  {
    name: _91slug_93aL5isVPGwHMeta?.name ?? "community-discount-slug___en",
    path: _91slug_93aL5isVPGwHMeta?.path ?? "/en/community/discount/:slug",
    meta: _91slug_93aL5isVPGwHMeta || {},
    alias: _91slug_93aL5isVPGwHMeta?.alias || [],
    redirect: _91slug_93aL5isVPGwHMeta?.redirect || undefined,
    component: () => import("/vercel/path0/pages/community/discount/[slug].vue").then(m => m.default || m)
  },
  {
    name: _91slug_93aL5isVPGwHMeta?.name ?? "community-discount-slug___es",
    path: _91slug_93aL5isVPGwHMeta?.path ?? "/es/comunidad/descuento/:slug",
    meta: _91slug_93aL5isVPGwHMeta || {},
    alias: _91slug_93aL5isVPGwHMeta?.alias || [],
    redirect: _91slug_93aL5isVPGwHMeta?.redirect || undefined,
    component: () => import("/vercel/path0/pages/community/discount/[slug].vue").then(m => m.default || m)
  },
  {
    name: _91slug_93H6ieq4I8KUMeta?.name ?? "community-event-slug",
    path: _91slug_93H6ieq4I8KUMeta?.path ?? "/community/event/:slug()",
    meta: _91slug_93H6ieq4I8KUMeta || {},
    alias: _91slug_93H6ieq4I8KUMeta?.alias || [],
    redirect: _91slug_93H6ieq4I8KUMeta?.redirect || undefined,
    component: () => import("/vercel/path0/pages/community/event/[slug].vue").then(m => m.default || m)
  },
  {
    name: _91slug_93H6ieq4I8KUMeta?.name ?? "community-event-slug___en",
    path: _91slug_93H6ieq4I8KUMeta?.path ?? "/en/community/event/:slug",
    meta: _91slug_93H6ieq4I8KUMeta || {},
    alias: _91slug_93H6ieq4I8KUMeta?.alias || [],
    redirect: _91slug_93H6ieq4I8KUMeta?.redirect || undefined,
    component: () => import("/vercel/path0/pages/community/event/[slug].vue").then(m => m.default || m)
  },
  {
    name: _91slug_93H6ieq4I8KUMeta?.name ?? "community-event-slug___es",
    path: _91slug_93H6ieq4I8KUMeta?.path ?? "/es/comunidad/evento/:slug",
    meta: _91slug_93H6ieq4I8KUMeta || {},
    alias: _91slug_93H6ieq4I8KUMeta?.alias || [],
    redirect: _91slug_93H6ieq4I8KUMeta?.redirect || undefined,
    component: () => import("/vercel/path0/pages/community/event/[slug].vue").then(m => m.default || m)
  },
  {
    name: indexww8wnGtvwPMeta?.name ?? "community",
    path: indexww8wnGtvwPMeta?.path ?? "/community",
    meta: indexww8wnGtvwPMeta || {},
    alias: indexww8wnGtvwPMeta?.alias || [],
    redirect: indexww8wnGtvwPMeta?.redirect || undefined,
    component: () => import("/vercel/path0/pages/community/index.vue").then(m => m.default || m)
  },
  {
    name: indexww8wnGtvwPMeta?.name ?? "community___en",
    path: indexww8wnGtvwPMeta?.path ?? "/en/community",
    meta: indexww8wnGtvwPMeta || {},
    alias: indexww8wnGtvwPMeta?.alias || [],
    redirect: indexww8wnGtvwPMeta?.redirect || undefined,
    component: () => import("/vercel/path0/pages/community/index.vue").then(m => m.default || m)
  },
  {
    name: indexww8wnGtvwPMeta?.name ?? "community___es",
    path: indexww8wnGtvwPMeta?.path ?? "/es/comunidad",
    meta: indexww8wnGtvwPMeta || {},
    alias: indexww8wnGtvwPMeta?.alias || [],
    redirect: indexww8wnGtvwPMeta?.redirect || undefined,
    component: () => import("/vercel/path0/pages/community/index.vue").then(m => m.default || m)
  },
  {
    name: _91slug_93eLotGnFHyxMeta?.name ?? "community-majority-tv-slug",
    path: _91slug_93eLotGnFHyxMeta?.path ?? "/community/majority-tv/:slug()",
    meta: _91slug_93eLotGnFHyxMeta || {},
    alias: _91slug_93eLotGnFHyxMeta?.alias || [],
    redirect: _91slug_93eLotGnFHyxMeta?.redirect || undefined,
    component: () => import("/vercel/path0/pages/community/majority-tv/[slug].vue").then(m => m.default || m)
  },
  {
    name: _91slug_93eLotGnFHyxMeta?.name ?? "community-majority-tv-slug___en",
    path: _91slug_93eLotGnFHyxMeta?.path ?? "/en/community/majority-tv/:slug",
    meta: _91slug_93eLotGnFHyxMeta || {},
    alias: _91slug_93eLotGnFHyxMeta?.alias || [],
    redirect: _91slug_93eLotGnFHyxMeta?.redirect || undefined,
    component: () => import("/vercel/path0/pages/community/majority-tv/[slug].vue").then(m => m.default || m)
  },
  {
    name: _91slug_93eLotGnFHyxMeta?.name ?? "community-majority-tv-slug___es",
    path: _91slug_93eLotGnFHyxMeta?.path ?? "/es/comunidad/majority-tv/:slug",
    meta: _91slug_93eLotGnFHyxMeta || {},
    alias: _91slug_93eLotGnFHyxMeta?.alias || [],
    redirect: _91slug_93eLotGnFHyxMeta?.redirect || undefined,
    component: () => import("/vercel/path0/pages/community/majority-tv/[slug].vue").then(m => m.default || m)
  },
  {
    name: migrant_45handbookHNYIKc6VZeMeta?.name ?? "community-migrant-handbook",
    path: migrant_45handbookHNYIKc6VZeMeta?.path ?? "/community/migrant-handbook",
    meta: migrant_45handbookHNYIKc6VZeMeta || {},
    alias: migrant_45handbookHNYIKc6VZeMeta?.alias || [],
    redirect: migrant_45handbookHNYIKc6VZeMeta?.redirect || undefined,
    component: () => import("/vercel/path0/pages/community/migrant-handbook.vue").then(m => m.default || m)
  },
  {
    name: migrant_45handbookHNYIKc6VZeMeta?.name ?? "community-migrant-handbook___en",
    path: migrant_45handbookHNYIKc6VZeMeta?.path ?? "/en/community/migrant-handbook",
    meta: migrant_45handbookHNYIKc6VZeMeta || {},
    alias: migrant_45handbookHNYIKc6VZeMeta?.alias || [],
    redirect: migrant_45handbookHNYIKc6VZeMeta?.redirect || undefined,
    component: () => import("/vercel/path0/pages/community/migrant-handbook.vue").then(m => m.default || m)
  },
  {
    name: migrant_45handbookHNYIKc6VZeMeta?.name ?? "community-migrant-handbook___es",
    path: migrant_45handbookHNYIKc6VZeMeta?.path ?? "/es/comunidad/guia-migrante",
    meta: migrant_45handbookHNYIKc6VZeMeta || {},
    alias: migrant_45handbookHNYIKc6VZeMeta?.alias || [],
    redirect: migrant_45handbookHNYIKc6VZeMeta?.redirect || undefined,
    component: () => import("/vercel/path0/pages/community/migrant-handbook.vue").then(m => m.default || m)
  },
  {
    name: _91slug_93RSH2oxTreVMeta?.name ?? "community-tag-slug",
    path: _91slug_93RSH2oxTreVMeta?.path ?? "/community/tag/:slug()",
    meta: _91slug_93RSH2oxTreVMeta || {},
    alias: _91slug_93RSH2oxTreVMeta?.alias || [],
    redirect: _91slug_93RSH2oxTreVMeta?.redirect || undefined,
    component: () => import("/vercel/path0/pages/community/tag/[slug].vue").then(m => m.default || m)
  },
  {
    name: _91slug_93RSH2oxTreVMeta?.name ?? "community-tag-slug___en",
    path: _91slug_93RSH2oxTreVMeta?.path ?? "/en/community/tag/:slug",
    meta: _91slug_93RSH2oxTreVMeta || {},
    alias: _91slug_93RSH2oxTreVMeta?.alias || [],
    redirect: _91slug_93RSH2oxTreVMeta?.redirect || undefined,
    component: () => import("/vercel/path0/pages/community/tag/[slug].vue").then(m => m.default || m)
  },
  {
    name: _91slug_93RSH2oxTreVMeta?.name ?? "community-tag-slug___es",
    path: _91slug_93RSH2oxTreVMeta?.path ?? "/es/comunidad/tag/:slug",
    meta: _91slug_93RSH2oxTreVMeta || {},
    alias: _91slug_93RSH2oxTreVMeta?.alias || [],
    redirect: _91slug_93RSH2oxTreVMeta?.redirect || undefined,
    component: () => import("/vercel/path0/pages/community/tag/[slug].vue").then(m => m.default || m)
  },
  {
    name: applyYHmoXvZSuDMeta?.name ?? "company-career-apply",
    path: applyYHmoXvZSuDMeta?.path ?? "/company/career/apply",
    meta: applyYHmoXvZSuDMeta || {},
    alias: applyYHmoXvZSuDMeta?.alias || [],
    redirect: applyYHmoXvZSuDMeta?.redirect || undefined,
    component: () => import("/vercel/path0/pages/company/career/apply.vue").then(m => m.default || m)
  },
  {
    name: applyYHmoXvZSuDMeta?.name ?? "company-career-apply___en",
    path: applyYHmoXvZSuDMeta?.path ?? "/en/company/career/apply",
    meta: applyYHmoXvZSuDMeta || {},
    alias: applyYHmoXvZSuDMeta?.alias || [],
    redirect: applyYHmoXvZSuDMeta?.redirect || undefined,
    component: () => import("/vercel/path0/pages/company/career/apply.vue").then(m => m.default || m)
  },
  {
    name: applyYHmoXvZSuDMeta?.name ?? "company-career-apply___es",
    path: applyYHmoXvZSuDMeta?.path ?? "/es/empresa/bolsa-trabajo/aplicar",
    meta: applyYHmoXvZSuDMeta || {},
    alias: applyYHmoXvZSuDMeta?.alias || [],
    redirect: applyYHmoXvZSuDMeta?.redirect || undefined,
    component: () => import("/vercel/path0/pages/company/career/apply.vue").then(m => m.default || m)
  },
  {
    name: index5r8w87C1p2Meta?.name ?? "company-career",
    path: index5r8w87C1p2Meta?.path ?? "/company/career",
    meta: index5r8w87C1p2Meta || {},
    alias: index5r8w87C1p2Meta?.alias || [],
    redirect: index5r8w87C1p2Meta?.redirect || undefined,
    component: () => import("/vercel/path0/pages/company/career/index.vue").then(m => m.default || m)
  },
  {
    name: index5r8w87C1p2Meta?.name ?? "company-career___en",
    path: index5r8w87C1p2Meta?.path ?? "/en/company/career",
    meta: index5r8w87C1p2Meta || {},
    alias: index5r8w87C1p2Meta?.alias || [],
    redirect: index5r8w87C1p2Meta?.redirect || undefined,
    component: () => import("/vercel/path0/pages/company/career/index.vue").then(m => m.default || m)
  },
  {
    name: index5r8w87C1p2Meta?.name ?? "company-career___es",
    path: index5r8w87C1p2Meta?.path ?? "/es/empresa/bolsa-trabajo",
    meta: index5r8w87C1p2Meta || {},
    alias: index5r8w87C1p2Meta?.alias || [],
    redirect: index5r8w87C1p2Meta?.redirect || undefined,
    component: () => import("/vercel/path0/pages/company/career/index.vue").then(m => m.default || m)
  },
  {
    name: _91slug_936uyVozXTkgMeta?.name ?? "company-career-position-slug",
    path: _91slug_936uyVozXTkgMeta?.path ?? "/company/career/position/:slug()",
    meta: _91slug_936uyVozXTkgMeta || {},
    alias: _91slug_936uyVozXTkgMeta?.alias || [],
    redirect: _91slug_936uyVozXTkgMeta?.redirect || undefined,
    component: () => import("/vercel/path0/pages/company/career/position/[slug].vue").then(m => m.default || m)
  },
  {
    name: _91slug_936uyVozXTkgMeta?.name ?? "company-career-position-slug___en",
    path: _91slug_936uyVozXTkgMeta?.path ?? "/en/company/career/position/:slug",
    meta: _91slug_936uyVozXTkgMeta || {},
    alias: _91slug_936uyVozXTkgMeta?.alias || [],
    redirect: _91slug_936uyVozXTkgMeta?.redirect || undefined,
    component: () => import("/vercel/path0/pages/company/career/position/[slug].vue").then(m => m.default || m)
  },
  {
    name: _91slug_936uyVozXTkgMeta?.name ?? "company-career-position-slug___es",
    path: _91slug_936uyVozXTkgMeta?.path ?? "/es/empresa/bolsa-trabajo/puestos-disponibles/:slug",
    meta: _91slug_936uyVozXTkgMeta || {},
    alias: _91slug_936uyVozXTkgMeta?.alias || [],
    redirect: _91slug_936uyVozXTkgMeta?.redirect || undefined,
    component: () => import("/vercel/path0/pages/company/career/position/[slug].vue").then(m => m.default || m)
  },
  {
    name: indexTHqTRxlhV2Meta?.name ?? "company",
    path: indexTHqTRxlhV2Meta?.path ?? "/company",
    meta: indexTHqTRxlhV2Meta || {},
    alias: indexTHqTRxlhV2Meta?.alias || [],
    redirect: indexTHqTRxlhV2Meta?.redirect || undefined,
    component: () => import("/vercel/path0/pages/company/index.vue").then(m => m.default || m)
  },
  {
    name: indexTHqTRxlhV2Meta?.name ?? "company___en",
    path: indexTHqTRxlhV2Meta?.path ?? "/en/company",
    meta: indexTHqTRxlhV2Meta || {},
    alias: indexTHqTRxlhV2Meta?.alias || [],
    redirect: indexTHqTRxlhV2Meta?.redirect || undefined,
    component: () => import("/vercel/path0/pages/company/index.vue").then(m => m.default || m)
  },
  {
    name: indexTHqTRxlhV2Meta?.name ?? "company___es",
    path: indexTHqTRxlhV2Meta?.path ?? "/es/empresa",
    meta: indexTHqTRxlhV2Meta || {},
    alias: indexTHqTRxlhV2Meta?.alias || [],
    redirect: indexTHqTRxlhV2Meta?.redirect || undefined,
    component: () => import("/vercel/path0/pages/company/index.vue").then(m => m.default || m)
  },
  {
    name: indexqjyoF6pbh5Meta?.name ?? "data-deletion",
    path: indexqjyoF6pbh5Meta?.path ?? "/data-deletion",
    meta: indexqjyoF6pbh5Meta || {},
    alias: indexqjyoF6pbh5Meta?.alias || [],
    redirect: indexqjyoF6pbh5Meta?.redirect || undefined,
    component: () => import("/vercel/path0/pages/data-deletion/index.vue").then(m => m.default || m)
  },
  {
    name: indexqjyoF6pbh5Meta?.name ?? "data-deletion___en",
    path: indexqjyoF6pbh5Meta?.path ?? "/en/data-deletion",
    meta: indexqjyoF6pbh5Meta || {},
    alias: indexqjyoF6pbh5Meta?.alias || [],
    redirect: indexqjyoF6pbh5Meta?.redirect || undefined,
    component: () => import("/vercel/path0/pages/data-deletion/index.vue").then(m => m.default || m)
  },
  {
    name: indexqjyoF6pbh5Meta?.name ?? "data-deletion___es",
    path: indexqjyoF6pbh5Meta?.path ?? "/es/eliminacion-de-datos",
    meta: indexqjyoF6pbh5Meta || {},
    alias: indexqjyoF6pbh5Meta?.alias || [],
    redirect: indexqjyoF6pbh5Meta?.redirect || undefined,
    component: () => import("/vercel/path0/pages/data-deletion/index.vue").then(m => m.default || m)
  },
  {
    name: _91slug_9334sXn1PZQ3Meta?.name ?? "help-article-slug",
    path: _91slug_9334sXn1PZQ3Meta?.path ?? "/help/article/:slug()",
    meta: _91slug_9334sXn1PZQ3Meta || {},
    alias: _91slug_9334sXn1PZQ3Meta?.alias || [],
    redirect: _91slug_9334sXn1PZQ3Meta?.redirect || undefined,
    component: () => import("/vercel/path0/pages/help/article/[slug].vue").then(m => m.default || m)
  },
  {
    name: _91slug_9334sXn1PZQ3Meta?.name ?? "help-article-slug___en",
    path: _91slug_9334sXn1PZQ3Meta?.path ?? "/en/help/article/:slug",
    meta: _91slug_9334sXn1PZQ3Meta || {},
    alias: _91slug_9334sXn1PZQ3Meta?.alias || [],
    redirect: _91slug_9334sXn1PZQ3Meta?.redirect || undefined,
    component: () => import("/vercel/path0/pages/help/article/[slug].vue").then(m => m.default || m)
  },
  {
    name: _91slug_9334sXn1PZQ3Meta?.name ?? "help-article-slug___es",
    path: _91slug_9334sXn1PZQ3Meta?.path ?? "/es/ayuda/article/:slug",
    meta: _91slug_9334sXn1PZQ3Meta || {},
    alias: _91slug_9334sXn1PZQ3Meta?.alias || [],
    redirect: _91slug_9334sXn1PZQ3Meta?.redirect || undefined,
    component: () => import("/vercel/path0/pages/help/article/[slug].vue").then(m => m.default || m)
  },
  {
    name: _91subcategory_93Ra0invBsJtMeta?.name ?? "help-category-slug-subcategory",
    path: _91subcategory_93Ra0invBsJtMeta?.path ?? "/help/category/:slug()/:subcategory()",
    meta: _91subcategory_93Ra0invBsJtMeta || {},
    alias: _91subcategory_93Ra0invBsJtMeta?.alias || [],
    redirect: _91subcategory_93Ra0invBsJtMeta?.redirect || undefined,
    component: () => import("/vercel/path0/pages/help/category/[slug]/[subcategory].vue").then(m => m.default || m)
  },
  {
    name: _91subcategory_93Ra0invBsJtMeta?.name ?? "help-category-slug-subcategory___en",
    path: _91subcategory_93Ra0invBsJtMeta?.path ?? "/en/help/category/:slug/:subcategory",
    meta: _91subcategory_93Ra0invBsJtMeta || {},
    alias: _91subcategory_93Ra0invBsJtMeta?.alias || [],
    redirect: _91subcategory_93Ra0invBsJtMeta?.redirect || undefined,
    component: () => import("/vercel/path0/pages/help/category/[slug]/[subcategory].vue").then(m => m.default || m)
  },
  {
    name: _91subcategory_93Ra0invBsJtMeta?.name ?? "help-category-slug-subcategory___es",
    path: _91subcategory_93Ra0invBsJtMeta?.path ?? "/es/ayuda/categoria/:slug/:subcategory",
    meta: _91subcategory_93Ra0invBsJtMeta || {},
    alias: _91subcategory_93Ra0invBsJtMeta?.alias || [],
    redirect: _91subcategory_93Ra0invBsJtMeta?.redirect || undefined,
    component: () => import("/vercel/path0/pages/help/category/[slug]/[subcategory].vue").then(m => m.default || m)
  },
  {
    name: indexbzgYzYPN5fMeta?.name ?? "help-category-slug",
    path: indexbzgYzYPN5fMeta?.path ?? "/help/category/:slug()",
    meta: indexbzgYzYPN5fMeta || {},
    alias: indexbzgYzYPN5fMeta?.alias || [],
    redirect: indexbzgYzYPN5fMeta?.redirect || undefined,
    component: () => import("/vercel/path0/pages/help/category/[slug]/index.vue").then(m => m.default || m)
  },
  {
    name: indexbzgYzYPN5fMeta?.name ?? "help-category-slug___en",
    path: indexbzgYzYPN5fMeta?.path ?? "/en/help/category/:slug",
    meta: indexbzgYzYPN5fMeta || {},
    alias: indexbzgYzYPN5fMeta?.alias || [],
    redirect: indexbzgYzYPN5fMeta?.redirect || undefined,
    component: () => import("/vercel/path0/pages/help/category/[slug]/index.vue").then(m => m.default || m)
  },
  {
    name: indexbzgYzYPN5fMeta?.name ?? "help-category-slug___es",
    path: indexbzgYzYPN5fMeta?.path ?? "/es/ayuda/categoria/:slug",
    meta: indexbzgYzYPN5fMeta || {},
    alias: indexbzgYzYPN5fMeta?.alias || [],
    redirect: indexbzgYzYPN5fMeta?.redirect || undefined,
    component: () => import("/vercel/path0/pages/help/category/[slug]/index.vue").then(m => m.default || m)
  },
  {
    name: indexluL2WM4aqkMeta?.name ?? "help",
    path: indexluL2WM4aqkMeta?.path ?? "/help",
    meta: indexluL2WM4aqkMeta || {},
    alias: indexluL2WM4aqkMeta?.alias || [],
    redirect: indexluL2WM4aqkMeta?.redirect || undefined,
    component: () => import("/vercel/path0/pages/help/index.vue").then(m => m.default || m)
  },
  {
    name: indexluL2WM4aqkMeta?.name ?? "help___en",
    path: indexluL2WM4aqkMeta?.path ?? "/en/help",
    meta: indexluL2WM4aqkMeta || {},
    alias: indexluL2WM4aqkMeta?.alias || [],
    redirect: indexluL2WM4aqkMeta?.redirect || undefined,
    component: () => import("/vercel/path0/pages/help/index.vue").then(m => m.default || m)
  },
  {
    name: indexluL2WM4aqkMeta?.name ?? "help___es",
    path: indexluL2WM4aqkMeta?.path ?? "/es/ayuda",
    meta: indexluL2WM4aqkMeta || {},
    alias: indexluL2WM4aqkMeta?.alias || [],
    redirect: indexluL2WM4aqkMeta?.redirect || undefined,
    component: () => import("/vercel/path0/pages/help/index.vue").then(m => m.default || m)
  },
  {
    name: searchK3qv33UQFOMeta?.name ?? "help-search",
    path: searchK3qv33UQFOMeta?.path ?? "/help/search",
    meta: searchK3qv33UQFOMeta || {},
    alias: searchK3qv33UQFOMeta?.alias || [],
    redirect: searchK3qv33UQFOMeta?.redirect || undefined,
    component: () => import("/vercel/path0/pages/help/search.vue").then(m => m.default || m)
  },
  {
    name: searchK3qv33UQFOMeta?.name ?? "help-search___en",
    path: searchK3qv33UQFOMeta?.path ?? "/en/help/search",
    meta: searchK3qv33UQFOMeta || {},
    alias: searchK3qv33UQFOMeta?.alias || [],
    redirect: searchK3qv33UQFOMeta?.redirect || undefined,
    component: () => import("/vercel/path0/pages/help/search.vue").then(m => m.default || m)
  },
  {
    name: searchK3qv33UQFOMeta?.name ?? "help-search___es",
    path: searchK3qv33UQFOMeta?.path ?? "/es/ayuda/search",
    meta: searchK3qv33UQFOMeta || {},
    alias: searchK3qv33UQFOMeta?.alias || [],
    redirect: searchK3qv33UQFOMeta?.redirect || undefined,
    component: () => import("/vercel/path0/pages/help/search.vue").then(m => m.default || m)
  },
  {
    name: indexe9Brt5DfdhMeta?.name ?? "index",
    path: indexe9Brt5DfdhMeta?.path ?? "/",
    meta: indexe9Brt5DfdhMeta || {},
    alias: indexe9Brt5DfdhMeta?.alias || [],
    redirect: indexe9Brt5DfdhMeta?.redirect || undefined,
    component: () => import("/vercel/path0/pages/index.vue").then(m => m.default || m)
  },
  {
    name: indexe9Brt5DfdhMeta?.name ?? "index___en",
    path: indexe9Brt5DfdhMeta?.path ?? "/en",
    meta: indexe9Brt5DfdhMeta || {},
    alias: indexe9Brt5DfdhMeta?.alias || [],
    redirect: indexe9Brt5DfdhMeta?.redirect || undefined,
    component: () => import("/vercel/path0/pages/index.vue").then(m => m.default || m)
  },
  {
    name: indexe9Brt5DfdhMeta?.name ?? "index___es",
    path: indexe9Brt5DfdhMeta?.path ?? "/es",
    meta: indexe9Brt5DfdhMeta || {},
    alias: indexe9Brt5DfdhMeta?.alias || [],
    redirect: indexe9Brt5DfdhMeta?.redirect || undefined,
    component: () => import("/vercel/path0/pages/index.vue").then(m => m.default || m)
  },
  {
    name: _91slug_93zFawhlyJvXMeta?.name ?? "internal-library-slug",
    path: _91slug_93zFawhlyJvXMeta?.path ?? "/internal/library/:slug()",
    meta: _91slug_93zFawhlyJvXMeta || {},
    alias: _91slug_93zFawhlyJvXMeta?.alias || [],
    redirect: _91slug_93zFawhlyJvXMeta?.redirect || undefined,
    component: () => import("/vercel/path0/pages/internal/library/[slug].vue").then(m => m.default || m)
  },
  {
    name: _91slug_93zFawhlyJvXMeta?.name ?? "internal-library-slug___en",
    path: _91slug_93zFawhlyJvXMeta?.path ?? "/en/internal/library/:slug()",
    meta: _91slug_93zFawhlyJvXMeta || {},
    alias: _91slug_93zFawhlyJvXMeta?.alias || [],
    redirect: _91slug_93zFawhlyJvXMeta?.redirect || undefined,
    component: () => import("/vercel/path0/pages/internal/library/[slug].vue").then(m => m.default || m)
  },
  {
    name: _91slug_93zFawhlyJvXMeta?.name ?? "internal-library-slug___es",
    path: _91slug_93zFawhlyJvXMeta?.path ?? "/es/internal/library/:slug()",
    meta: _91slug_93zFawhlyJvXMeta || {},
    alias: _91slug_93zFawhlyJvXMeta?.alias || [],
    redirect: _91slug_93zFawhlyJvXMeta?.redirect || undefined,
    component: () => import("/vercel/path0/pages/internal/library/[slug].vue").then(m => m.default || m)
  },
  {
    name: _91country_93qQXa9iWpqDMeta?.name ?? "internal-library-money-transfers-country",
    path: _91country_93qQXa9iWpqDMeta?.path ?? "/internal/library/money-transfers/:country()",
    meta: _91country_93qQXa9iWpqDMeta || {},
    alias: _91country_93qQXa9iWpqDMeta?.alias || [],
    redirect: _91country_93qQXa9iWpqDMeta?.redirect || undefined,
    component: () => import("/vercel/path0/pages/internal/library/money-transfers/[country].vue").then(m => m.default || m)
  },
  {
    name: _91country_93qQXa9iWpqDMeta?.name ?? "internal-library-money-transfers-country___en",
    path: _91country_93qQXa9iWpqDMeta?.path ?? "/en/internal/library/money-transfers/:country()",
    meta: _91country_93qQXa9iWpqDMeta || {},
    alias: _91country_93qQXa9iWpqDMeta?.alias || [],
    redirect: _91country_93qQXa9iWpqDMeta?.redirect || undefined,
    component: () => import("/vercel/path0/pages/internal/library/money-transfers/[country].vue").then(m => m.default || m)
  },
  {
    name: _91country_93qQXa9iWpqDMeta?.name ?? "internal-library-money-transfers-country___es",
    path: _91country_93qQXa9iWpqDMeta?.path ?? "/es/internal/library/money-transfers/:country()",
    meta: _91country_93qQXa9iWpqDMeta || {},
    alias: _91country_93qQXa9iWpqDMeta?.alias || [],
    redirect: _91country_93qQXa9iWpqDMeta?.redirect || undefined,
    component: () => import("/vercel/path0/pages/internal/library/money-transfers/[country].vue").then(m => m.default || m)
  },
  {
    name: indexEjLM4FiSCqMeta?.name ?? "internal-library-money-transfers",
    path: indexEjLM4FiSCqMeta?.path ?? "/internal/library/money-transfers",
    meta: indexEjLM4FiSCqMeta || {},
    alias: indexEjLM4FiSCqMeta?.alias || [],
    redirect: indexEjLM4FiSCqMeta?.redirect || undefined,
    component: () => import("/vercel/path0/pages/internal/library/money-transfers/index.vue").then(m => m.default || m)
  },
  {
    name: indexEjLM4FiSCqMeta?.name ?? "internal-library-money-transfers___en",
    path: indexEjLM4FiSCqMeta?.path ?? "/en/internal/library/money-transfers",
    meta: indexEjLM4FiSCqMeta || {},
    alias: indexEjLM4FiSCqMeta?.alias || [],
    redirect: indexEjLM4FiSCqMeta?.redirect || undefined,
    component: () => import("/vercel/path0/pages/internal/library/money-transfers/index.vue").then(m => m.default || m)
  },
  {
    name: indexEjLM4FiSCqMeta?.name ?? "internal-library-money-transfers___es",
    path: indexEjLM4FiSCqMeta?.path ?? "/es/internal/library/money-transfers",
    meta: indexEjLM4FiSCqMeta || {},
    alias: indexEjLM4FiSCqMeta?.alias || [],
    redirect: indexEjLM4FiSCqMeta?.redirect || undefined,
    component: () => import("/vercel/path0/pages/internal/library/money-transfers/index.vue").then(m => m.default || m)
  },
  {
    name: submit_45ticketYke5FLqxIXMeta?.name ?? "internal-submit-ticket",
    path: submit_45ticketYke5FLqxIXMeta?.path ?? "/internal/submit-ticket",
    meta: submit_45ticketYke5FLqxIXMeta || {},
    alias: submit_45ticketYke5FLqxIXMeta?.alias || [],
    redirect: submit_45ticketYke5FLqxIXMeta?.redirect || undefined,
    component: () => import("/vercel/path0/pages/internal/submit-ticket.vue").then(m => m.default || m)
  },
  {
    name: submit_45ticketYke5FLqxIXMeta?.name ?? "internal-submit-ticket___en",
    path: submit_45ticketYke5FLqxIXMeta?.path ?? "/en/internal/submit-ticket",
    meta: submit_45ticketYke5FLqxIXMeta || {},
    alias: submit_45ticketYke5FLqxIXMeta?.alias || [],
    redirect: submit_45ticketYke5FLqxIXMeta?.redirect || undefined,
    component: () => import("/vercel/path0/pages/internal/submit-ticket.vue").then(m => m.default || m)
  },
  {
    name: submit_45ticketYke5FLqxIXMeta?.name ?? "internal-submit-ticket___es",
    path: submit_45ticketYke5FLqxIXMeta?.path ?? "/es/internal/enviar-ticket",
    meta: submit_45ticketYke5FLqxIXMeta || {},
    alias: submit_45ticketYke5FLqxIXMeta?.alias || [],
    redirect: submit_45ticketYke5FLqxIXMeta?.redirect || undefined,
    component: () => import("/vercel/path0/pages/internal/submit-ticket.vue").then(m => m.default || m)
  },
  {
    name: verify_45emailLApvx33IjYMeta?.name ?? "internal-verify-email",
    path: verify_45emailLApvx33IjYMeta?.path ?? "/internal/verify-email",
    meta: verify_45emailLApvx33IjYMeta || {},
    alias: verify_45emailLApvx33IjYMeta?.alias || [],
    redirect: verify_45emailLApvx33IjYMeta?.redirect || undefined,
    component: () => import("/vercel/path0/pages/internal/verify-email.vue").then(m => m.default || m)
  },
  {
    name: verify_45emailLApvx33IjYMeta?.name ?? "internal-verify-email___en",
    path: verify_45emailLApvx33IjYMeta?.path ?? "/en/internal/verify-email",
    meta: verify_45emailLApvx33IjYMeta || {},
    alias: verify_45emailLApvx33IjYMeta?.alias || [],
    redirect: verify_45emailLApvx33IjYMeta?.redirect || undefined,
    component: () => import("/vercel/path0/pages/internal/verify-email.vue").then(m => m.default || m)
  },
  {
    name: verify_45emailLApvx33IjYMeta?.name ?? "internal-verify-email___es",
    path: verify_45emailLApvx33IjYMeta?.path ?? "/es/internal/verify-email",
    meta: verify_45emailLApvx33IjYMeta || {},
    alias: verify_45emailLApvx33IjYMeta?.alias || [],
    redirect: verify_45emailLApvx33IjYMeta?.redirect || undefined,
    component: () => import("/vercel/path0/pages/internal/verify-email.vue").then(m => m.default || m)
  },
  {
    name: calling_45ratesf1eINULvwMMeta?.name ?? "international-calling-calling-rates",
    path: calling_45ratesf1eINULvwMMeta?.path ?? "/international-calling/calling-rates",
    meta: calling_45ratesf1eINULvwMMeta || {},
    alias: calling_45ratesf1eINULvwMMeta?.alias || [],
    redirect: calling_45ratesf1eINULvwMMeta?.redirect || undefined,
    component: () => import("/vercel/path0/pages/international-calling/calling-rates.vue").then(m => m.default || m)
  },
  {
    name: calling_45ratesf1eINULvwMMeta?.name ?? "international-calling-calling-rates___en",
    path: calling_45ratesf1eINULvwMMeta?.path ?? "/en/international-calling/calling-rates",
    meta: calling_45ratesf1eINULvwMMeta || {},
    alias: calling_45ratesf1eINULvwMMeta?.alias || [],
    redirect: calling_45ratesf1eINULvwMMeta?.redirect || undefined,
    component: () => import("/vercel/path0/pages/international-calling/calling-rates.vue").then(m => m.default || m)
  },
  {
    name: calling_45ratesf1eINULvwMMeta?.name ?? "international-calling-calling-rates___es",
    path: calling_45ratesf1eINULvwMMeta?.path ?? "/es/llamadas-internacionales/tarifas-llamadas",
    meta: calling_45ratesf1eINULvwMMeta || {},
    alias: calling_45ratesf1eINULvwMMeta?.alias || [],
    redirect: calling_45ratesf1eINULvwMMeta?.redirect || undefined,
    component: () => import("/vercel/path0/pages/international-calling/calling-rates.vue").then(m => m.default || m)
  },
  {
    name: indexA1LT9pfVyvMeta?.name ?? "international-calling",
    path: indexA1LT9pfVyvMeta?.path ?? "/international-calling",
    meta: indexA1LT9pfVyvMeta || {},
    alias: indexA1LT9pfVyvMeta?.alias || [],
    redirect: indexA1LT9pfVyvMeta?.redirect || undefined,
    component: () => import("/vercel/path0/pages/international-calling/index.vue").then(m => m.default || m)
  },
  {
    name: indexA1LT9pfVyvMeta?.name ?? "international-calling___en",
    path: indexA1LT9pfVyvMeta?.path ?? "/en/international-calling",
    meta: indexA1LT9pfVyvMeta || {},
    alias: indexA1LT9pfVyvMeta?.alias || [],
    redirect: indexA1LT9pfVyvMeta?.redirect || undefined,
    component: () => import("/vercel/path0/pages/international-calling/index.vue").then(m => m.default || m)
  },
  {
    name: indexA1LT9pfVyvMeta?.name ?? "international-calling___es",
    path: indexA1LT9pfVyvMeta?.path ?? "/es/llamadas-internacionales",
    meta: indexA1LT9pfVyvMeta || {},
    alias: indexA1LT9pfVyvMeta?.alias || [],
    redirect: indexA1LT9pfVyvMeta?.redirect || undefined,
    component: () => import("/vercel/path0/pages/international-calling/index.vue").then(m => m.default || m)
  },
  {
    name: unlimited4cXKwmTbWuMeta?.name ?? "international-calling-unlimited",
    path: unlimited4cXKwmTbWuMeta?.path ?? "/international-calling/unlimited",
    meta: unlimited4cXKwmTbWuMeta || {},
    alias: unlimited4cXKwmTbWuMeta?.alias || [],
    redirect: unlimited4cXKwmTbWuMeta?.redirect || undefined,
    component: () => import("/vercel/path0/pages/international-calling/unlimited.vue").then(m => m.default || m)
  },
  {
    name: unlimited4cXKwmTbWuMeta?.name ?? "international-calling-unlimited___en",
    path: unlimited4cXKwmTbWuMeta?.path ?? "/en/international-calling/unlimited",
    meta: unlimited4cXKwmTbWuMeta || {},
    alias: unlimited4cXKwmTbWuMeta?.alias || [],
    redirect: unlimited4cXKwmTbWuMeta?.redirect || undefined,
    component: () => import("/vercel/path0/pages/international-calling/unlimited.vue").then(m => m.default || m)
  },
  {
    name: unlimited4cXKwmTbWuMeta?.name ?? "international-calling-unlimited___es",
    path: unlimited4cXKwmTbWuMeta?.path ?? "/es/llamadas-internacionales/ilimitadas",
    meta: unlimited4cXKwmTbWuMeta || {},
    alias: unlimited4cXKwmTbWuMeta?.alias || [],
    redirect: unlimited4cXKwmTbWuMeta?.redirect || undefined,
    component: () => import("/vercel/path0/pages/international-calling/unlimited.vue").then(m => m.default || m)
  },
  {
    name: team_45majority2GpAGx8EkwMeta?.name ?? "legal-privacy-policy-team-majority",
    path: team_45majority2GpAGx8EkwMeta?.path ?? "/legal/privacy-policy/team-majority",
    meta: team_45majority2GpAGx8EkwMeta || {},
    alias: team_45majority2GpAGx8EkwMeta?.alias || [],
    redirect: team_45majority2GpAGx8EkwMeta?.redirect || undefined,
    component: () => import("/vercel/path0/pages/legal/privacy-policy/team-majority.vue").then(m => m.default || m)
  },
  {
    name: team_45majority2GpAGx8EkwMeta?.name ?? "legal-privacy-policy-team-majority___en",
    path: team_45majority2GpAGx8EkwMeta?.path ?? "/en/legal/privacy-policy/team-majority",
    meta: team_45majority2GpAGx8EkwMeta || {},
    alias: team_45majority2GpAGx8EkwMeta?.alias || [],
    redirect: team_45majority2GpAGx8EkwMeta?.redirect || undefined,
    component: () => import("/vercel/path0/pages/legal/privacy-policy/team-majority.vue").then(m => m.default || m)
  },
  {
    name: team_45majority2GpAGx8EkwMeta?.name ?? "legal-privacy-policy-team-majority___es",
    path: team_45majority2GpAGx8EkwMeta?.path ?? "/es/legal/privacy-policy/team-majority",
    meta: team_45majority2GpAGx8EkwMeta || {},
    alias: team_45majority2GpAGx8EkwMeta?.alias || [],
    redirect: team_45majority2GpAGx8EkwMeta?.redirect || undefined,
    component: () => import("/vercel/path0/pages/legal/privacy-policy/team-majority.vue").then(m => m.default || m)
  },
  {
    name: team_45majorityppg1N5cy7eMeta?.name ?? "legal-terms-and-conditions-team-majority",
    path: team_45majorityppg1N5cy7eMeta?.path ?? "/legal/terms-and-conditions/team-majority",
    meta: team_45majorityppg1N5cy7eMeta || {},
    alias: team_45majorityppg1N5cy7eMeta?.alias || [],
    redirect: team_45majorityppg1N5cy7eMeta?.redirect || undefined,
    component: () => import("/vercel/path0/pages/legal/terms-and-conditions/team-majority.vue").then(m => m.default || m)
  },
  {
    name: team_45majorityppg1N5cy7eMeta?.name ?? "legal-terms-and-conditions-team-majority___en",
    path: team_45majorityppg1N5cy7eMeta?.path ?? "/en/legal/terms-and-conditions/team-majority",
    meta: team_45majorityppg1N5cy7eMeta || {},
    alias: team_45majorityppg1N5cy7eMeta?.alias || [],
    redirect: team_45majorityppg1N5cy7eMeta?.redirect || undefined,
    component: () => import("/vercel/path0/pages/legal/terms-and-conditions/team-majority.vue").then(m => m.default || m)
  },
  {
    name: team_45majorityppg1N5cy7eMeta?.name ?? "legal-terms-and-conditions-team-majority___es",
    path: team_45majorityppg1N5cy7eMeta?.path ?? "/es/legal/terms-and-conditions/team-majority",
    meta: team_45majorityppg1N5cy7eMeta || {},
    alias: team_45majorityppg1N5cy7eMeta?.alias || [],
    redirect: team_45majorityppg1N5cy7eMeta?.redirect || undefined,
    component: () => import("/vercel/path0/pages/legal/terms-and-conditions/team-majority.vue").then(m => m.default || m)
  },
  {
    name: indexzKnoFAVuQpMeta?.name ?? "locations",
    path: indexzKnoFAVuQpMeta?.path ?? "/locations",
    meta: indexzKnoFAVuQpMeta || {},
    alias: indexzKnoFAVuQpMeta?.alias || [],
    redirect: indexzKnoFAVuQpMeta?.redirect || undefined,
    component: () => import("/vercel/path0/pages/locations/index.vue").then(m => m.default || m)
  },
  {
    name: indexzKnoFAVuQpMeta?.name ?? "locations___en",
    path: indexzKnoFAVuQpMeta?.path ?? "/en/locations",
    meta: indexzKnoFAVuQpMeta || {},
    alias: indexzKnoFAVuQpMeta?.alias || [],
    redirect: indexzKnoFAVuQpMeta?.redirect || undefined,
    component: () => import("/vercel/path0/pages/locations/index.vue").then(m => m.default || m)
  },
  {
    name: indexzKnoFAVuQpMeta?.name ?? "locations___es",
    path: indexzKnoFAVuQpMeta?.path ?? "/es/ubicaciones",
    meta: indexzKnoFAVuQpMeta || {},
    alias: indexzKnoFAVuQpMeta?.alias || [],
    redirect: indexzKnoFAVuQpMeta?.redirect || undefined,
    component: () => import("/vercel/path0/pages/locations/index.vue").then(m => m.default || m)
  },
  {
    name: get_45paid_45earlyMEhonNSXakMeta?.name ?? "majority-account-get-paid-early",
    path: get_45paid_45earlyMEhonNSXakMeta?.path ?? "/majority-account/get-paid-early",
    meta: get_45paid_45earlyMEhonNSXakMeta || {},
    alias: get_45paid_45earlyMEhonNSXakMeta?.alias || [],
    redirect: get_45paid_45earlyMEhonNSXakMeta?.redirect || undefined,
    component: () => import("/vercel/path0/pages/majority-account/get-paid-early.vue").then(m => m.default || m)
  },
  {
    name: get_45paid_45earlyMEhonNSXakMeta?.name ?? "majority-account-get-paid-early___en",
    path: get_45paid_45earlyMEhonNSXakMeta?.path ?? "/en/majority-account/get-paid-early",
    meta: get_45paid_45earlyMEhonNSXakMeta || {},
    alias: get_45paid_45earlyMEhonNSXakMeta?.alias || [],
    redirect: get_45paid_45earlyMEhonNSXakMeta?.redirect || undefined,
    component: () => import("/vercel/path0/pages/majority-account/get-paid-early.vue").then(m => m.default || m)
  },
  {
    name: get_45paid_45earlyMEhonNSXakMeta?.name ?? "majority-account-get-paid-early___es",
    path: get_45paid_45earlyMEhonNSXakMeta?.path ?? "/es/cuenta-majority/recibir-sueldo-antes",
    meta: get_45paid_45earlyMEhonNSXakMeta || {},
    alias: get_45paid_45earlyMEhonNSXakMeta?.alias || [],
    redirect: get_45paid_45earlyMEhonNSXakMeta?.redirect || undefined,
    component: () => import("/vercel/path0/pages/majority-account/get-paid-early.vue").then(m => m.default || m)
  },
  {
    name: indexMHIyjg1SRjMeta?.name ?? "majority-account",
    path: indexMHIyjg1SRjMeta?.path ?? "/majority-account",
    meta: indexMHIyjg1SRjMeta || {},
    alias: indexMHIyjg1SRjMeta?.alias || [],
    redirect: indexMHIyjg1SRjMeta?.redirect || undefined,
    component: () => import("/vercel/path0/pages/majority-account/index.vue").then(m => m.default || m)
  },
  {
    name: indexMHIyjg1SRjMeta?.name ?? "majority-account___en",
    path: indexMHIyjg1SRjMeta?.path ?? "/en/majority-account",
    meta: indexMHIyjg1SRjMeta || {},
    alias: indexMHIyjg1SRjMeta?.alias || [],
    redirect: indexMHIyjg1SRjMeta?.redirect || undefined,
    component: () => import("/vercel/path0/pages/majority-account/index.vue").then(m => m.default || m)
  },
  {
    name: indexMHIyjg1SRjMeta?.name ?? "majority-account___es",
    path: indexMHIyjg1SRjMeta?.path ?? "/es/cuenta-majority",
    meta: indexMHIyjg1SRjMeta || {},
    alias: indexMHIyjg1SRjMeta?.alias || [],
    redirect: indexMHIyjg1SRjMeta?.redirect || undefined,
    component: () => import("/vercel/path0/pages/majority-account/index.vue").then(m => m.default || m)
  },
  {
    name: no_45hidden_45feesiCq3irP3v0Meta?.name ?? "majority-account-no-hidden-fees",
    path: no_45hidden_45feesiCq3irP3v0Meta?.path ?? "/majority-account/no-hidden-fees",
    meta: no_45hidden_45feesiCq3irP3v0Meta || {},
    alias: no_45hidden_45feesiCq3irP3v0Meta?.alias || [],
    redirect: no_45hidden_45feesiCq3irP3v0Meta?.redirect || undefined,
    component: () => import("/vercel/path0/pages/majority-account/no-hidden-fees.vue").then(m => m.default || m)
  },
  {
    name: no_45hidden_45feesiCq3irP3v0Meta?.name ?? "majority-account-no-hidden-fees___en",
    path: no_45hidden_45feesiCq3irP3v0Meta?.path ?? "/en/majority-account/no-hidden-fees",
    meta: no_45hidden_45feesiCq3irP3v0Meta || {},
    alias: no_45hidden_45feesiCq3irP3v0Meta?.alias || [],
    redirect: no_45hidden_45feesiCq3irP3v0Meta?.redirect || undefined,
    component: () => import("/vercel/path0/pages/majority-account/no-hidden-fees.vue").then(m => m.default || m)
  },
  {
    name: no_45hidden_45feesiCq3irP3v0Meta?.name ?? "majority-account-no-hidden-fees___es",
    path: no_45hidden_45feesiCq3irP3v0Meta?.path ?? "/es/cuenta-majority/sin-cargos-ocultos",
    meta: no_45hidden_45feesiCq3irP3v0Meta || {},
    alias: no_45hidden_45feesiCq3irP3v0Meta?.alias || [],
    redirect: no_45hidden_45feesiCq3irP3v0Meta?.redirect || undefined,
    component: () => import("/vercel/path0/pages/majority-account/no-hidden-fees.vue").then(m => m.default || m)
  },
  {
    name: no_45ssnpIPI9r5lcqMeta?.name ?? "majority-account-no-ssn",
    path: no_45ssnpIPI9r5lcqMeta?.path ?? "/majority-account/no-ssn",
    meta: no_45ssnpIPI9r5lcqMeta || {},
    alias: no_45ssnpIPI9r5lcqMeta?.alias || [],
    redirect: no_45ssnpIPI9r5lcqMeta?.redirect || undefined,
    component: () => import("/vercel/path0/pages/majority-account/no-ssn.vue").then(m => m.default || m)
  },
  {
    name: no_45ssnpIPI9r5lcqMeta?.name ?? "majority-account-no-ssn___en",
    path: no_45ssnpIPI9r5lcqMeta?.path ?? "/en/majority-account/no-ssn",
    meta: no_45ssnpIPI9r5lcqMeta || {},
    alias: no_45ssnpIPI9r5lcqMeta?.alias || [],
    redirect: no_45ssnpIPI9r5lcqMeta?.redirect || undefined,
    component: () => import("/vercel/path0/pages/majority-account/no-ssn.vue").then(m => m.default || m)
  },
  {
    name: no_45ssnpIPI9r5lcqMeta?.name ?? "majority-account-no-ssn___es",
    path: no_45ssnpIPI9r5lcqMeta?.path ?? "/es/majority-account/no-ssn",
    meta: no_45ssnpIPI9r5lcqMeta || {},
    alias: no_45ssnpIPI9r5lcqMeta?.alias || [],
    redirect: no_45ssnpIPI9r5lcqMeta?.redirect || undefined,
    component: () => import("/vercel/path0/pages/majority-account/no-ssn.vue").then(m => m.default || m)
  },
  {
    name: indexsCJAjPNFvkMeta?.name ?? "majority-pay",
    path: indexsCJAjPNFvkMeta?.path ?? "/majority-pay",
    meta: indexsCJAjPNFvkMeta || {},
    alias: indexsCJAjPNFvkMeta?.alias || [],
    redirect: indexsCJAjPNFvkMeta?.redirect || undefined,
    component: () => import("/vercel/path0/pages/majority-pay/index.vue").then(m => m.default || m)
  },
  {
    name: indexsCJAjPNFvkMeta?.name ?? "majority-pay___en",
    path: indexsCJAjPNFvkMeta?.path ?? "/en/majority-pay",
    meta: indexsCJAjPNFvkMeta || {},
    alias: indexsCJAjPNFvkMeta?.alias || [],
    redirect: indexsCJAjPNFvkMeta?.redirect || undefined,
    component: () => import("/vercel/path0/pages/majority-pay/index.vue").then(m => m.default || m)
  },
  {
    name: indexsCJAjPNFvkMeta?.name ?? "majority-pay___es",
    path: indexsCJAjPNFvkMeta?.path ?? "/es/majority-pay",
    meta: indexsCJAjPNFvkMeta || {},
    alias: indexsCJAjPNFvkMeta?.alias || [],
    redirect: indexsCJAjPNFvkMeta?.redirect || undefined,
    component: () => import("/vercel/path0/pages/majority-pay/index.vue").then(m => m.default || m)
  },
  {
    name: indexGcgr3G3PgaMeta?.name ?? "membership-country",
    path: indexGcgr3G3PgaMeta?.path ?? "/membership/:country()",
    meta: indexGcgr3G3PgaMeta || {},
    alias: indexGcgr3G3PgaMeta?.alias || [],
    redirect: indexGcgr3G3PgaMeta?.redirect || undefined,
    component: () => import("/vercel/path0/pages/membership/[country]/index.vue").then(m => m.default || m)
  },
  {
    name: indexGcgr3G3PgaMeta?.name ?? "membership-country___en",
    path: indexGcgr3G3PgaMeta?.path ?? "/en/membership/:country",
    meta: indexGcgr3G3PgaMeta || {},
    alias: indexGcgr3G3PgaMeta?.alias || [],
    redirect: indexGcgr3G3PgaMeta?.redirect || undefined,
    component: () => import("/vercel/path0/pages/membership/[country]/index.vue").then(m => m.default || m)
  },
  {
    name: indexGcgr3G3PgaMeta?.name ?? "membership-country___es",
    path: indexGcgr3G3PgaMeta?.path ?? "/es/membresia/:country",
    meta: indexGcgr3G3PgaMeta || {},
    alias: indexGcgr3G3PgaMeta?.alias || [],
    redirect: indexGcgr3G3PgaMeta?.redirect || undefined,
    component: () => import("/vercel/path0/pages/membership/[country]/index.vue").then(m => m.default || m)
  },
  {
    name: indexZRSgJb8xJ7Meta?.name ?? "membership",
    path: indexZRSgJb8xJ7Meta?.path ?? "/membership",
    meta: indexZRSgJb8xJ7Meta || {},
    alias: indexZRSgJb8xJ7Meta?.alias || [],
    redirect: indexZRSgJb8xJ7Meta?.redirect || undefined,
    component: () => import("/vercel/path0/pages/membership/index.vue").then(m => m.default || m)
  },
  {
    name: indexZRSgJb8xJ7Meta?.name ?? "membership___en",
    path: indexZRSgJb8xJ7Meta?.path ?? "/en/membership",
    meta: indexZRSgJb8xJ7Meta || {},
    alias: indexZRSgJb8xJ7Meta?.alias || [],
    redirect: indexZRSgJb8xJ7Meta?.redirect || undefined,
    component: () => import("/vercel/path0/pages/membership/index.vue").then(m => m.default || m)
  },
  {
    name: indexZRSgJb8xJ7Meta?.name ?? "membership___es",
    path: indexZRSgJb8xJ7Meta?.path ?? "/es/membresia",
    meta: indexZRSgJb8xJ7Meta || {},
    alias: indexZRSgJb8xJ7Meta?.alias || [],
    redirect: indexZRSgJb8xJ7Meta?.redirect || undefined,
    component: () => import("/vercel/path0/pages/membership/index.vue").then(m => m.default || m)
  },
  {
    name: indexHOHaQz7WNNMeta?.name ?? "mobile-top-ups",
    path: indexHOHaQz7WNNMeta?.path ?? "/mobile-top-ups",
    meta: indexHOHaQz7WNNMeta || {},
    alias: indexHOHaQz7WNNMeta?.alias || [],
    redirect: indexHOHaQz7WNNMeta?.redirect || undefined,
    component: () => import("/vercel/path0/pages/mobile-top-ups/index.vue").then(m => m.default || m)
  },
  {
    name: indexHOHaQz7WNNMeta?.name ?? "mobile-top-ups___en",
    path: indexHOHaQz7WNNMeta?.path ?? "/en/mobile-top-ups",
    meta: indexHOHaQz7WNNMeta || {},
    alias: indexHOHaQz7WNNMeta?.alias || [],
    redirect: indexHOHaQz7WNNMeta?.redirect || undefined,
    component: () => import("/vercel/path0/pages/mobile-top-ups/index.vue").then(m => m.default || m)
  },
  {
    name: indexHOHaQz7WNNMeta?.name ?? "mobile-top-ups___es",
    path: indexHOHaQz7WNNMeta?.path ?? "/es/recargas-moviles",
    meta: indexHOHaQz7WNNMeta || {},
    alias: indexHOHaQz7WNNMeta?.alias || [],
    redirect: indexHOHaQz7WNNMeta?.redirect || undefined,
    component: () => import("/vercel/path0/pages/mobile-top-ups/index.vue").then(m => m.default || m)
  },
  {
    name: index4EMTMAgnfgMeta?.name ?? "money-transfers",
    path: index4EMTMAgnfgMeta?.path ?? "/money-transfers",
    meta: index4EMTMAgnfgMeta || {},
    alias: index4EMTMAgnfgMeta?.alias || [],
    redirect: index4EMTMAgnfgMeta?.redirect || undefined,
    component: () => import("/vercel/path0/pages/money-transfers/index.vue").then(m => m.default || m)
  },
  {
    name: index4EMTMAgnfgMeta?.name ?? "money-transfers___en",
    path: index4EMTMAgnfgMeta?.path ?? "/en/money-transfers",
    meta: index4EMTMAgnfgMeta || {},
    alias: index4EMTMAgnfgMeta?.alias || [],
    redirect: index4EMTMAgnfgMeta?.redirect || undefined,
    component: () => import("/vercel/path0/pages/money-transfers/index.vue").then(m => m.default || m)
  },
  {
    name: index4EMTMAgnfgMeta?.name ?? "money-transfers___es",
    path: index4EMTMAgnfgMeta?.path ?? "/es/envios-dinero",
    meta: index4EMTMAgnfgMeta || {},
    alias: index4EMTMAgnfgMeta?.alias || [],
    redirect: index4EMTMAgnfgMeta?.redirect || undefined,
    component: () => import("/vercel/path0/pages/money-transfers/index.vue").then(m => m.default || m)
  },
  {
    name: indexOmJH2CTO3IMeta?.name ?? "personalized-advisor",
    path: indexOmJH2CTO3IMeta?.path ?? "/personalized-advisor",
    meta: indexOmJH2CTO3IMeta || {},
    alias: indexOmJH2CTO3IMeta?.alias || [],
    redirect: indexOmJH2CTO3IMeta?.redirect || undefined,
    component: () => import("/vercel/path0/pages/personalized-advisor/index.vue").then(m => m.default || m)
  },
  {
    name: indexOmJH2CTO3IMeta?.name ?? "personalized-advisor___en",
    path: indexOmJH2CTO3IMeta?.path ?? "/en/personalized-advisor",
    meta: indexOmJH2CTO3IMeta || {},
    alias: indexOmJH2CTO3IMeta?.alias || [],
    redirect: indexOmJH2CTO3IMeta?.redirect || undefined,
    component: () => import("/vercel/path0/pages/personalized-advisor/index.vue").then(m => m.default || m)
  },
  {
    name: indexOmJH2CTO3IMeta?.name ?? "personalized-advisor___es",
    path: indexOmJH2CTO3IMeta?.path ?? "/es/asesor-personal",
    meta: indexOmJH2CTO3IMeta || {},
    alias: indexOmJH2CTO3IMeta?.alias || [],
    redirect: indexOmJH2CTO3IMeta?.redirect || undefined,
    component: () => import("/vercel/path0/pages/personalized-advisor/index.vue").then(m => m.default || m)
  },
  {
    name: _91category_93NgZvGRFck7Meta?.name ?? "press-category",
    path: _91category_93NgZvGRFck7Meta?.path ?? "/press/:category()",
    meta: _91category_93NgZvGRFck7Meta || {},
    alias: _91category_93NgZvGRFck7Meta?.alias || [],
    redirect: _91category_93NgZvGRFck7Meta?.redirect || undefined,
    component: () => import("/vercel/path0/pages/press/[category].vue").then(m => m.default || m)
  },
  {
    name: _91category_93NgZvGRFck7Meta?.name ?? "press-category___en",
    path: _91category_93NgZvGRFck7Meta?.path ?? "/en/press/:category",
    meta: _91category_93NgZvGRFck7Meta || {},
    alias: _91category_93NgZvGRFck7Meta?.alias || [],
    redirect: _91category_93NgZvGRFck7Meta?.redirect || undefined,
    component: () => import("/vercel/path0/pages/press/[category].vue").then(m => m.default || m)
  },
  {
    name: _91category_93NgZvGRFck7Meta?.name ?? "press-category___es",
    path: _91category_93NgZvGRFck7Meta?.path ?? "/es/prensa/:category",
    meta: _91category_93NgZvGRFck7Meta || {},
    alias: _91category_93NgZvGRFck7Meta?.alias || [],
    redirect: _91category_93NgZvGRFck7Meta?.redirect || undefined,
    component: () => import("/vercel/path0/pages/press/[category].vue").then(m => m.default || m)
  },
  {
    name: _91slug_93kAcGrqUgFwMeta?.name ?? "press-article-slug",
    path: _91slug_93kAcGrqUgFwMeta?.path ?? "/press/article/:slug()",
    meta: _91slug_93kAcGrqUgFwMeta || {},
    alias: _91slug_93kAcGrqUgFwMeta?.alias || [],
    redirect: _91slug_93kAcGrqUgFwMeta?.redirect || undefined,
    component: () => import("/vercel/path0/pages/press/article/[slug].vue").then(m => m.default || m)
  },
  {
    name: _91slug_93kAcGrqUgFwMeta?.name ?? "press-article-slug___en",
    path: _91slug_93kAcGrqUgFwMeta?.path ?? "/en/press/article/:slug",
    meta: _91slug_93kAcGrqUgFwMeta || {},
    alias: _91slug_93kAcGrqUgFwMeta?.alias || [],
    redirect: _91slug_93kAcGrqUgFwMeta?.redirect || undefined,
    component: () => import("/vercel/path0/pages/press/article/[slug].vue").then(m => m.default || m)
  },
  {
    name: _91slug_93kAcGrqUgFwMeta?.name ?? "press-article-slug___es",
    path: _91slug_93kAcGrqUgFwMeta?.path ?? "/es/prensa/articulo/:slug",
    meta: _91slug_93kAcGrqUgFwMeta || {},
    alias: _91slug_93kAcGrqUgFwMeta?.alias || [],
    redirect: _91slug_93kAcGrqUgFwMeta?.redirect || undefined,
    component: () => import("/vercel/path0/pages/press/article/[slug].vue").then(m => m.default || m)
  },
  {
    name: index9mGtdY37Z2Meta?.name ?? "press",
    path: index9mGtdY37Z2Meta?.path ?? "/press",
    meta: index9mGtdY37Z2Meta || {},
    alias: index9mGtdY37Z2Meta?.alias || [],
    redirect: index9mGtdY37Z2Meta?.redirect || undefined,
    component: () => import("/vercel/path0/pages/press/index.vue").then(m => m.default || m)
  },
  {
    name: index9mGtdY37Z2Meta?.name ?? "press___en",
    path: index9mGtdY37Z2Meta?.path ?? "/en/press",
    meta: index9mGtdY37Z2Meta || {},
    alias: index9mGtdY37Z2Meta?.alias || [],
    redirect: index9mGtdY37Z2Meta?.redirect || undefined,
    component: () => import("/vercel/path0/pages/press/index.vue").then(m => m.default || m)
  },
  {
    name: index9mGtdY37Z2Meta?.name ?? "press___es",
    path: index9mGtdY37Z2Meta?.path ?? "/es/prensa",
    meta: index9mGtdY37Z2Meta || {},
    alias: index9mGtdY37Z2Meta?.alias || [],
    redirect: index9mGtdY37Z2Meta?.redirect || undefined,
    component: () => import("/vercel/path0/pages/press/index.vue").then(m => m.default || m)
  },
  {
    name: _91category_93Hs1pWobJKnMeta?.name ?? "press-material-category",
    path: _91category_93Hs1pWobJKnMeta?.path ?? "/press/material/:category()",
    meta: _91category_93Hs1pWobJKnMeta || {},
    alias: _91category_93Hs1pWobJKnMeta?.alias || [],
    redirect: _91category_93Hs1pWobJKnMeta?.redirect || undefined,
    component: () => import("/vercel/path0/pages/press/material/[category].vue").then(m => m.default || m)
  },
  {
    name: _91category_93Hs1pWobJKnMeta?.name ?? "press-material-category___en",
    path: _91category_93Hs1pWobJKnMeta?.path ?? "/en/press/material/:category()",
    meta: _91category_93Hs1pWobJKnMeta || {},
    alias: _91category_93Hs1pWobJKnMeta?.alias || [],
    redirect: _91category_93Hs1pWobJKnMeta?.redirect || undefined,
    component: () => import("/vercel/path0/pages/press/material/[category].vue").then(m => m.default || m)
  },
  {
    name: _91category_93Hs1pWobJKnMeta?.name ?? "press-material-category___es",
    path: _91category_93Hs1pWobJKnMeta?.path ?? "/es/press/material/:category()",
    meta: _91category_93Hs1pWobJKnMeta || {},
    alias: _91category_93Hs1pWobJKnMeta?.alias || [],
    redirect: _91category_93Hs1pWobJKnMeta?.redirect || undefined,
    component: () => import("/vercel/path0/pages/press/material/[category].vue").then(m => m.default || m)
  },
  {
    name: _91slug_935cURNn2uRuMeta?.name ?? "press-tag-slug",
    path: _91slug_935cURNn2uRuMeta?.path ?? "/press/tag/:slug()",
    meta: _91slug_935cURNn2uRuMeta || {},
    alias: _91slug_935cURNn2uRuMeta?.alias || [],
    redirect: _91slug_935cURNn2uRuMeta?.redirect || undefined,
    component: () => import("/vercel/path0/pages/press/tag/[slug].vue").then(m => m.default || m)
  },
  {
    name: _91slug_935cURNn2uRuMeta?.name ?? "press-tag-slug___en",
    path: _91slug_935cURNn2uRuMeta?.path ?? "/en/press/tag/:slug()",
    meta: _91slug_935cURNn2uRuMeta || {},
    alias: _91slug_935cURNn2uRuMeta?.alias || [],
    redirect: _91slug_935cURNn2uRuMeta?.redirect || undefined,
    component: () => import("/vercel/path0/pages/press/tag/[slug].vue").then(m => m.default || m)
  },
  {
    name: _91slug_935cURNn2uRuMeta?.name ?? "press-tag-slug___es",
    path: _91slug_935cURNn2uRuMeta?.path ?? "/es/press/tag/:slug()",
    meta: _91slug_935cURNn2uRuMeta || {},
    alias: _91slug_935cURNn2uRuMeta?.alias || [],
    redirect: _91slug_935cURNn2uRuMeta?.redirect || undefined,
    component: () => import("/vercel/path0/pages/press/tag/[slug].vue").then(m => m.default || m)
  },
  {
    name: team_45majority73Ma2wxhliMeta?.name ?? "privacy-policy-team-majority",
    path: team_45majority73Ma2wxhliMeta?.path ?? "/privacy-policy/team-majority",
    meta: team_45majority73Ma2wxhliMeta || {},
    alias: team_45majority73Ma2wxhliMeta?.alias || [],
    redirect: team_45majority73Ma2wxhliMeta?.redirect || undefined,
    component: () => import("/vercel/path0/pages/privacy-policy/team-majority.vue").then(m => m.default || m)
  },
  {
    name: team_45majority73Ma2wxhliMeta?.name ?? "privacy-policy-team-majority___en",
    path: team_45majority73Ma2wxhliMeta?.path ?? "/en/privacy-policy/team-majority",
    meta: team_45majority73Ma2wxhliMeta || {},
    alias: team_45majority73Ma2wxhliMeta?.alias || [],
    redirect: team_45majority73Ma2wxhliMeta?.redirect || undefined,
    component: () => import("/vercel/path0/pages/privacy-policy/team-majority.vue").then(m => m.default || m)
  },
  {
    name: team_45majority73Ma2wxhliMeta?.name ?? "privacy-policy-team-majority___es",
    path: team_45majority73Ma2wxhliMeta?.path ?? "/es/privacy-policy/team-majority",
    meta: team_45majority73Ma2wxhliMeta || {},
    alias: team_45majority73Ma2wxhliMeta?.alias || [],
    redirect: team_45majority73Ma2wxhliMeta?.redirect || undefined,
    component: () => import("/vercel/path0/pages/privacy-policy/team-majority.vue").then(m => m.default || m)
  },
  {
    name: indexlqdIlivW5SMeta?.name ?? "referral",
    path: indexlqdIlivW5SMeta?.path ?? "/referral",
    meta: indexlqdIlivW5SMeta || {},
    alias: indexlqdIlivW5SMeta?.alias || [],
    redirect: indexlqdIlivW5SMeta?.redirect || undefined,
    component: () => import("/vercel/path0/pages/referral/index.vue").then(m => m.default || m)
  },
  {
    name: indexlqdIlivW5SMeta?.name ?? "referral___en",
    path: indexlqdIlivW5SMeta?.path ?? "/en/referral",
    meta: indexlqdIlivW5SMeta || {},
    alias: indexlqdIlivW5SMeta?.alias || [],
    redirect: indexlqdIlivW5SMeta?.redirect || undefined,
    component: () => import("/vercel/path0/pages/referral/index.vue").then(m => m.default || m)
  },
  {
    name: indexlqdIlivW5SMeta?.name ?? "referral___es",
    path: indexlqdIlivW5SMeta?.path ?? "/es/referral",
    meta: indexlqdIlivW5SMeta || {},
    alias: indexlqdIlivW5SMeta?.alias || [],
    redirect: indexlqdIlivW5SMeta?.redirect || undefined,
    component: () => import("/vercel/path0/pages/referral/index.vue").then(m => m.default || m)
  },
  {
    name: indexydGv8Ir5PuMeta?.name ?? "t",
    path: indexydGv8Ir5PuMeta?.path ?? "/t",
    meta: indexydGv8Ir5PuMeta || {},
    alias: indexydGv8Ir5PuMeta?.alias || [],
    redirect: indexydGv8Ir5PuMeta?.redirect || undefined,
    component: () => import("/vercel/path0/pages/t/index.vue").then(m => m.default || m)
  },
  {
    name: indexydGv8Ir5PuMeta?.name ?? "t___en",
    path: indexydGv8Ir5PuMeta?.path ?? "/en/t",
    meta: indexydGv8Ir5PuMeta || {},
    alias: indexydGv8Ir5PuMeta?.alias || [],
    redirect: indexydGv8Ir5PuMeta?.redirect || undefined,
    component: () => import("/vercel/path0/pages/t/index.vue").then(m => m.default || m)
  },
  {
    name: indexydGv8Ir5PuMeta?.name ?? "t___es",
    path: indexydGv8Ir5PuMeta?.path ?? "/es/t",
    meta: indexydGv8Ir5PuMeta || {},
    alias: indexydGv8Ir5PuMeta?.alias || [],
    redirect: indexydGv8Ir5PuMeta?.redirect || undefined,
    component: () => import("/vercel/path0/pages/t/index.vue").then(m => m.default || m)
  },
  {
    name: team_45majority6n4YTCP4w9Meta?.name ?? "terms-and-conditions-team-majority",
    path: team_45majority6n4YTCP4w9Meta?.path ?? "/terms-and-conditions/team-majority",
    meta: team_45majority6n4YTCP4w9Meta || {},
    alias: team_45majority6n4YTCP4w9Meta?.alias || [],
    redirect: team_45majority6n4YTCP4w9Meta?.redirect || undefined,
    component: () => import("/vercel/path0/pages/terms-and-conditions/team-majority.vue").then(m => m.default || m)
  },
  {
    name: team_45majority6n4YTCP4w9Meta?.name ?? "terms-and-conditions-team-majority___en",
    path: team_45majority6n4YTCP4w9Meta?.path ?? "/en/terms-and-conditions/team-majority",
    meta: team_45majority6n4YTCP4w9Meta || {},
    alias: team_45majority6n4YTCP4w9Meta?.alias || [],
    redirect: team_45majority6n4YTCP4w9Meta?.redirect || undefined,
    component: () => import("/vercel/path0/pages/terms-and-conditions/team-majority.vue").then(m => m.default || m)
  },
  {
    name: team_45majority6n4YTCP4w9Meta?.name ?? "terms-and-conditions-team-majority___es",
    path: team_45majority6n4YTCP4w9Meta?.path ?? "/es/terms-and-conditions/team-majority",
    meta: team_45majority6n4YTCP4w9Meta || {},
    alias: team_45majority6n4YTCP4w9Meta?.alias || [],
    redirect: team_45majority6n4YTCP4w9Meta?.redirect || undefined,
    component: () => import("/vercel/path0/pages/terms-and-conditions/team-majority.vue").then(m => m.default || m)
  },
  {
    name: _91slug_93SjHTecZFomMeta?.name ?? "terms-of-use-slug",
    path: _91slug_93SjHTecZFomMeta?.path ?? "/terms-of-use/:slug()",
    meta: _91slug_93SjHTecZFomMeta || {},
    alias: _91slug_93SjHTecZFomMeta?.alias || [],
    redirect: _91slug_93SjHTecZFomMeta?.redirect || undefined,
    component: () => import("/vercel/path0/pages/terms-of-use/[slug].vue").then(m => m.default || m)
  },
  {
    name: _91slug_93SjHTecZFomMeta?.name ?? "terms-of-use-slug___en",
    path: _91slug_93SjHTecZFomMeta?.path ?? "/en/terms-of-use/:slug",
    meta: _91slug_93SjHTecZFomMeta || {},
    alias: _91slug_93SjHTecZFomMeta?.alias || [],
    redirect: _91slug_93SjHTecZFomMeta?.redirect || undefined,
    component: () => import("/vercel/path0/pages/terms-of-use/[slug].vue").then(m => m.default || m)
  },
  {
    name: _91slug_93SjHTecZFomMeta?.name ?? "terms-of-use-slug___es",
    path: _91slug_93SjHTecZFomMeta?.path ?? "/es/terminos-de-uso/:slug",
    meta: _91slug_93SjHTecZFomMeta || {},
    alias: _91slug_93SjHTecZFomMeta?.alias || [],
    redirect: _91slug_93SjHTecZFomMeta?.redirect || undefined,
    component: () => import("/vercel/path0/pages/terms-of-use/[slug].vue").then(m => m.default || m)
  },
  {
    name: indexPhPz3MuV0pMeta?.name ?? "terms-of-use",
    path: indexPhPz3MuV0pMeta?.path ?? "/terms-of-use",
    meta: indexPhPz3MuV0pMeta || {},
    alias: indexPhPz3MuV0pMeta?.alias || [],
    redirect: indexPhPz3MuV0pMeta?.redirect || undefined,
    component: () => import("/vercel/path0/pages/terms-of-use/index.vue").then(m => m.default || m)
  },
  {
    name: indexPhPz3MuV0pMeta?.name ?? "terms-of-use___en",
    path: indexPhPz3MuV0pMeta?.path ?? "/en/terms-of-use",
    meta: indexPhPz3MuV0pMeta || {},
    alias: indexPhPz3MuV0pMeta?.alias || [],
    redirect: indexPhPz3MuV0pMeta?.redirect || undefined,
    component: () => import("/vercel/path0/pages/terms-of-use/index.vue").then(m => m.default || m)
  },
  {
    name: indexPhPz3MuV0pMeta?.name ?? "terms-of-use___es",
    path: indexPhPz3MuV0pMeta?.path ?? "/es/terminos-de-uso",
    meta: indexPhPz3MuV0pMeta || {},
    alias: indexPhPz3MuV0pMeta?.alias || [],
    redirect: indexPhPz3MuV0pMeta?.redirect || undefined,
    component: () => import("/vercel/path0/pages/terms-of-use/index.vue").then(m => m.default || m)
  },
  {
    name: atms9uR5VCIpt2Meta?.name ?? "visa-debit-card-atms",
    path: atms9uR5VCIpt2Meta?.path ?? "/visa-debit-card/atms",
    meta: atms9uR5VCIpt2Meta || {},
    alias: atms9uR5VCIpt2Meta?.alias || [],
    redirect: atms9uR5VCIpt2Meta?.redirect || undefined,
    component: () => import("/vercel/path0/pages/visa-debit-card/atms.vue").then(m => m.default || m)
  },
  {
    name: atms9uR5VCIpt2Meta?.name ?? "visa-debit-card-atms___en",
    path: atms9uR5VCIpt2Meta?.path ?? "/en/visa-debit-card/atms",
    meta: atms9uR5VCIpt2Meta || {},
    alias: atms9uR5VCIpt2Meta?.alias || [],
    redirect: atms9uR5VCIpt2Meta?.redirect || undefined,
    component: () => import("/vercel/path0/pages/visa-debit-card/atms.vue").then(m => m.default || m)
  },
  {
    name: atms9uR5VCIpt2Meta?.name ?? "visa-debit-card-atms___es",
    path: atms9uR5VCIpt2Meta?.path ?? "/es/tarjeta-visa-debito/atms",
    meta: atms9uR5VCIpt2Meta || {},
    alias: atms9uR5VCIpt2Meta?.alias || [],
    redirect: atms9uR5VCIpt2Meta?.redirect || undefined,
    component: () => import("/vercel/path0/pages/visa-debit-card/atms.vue").then(m => m.default || m)
  },
  {
    name: explore_45discounts68q40V9IUpMeta?.name ?? "visa-debit-card-discounts-explore-discounts",
    path: explore_45discounts68q40V9IUpMeta?.path ?? "/visa-debit-card/discounts/explore-discounts",
    meta: explore_45discounts68q40V9IUpMeta || {},
    alias: explore_45discounts68q40V9IUpMeta?.alias || [],
    redirect: explore_45discounts68q40V9IUpMeta?.redirect || undefined,
    component: () => import("/vercel/path0/pages/visa-debit-card/discounts/explore-discounts.vue").then(m => m.default || m)
  },
  {
    name: explore_45discounts68q40V9IUpMeta?.name ?? "visa-debit-card-discounts-explore-discounts___en",
    path: explore_45discounts68q40V9IUpMeta?.path ?? "/en/visa-debit-card/discounts/explore-discounts",
    meta: explore_45discounts68q40V9IUpMeta || {},
    alias: explore_45discounts68q40V9IUpMeta?.alias || [],
    redirect: explore_45discounts68q40V9IUpMeta?.redirect || undefined,
    component: () => import("/vercel/path0/pages/visa-debit-card/discounts/explore-discounts.vue").then(m => m.default || m)
  },
  {
    name: explore_45discounts68q40V9IUpMeta?.name ?? "visa-debit-card-discounts-explore-discounts___es",
    path: explore_45discounts68q40V9IUpMeta?.path ?? "/es/tarjeta-visa-debito/descuentos/explorar-descuentos",
    meta: explore_45discounts68q40V9IUpMeta || {},
    alias: explore_45discounts68q40V9IUpMeta?.alias || [],
    redirect: explore_45discounts68q40V9IUpMeta?.redirect || undefined,
    component: () => import("/vercel/path0/pages/visa-debit-card/discounts/explore-discounts.vue").then(m => m.default || m)
  },
  {
    name: indexASV3CORU2eMeta?.name ?? "visa-debit-card-discounts",
    path: indexASV3CORU2eMeta?.path ?? "/visa-debit-card/discounts",
    meta: indexASV3CORU2eMeta || {},
    alias: indexASV3CORU2eMeta?.alias || [],
    redirect: indexASV3CORU2eMeta?.redirect || undefined,
    component: () => import("/vercel/path0/pages/visa-debit-card/discounts/index.vue").then(m => m.default || m)
  },
  {
    name: indexASV3CORU2eMeta?.name ?? "visa-debit-card-discounts___en",
    path: indexASV3CORU2eMeta?.path ?? "/en/visa-debit-card/discounts",
    meta: indexASV3CORU2eMeta || {},
    alias: indexASV3CORU2eMeta?.alias || [],
    redirect: indexASV3CORU2eMeta?.redirect || undefined,
    component: () => import("/vercel/path0/pages/visa-debit-card/discounts/index.vue").then(m => m.default || m)
  },
  {
    name: indexASV3CORU2eMeta?.name ?? "visa-debit-card-discounts___es",
    path: indexASV3CORU2eMeta?.path ?? "/es/tarjeta-visa-debito/descuentos",
    meta: indexASV3CORU2eMeta || {},
    alias: indexASV3CORU2eMeta?.alias || [],
    redirect: indexASV3CORU2eMeta?.redirect || undefined,
    component: () => import("/vercel/path0/pages/visa-debit-card/discounts/index.vue").then(m => m.default || m)
  },
  {
    name: indexSZstZ9F5ngMeta?.name ?? "visa-debit-card",
    path: indexSZstZ9F5ngMeta?.path ?? "/visa-debit-card",
    meta: indexSZstZ9F5ngMeta || {},
    alias: indexSZstZ9F5ngMeta?.alias || [],
    redirect: indexSZstZ9F5ngMeta?.redirect || undefined,
    component: () => import("/vercel/path0/pages/visa-debit-card/index.vue").then(m => m.default || m)
  },
  {
    name: indexSZstZ9F5ngMeta?.name ?? "visa-debit-card___en",
    path: indexSZstZ9F5ngMeta?.path ?? "/en/visa-debit-card",
    meta: indexSZstZ9F5ngMeta || {},
    alias: indexSZstZ9F5ngMeta?.alias || [],
    redirect: indexSZstZ9F5ngMeta?.redirect || undefined,
    component: () => import("/vercel/path0/pages/visa-debit-card/index.vue").then(m => m.default || m)
  },
  {
    name: indexSZstZ9F5ngMeta?.name ?? "visa-debit-card___es",
    path: indexSZstZ9F5ngMeta?.path ?? "/es/tarjeta-visa-debito",
    meta: indexSZstZ9F5ngMeta || {},
    alias: indexSZstZ9F5ngMeta?.alias || [],
    redirect: indexSZstZ9F5ngMeta?.redirect || undefined,
    component: () => import("/vercel/path0/pages/visa-debit-card/index.vue").then(m => m.default || m)
  },
  {
    name: no_45ssnjwQsjIQvWHMeta?.name ?? "visa-debit-card-no-ssn",
    path: no_45ssnjwQsjIQvWHMeta?.path ?? "/visa-debit-card/no-ssn",
    meta: no_45ssnjwQsjIQvWHMeta || {},
    alias: no_45ssnjwQsjIQvWHMeta?.alias || [],
    redirect: no_45ssnjwQsjIQvWHMeta?.redirect || undefined,
    component: () => import("/vercel/path0/pages/visa-debit-card/no-ssn.vue").then(m => m.default || m)
  },
  {
    name: no_45ssnjwQsjIQvWHMeta?.name ?? "visa-debit-card-no-ssn___en",
    path: no_45ssnjwQsjIQvWHMeta?.path ?? "/en/visa-debit-card/no-ssn",
    meta: no_45ssnjwQsjIQvWHMeta || {},
    alias: no_45ssnjwQsjIQvWHMeta?.alias || [],
    redirect: no_45ssnjwQsjIQvWHMeta?.redirect || undefined,
    component: () => import("/vercel/path0/pages/visa-debit-card/no-ssn.vue").then(m => m.default || m)
  },
  {
    name: no_45ssnjwQsjIQvWHMeta?.name ?? "visa-debit-card-no-ssn___es",
    path: no_45ssnjwQsjIQvWHMeta?.path ?? "/es/tarjeta-visa-debito/no-ssn",
    meta: no_45ssnjwQsjIQvWHMeta || {},
    alias: no_45ssnjwQsjIQvWHMeta?.alias || [],
    redirect: no_45ssnjwQsjIQvWHMeta?.redirect || undefined,
    component: () => import("/vercel/path0/pages/visa-debit-card/no-ssn.vue").then(m => m.default || m)
  }
]