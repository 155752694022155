const stringify = function (obj) {
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  return JSON.stringify(obj, function (key, value) {
    if (value instanceof Function || typeof value === 'function') {
      const v = value({ normalize, interpolate, named })
      return v.join('')
    }
    return value
  })
}

function normalize (v) {
  return v
}
function interpolate (v) {
  return named(v)
}
function named (v) {
  return '{' + v + '}'
}

const getMessage = (messages, key, params) => {
  const keys = key.split('.')
  let message = messages
  for (let i = 0; i < keys.length; i++) {
    message = message ? message[keys[i]] : null
  }
  if (message) {
    let r = JSON.parse(stringify(message))
    if (params) {
      r = loadParams(r, params)
    }
    return r
  } else {
    return ''
  }
}

function loadParams (template, data) {
  return template.replace(/\{\{(\w+)\}\}/g, function (match, key) {
    return data[key]
  })
}

export default getMessage
