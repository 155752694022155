import { isEmpty } from '@/utils/comparators'
import { DESTINATION_QUERY } from '@/utils/constants'
import { userContextStore } from '@/store/userContext'

export default defineNuxtRouteMiddleware(async (to, from) => {
  const route = to
  const uStore = userContextStore()
  if (!isEmpty(route.query[DESTINATION_QUERY])) {
    uStore.setToCountry(route.query[DESTINATION_QUERY].toUpperCase())
  }
})
