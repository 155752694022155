import mitt from 'mitt'
const CHANGE_LANGUAGE = 'change-language'

export default defineNuxtPlugin(() => {
  const emitter = mitt()
  const bus = {
    emitChangeLanguage (currentRoute, destinationLocale) {
      emitter.emit(CHANGE_LANGUAGE, { currentRoute, destinationLocale })
    },
    onChangeLanguage (handler) {
      emitter.on(CHANGE_LANGUAGE, handler)
    },
    emitDynamicRouteChangeLanguage (currentRoute, destinationLocale, destinationCountry = null) {
      emitter.emit(`${CHANGE_LANGUAGE}-${currentRoute.meta.key}`, {
        currentRoute,
        destinationLocale,
        destinationCountry
      })
    },
    onDynamicRouteChangeLanguage (key, handler) {
      emitter.on(`${CHANGE_LANGUAGE}-${key}`, handler)
    }
  }
  return {
    provide: {
      bus
    }
  }
})
