import { defineStore } from 'pinia'

const state = () => ({
  countries: [],
  country: {},
  sorted: {}
})

const actions = {
  setCountries (countries) {
    this.countries = countries
  },
  setCountry (code, country) {
    this.country[code] = country
  },
  setSorted (order, sorted) {
    this.sorted[order] = sorted
  }
}

export const useCountriesStore = defineStore('countries', {
  state: () => state(),
  actions
})

export default useCountriesStore
