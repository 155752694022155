const actions = {
  SET_USERAGENT (val) {
    this.userAgent = val
  },
  SET_ISMAJORITYBROWSER (val) {
    this.majorityBrowser = val
  },
  SET_MAJORITYAPP (val) {
    this.majorityApp = val
  },
  SET_SEENWORDMARK (val) {
    this.seenWordmark = val
  },
  SET_HEADERS (val) {
    this.headers = val
  },
  SET_TITLE (val) {
    this.title = val
  },
  SET_GOOGLE_MAPS_STATUS (val) {
    this.googleMapsStatus = val
  },
  SET_MENU_DRAWER_IS_OPEN (open) {
    this.menuDrawerIsOpen = open
  },
  SET_SIGNUP_MODAL_IS_OPEN (open) {
    this.signupModalIsOpen = open
  },
  COMPLETED_REFERRAL_FLOW (val) {
    this.completedReferralFlow = val
  },
  seeWordmark () {
    this.SET_SEENWORDMARK(true)
  },
  changeGoogleMapsStatus (value = null) {
    value = [null, 'STARTED', 'COMPLETED'].includes(value) ? value : null
    this.SET_GOOGLE_MAPS_STATUS(value)
  },
  setMenuDrawerIsOpen (open) {
    this.SET_MENU_DRAWER_IS_OPEN(open)
  },
  setDiasporaDrawerIsOpen (open) {
    this.diasporaDrawerIsOpen = open
  },
  setSignupModalIsOpen (open) {
    this.SET_SIGNUP_MODAL_IS_OPEN(open)
  },
  setCompletedReferralFlow (val) {
    this.COMPLETED_REFERRAL_FLOW(val)
  },
  setUserAgent (val) {
    this.SET_USERAGENT(val)
  },
  setIsMajorityBrowser (val) {
    this.SET_ISMAJORITYBROWSER(val)
  },
  setMajorityApp (val) {
    this.SET_MAJORITYAPP(val)
  }
}

export default actions
